import React, { useState, useEffect } from "react";
import { Button, TextInput, InlineNotification } from "@carbon/react";
import "./KeywordInput.scss";
import { Add, Locked, Help } from "@carbon/icons-react";

const KeywordTagInput = ({
  currentSeoKeywords,
  setSeoKeywords,
  addKeyWordToList,
}) => {
  const [input, setInput] = useState("");
  const [canAdd, setCanAdd] = useState(true);

  useEffect(() => {
    if (addKeyWordToList) {
      handleAddTagClick();
    }
  }, [addKeyWordToList]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value.trim().split(" ").length > 3) {
      setCanAdd(false);
    } else {
      setCanAdd(true);
    }

    setInput(value);
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (
      (key === "Enter" || key === ",") &&
      trimmedInput.length &&
      currentSeoKeywords.length < 3
    ) {
      e.preventDefault();
      addTag(trimmedInput);
    }
  };

  const handleAddTagClick = () => {
    const trimmedInput = input.trim();

    if (trimmedInput.length) {
      addTag(trimmedInput);
    }
  };

  const addTag = (tag) => {
    if (canAdd) {
      if (currentSeoKeywords.length >= 2) {
        setCanAdd(false);
      }

      const updatedTags = [...currentSeoKeywords, tag];
      setSeoKeywords(updatedTags);
      setInput("");
    }
  };

  const removeTag = (indexToRemove) => {
    const updatedTags = currentSeoKeywords.filter(
      (_, index) => index !== indexToRemove
    );
    setSeoKeywords(updatedTags);
    if (updatedTags.length < 3) {
      setCanAdd(true);
    }
  };

  // const handleCloseNotification = () => {
  //   console.log("close");
  //   setIsLocked(false);
  // };

  // const handleOpenNotification = () => {
  //   console.log("open");
  //   setIsLocked(true);
  // };

  const generateKeywords = async () => {
    try {
      const response = await fetch("API_ENDPOINT", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ input }),
      });

      if (response.ok) {
        const data = await response.json();
        const generatedKeywords = data.keywords;
        setSeoKeywords([...currentSeoKeywords, ...generatedKeywords]);
      } else {
        console.error("Error generating keywords:", response.statusText);
      }
    } catch (error) {
      console.error("Error generating keywords:", error);
    }
  };

  return (
    <div style={{ marginTop: "1%" }}>
      <div className="input-container">
        <TextInput
          id="keyword-input"
          labelText=""
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          type="text"
          className="keyword-input"
          placeholder="Enter SEO keywords..."
        />
        <Button
          className="keywords-buttons add-keywords-button"
          onClick={handleAddTagClick}
          disabled={!canAdd}
          kind="primary"
        >
          {/* Add */}
          <Add />
        </Button>
      </div>

      <div className="tags-container">
        {currentSeoKeywords.map((tag, index) => (
          <div key={index} className="tag-item">
            <span className="text">{tag}</span>
            <span className="close" onClick={() => removeTag(index)}>
              &times;
            </span>
          </div>
        ))}
      </div>
      <div>
        <button className="keywords-buttons  generate-button">
          Generate keywords
          <Locked className="lock" />
        </button>
        <div
          style={{
            fontSize: "var(--text-small)",
            color: "var(--xpub-brand-color)",
            marginLeft: "0.2rem",
            marginTop: "0.2rem",
            display: "flex",
            flexDirection: "row",
            gap: "0.2rem",
            alignItems: "center",
          }}
        >
          {/* <Locked  /> */}
          Contact support@xpub.ai to unlock this feature.
        </div>
      </div>
    </div>
  );
};

export default KeywordTagInput;

import React from "react";
import "./CustomMenu.scss";
import { InlineLoading } from "@carbon/react";
import { Close } from "@carbon/icons-react";

const CustomMenu = (props) => {
  return (
    <div className="custom-menu-container">
      <div className="menu-header">
        {/* <span className="menu-title">Magic Actions</span> */}
        <span className="menu-title">
          Magic actions{" "}
          <span
            style={{
              fontSize: "var(--text-medium)",
              color: "lightgray",
              paddingLeft: "10px",
            }}
          >
            [ cmd + / ]
          </span>
        </span>
        <span className="close-button" onClick={() => props.menuClose()}>
          <Close />
        </span>
      </div>
      <div className="menu-items">
        <div style={{ marginLeft: "10px" }}>
          {!props.enableGen && (
            <InlineLoading description="Transcribing media files" />
          )}
        </div>
        {props.menuItems.map(
          (item, index) =>
            item.menuText && (
              <div
                className={`action-item ${
                  item.subMenuItem ? "submenu-item" : ""
                } ${!props.enableGen ? "disabled" : ""} ${
                  item.disableOption ? "disabled" : ""
                }`}
                onClick={() => {
                  item.functionCall();
                }}
                key={index}
              >
                {item.menuText}
                {item.disableOption && (
                  <span style={{ color: "#ff8080" }}>
                    &nbsp;{item.disabledMsg}
                  </span>
                )}
              </div>
            )
        )}
        <div className="action-item disabled">Change Tone</div>
      </div>
    </div>
  );
};

export default CustomMenu;

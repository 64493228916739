import React from "react";
import { Checkbox, FormLabel } from "@carbon/react";
import { CheckmarkOutline } from "@carbon/icons-react";
import "./scss/StatusPanel.scss"; // Import the SCSS file
import ReadinessIndex from "./ReadinessIndex.js";

const StatusPanel = ({ steps, onSectionClick, currentSection, index }) => {
  return (
    <div className="status-panel">
      <ReadinessIndex score={index} /> {/* Add this line */}
      <br />
      <div className="steps-list">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step-item ${currentSection === index ? "active" : ""}`}
            onClick={() => onSectionClick(index)}
          >
            <FormLabel>{step.title}</FormLabel>
            <div className="status-icon">
              {step.reviewed ? (
                <CheckmarkOutline size="20" />
              ) : (
                <div className="circle-icon"></div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusPanel;

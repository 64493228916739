import React, { useMemo } from 'react';
import './ReadinessIndex.scss';

const ReadinessIndex = ({ score }) => {
  const percentage = (score / 10) * 100;
  const dashArray = 2 * Math.PI * 45;
  const dashOffset = dashArray * ((100 - percentage) / 100);

  const arcColor = useMemo(() => {
    const hue = score * 7; // 0 to 120 (red to green in HSL)
    return `hsl(${hue}, 100%, 50%)`;
  }, [score]);

  return (
    <div className="readiness-index">
      <div className="readiness-index__loader">
        <svg viewBox="0 0 100 100" className="readiness-index__circle">
          <circle cx="50" cy="50" r="45" fill="transparent" stroke="#f0f0f0" strokeWidth="10" />
          <circle
            cx="50"
            cy="50"
            r="45"
            fill="transparent"
            stroke={arcColor}
            strokeWidth="10"
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap="round"
          />
        </svg>
        <span className="readiness-index__score" style={{ color: arcColor }}>{score.toFixed(1)}</span>
      </div>
      <div className="readiness-index__text">
        <h2 className="readiness-index__title">Readiness Index</h2>
        <p className="readiness-index__scale">(scale of 10)</p>
      </div>
    </div>
  );
};

export default ReadinessIndex;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InlineNotification } from "@carbon/react";
import { api, loginWithDataTokenAndProfile } from "../store";

const ProfileCompletion = ({
  supabaseUser,
  accessToken,
  onLogin,
  onSignup,
}) => {
  const navigate = useNavigate();
  const [fname, setFirstName] = useState("");
  const [lname, setLastName] = useState("");
  // const [usage, setUsage] = useState("");
  const [icp, setIcp] = useState("");
  const [businessOfferings, setBusinessOfferings] = useState("");
  const [industry, setIndustry] = useState("");
  const [isSignupError, setSignupError] = useState(false);

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    if (!supabaseUser) {
      return;
    }
    const supabaseUserId = supabaseUser.id;
    try {
      const res = await api.post("auth/signup", {
        email: supabaseUser.email,
        fname: fname.trim(),
        lname: lname.trim(),
        industry: industry.trim(),
        icp: icp.trim(),
        offerings: businessOfferings.trim(),
        supabaseId: supabaseUserId,
      });
      if (res.status === 200) {
        loginWithDataTokenAndProfile(accessToken, res.data.profile);
        onSignup(res.data.profile);
        onLogin();
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error);
      setSignupError(true);
    }
  };

  return (
    <div className="auth-full-width">
      {isSignupError && (
        <div style={{ margin: "0 1rem" }}>
          <InlineNotification
            lowContrast
            kind="error"
            subtitle={
              "Please contact us at support@xpub.ai for further support."
            }
            title="Error in Signup"
          />
        </div>
      )}
      <form onSubmit={handleProfileSubmit}>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            marginBottom: "1.5rem",
          }}
        >
          <div>
            <label className="supabase-auth-ui_ui-label c-bpexlo">
              First Name
            </label>
            <input
              className="supabase-auth-ui_ui-input c-dEnagJ c-dEnagJ-bBzSYw-type-default"
              placeholder="John"
              id="fname"
              name="fname"
              value={fname}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="supabase-auth-ui_ui-label c-bpexlo">
              Last Name
            </label>
            <input
              className="supabase-auth-ui_ui-input c-dEnagJ c-dEnagJ-bBzSYw-type-default"
              placeholder="Doe"
              id="lname"
              name="lname"
              value={lname}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        </div>
        <div style={{ marginBottom: "1.5rem" }}>
          <label className="supabase-auth-ui_ui-label c-bpexlo">
            The accuracy of this following input would determine the relevancy
            of the ideas and research sources generated for your content in the
            tool.
          </label>
        </div>
        <div style={{ marginBottom: "1.5rem" }}>
          <label className="supabase-auth-ui_ui-label c-bpexlo">
            What industry do you write for?
          </label>
          <input
            className="supabase-auth-ui_ui-input c-dEnagJ c-dEnagJ-bBzSYw-type-default"
            placeholder="e.g., Information Technology, Healthcare"
            id="industry"
            name="industry"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
            required
          />
        </div>
        <div style={{ marginBottom: "1.5rem" }}>
          <label className="supabase-auth-ui_ui-label c-bpexlo">
            What are your business offerings?
          </label>
          <input
            className="supabase-auth-ui_ui-input c-dEnagJ c-dEnagJ-bBzSYw-type-default"
            placeholder="e.g., Financial services, Digital Marketing"
            id="businessOfferings"
            name="businessOfferings"
            value={businessOfferings}
            onChange={(e) => setBusinessOfferings(e.target.value)}
            required
          />
        </div>
        <div style={{ marginBottom: "1.5rem" }}>
          <label className="supabase-auth-ui_ui-label c-bpexlo">
            Describe your Ideal Customer Profile (ICP):
          </label>
          <textarea
            className="supabase-auth-ui_ui-input c-dEnagJ c-dEnagJ-bBzSYw-type-default"
            placeholder="e.g., Mid-sized tech companies looking for content marketing solutions"
            id="icp"
            name="icp"
            value={icp}
            onChange={(e) => setIcp(e.target.value)}
            required
          />
        </div>
        {/* <div style={{ marginBottom: "1.5rem" }}>
          <label className="supabase-auth-ui_ui-label c-bpexlo">
            Tell us how you intend to use the tool
          </label>
          <input
            className="supabase-auth-ui_ui-input c-dEnagJ c-dEnagJ-bBzSYw-type-default"
            placeholder="Tool usage"
            id="usage"
            name="usage"
            value={usage}
            onChange={(e) => setUsage(e.target.value)}
          />
        </div> */}
        <br />
        <button
          type="submit"
          className="supabase-auth-ui_ui-button c-bOcPnF c-bOcPnF-cmFMMs-color-primary"
        >
          Proceed
        </button>
      </form>
      <br />
    </div>
  );
};

export default ProfileCompletion;

import React, { useState, useEffect } from "react";
import Joyride, { STATUS, EVENTS } from "react-joyride";

const TourDashboard = ({ onEnd, setIsNavOpen }) => {
  const [isTourOpen, setTourOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    // Check if the tour has been shown before
    const showTour = localStorage.getItem("showDashboardTour") !== "false";
    if (showTour) {
      setTourOpen(true);
    }
  }, []);

  useEffect(() => {
    const createProjectButton = document.querySelector(
      ".create-project-button"
    );

    if (createProjectButton) {
      createProjectButton.addEventListener("click", () => {
        setStepIndex(2);
      });
    }

    return () => {
      if (createProjectButton) {
        createProjectButton.removeEventListener("click", () => {
          setStepIndex(2);
        });
      }
    };
  }, [stepIndex]);

  const steps = [
    {
      target: "body",
      content:
        "Welcome to xPub! You are now on the Projects page, where you'll see all the projects you're working on. In this tour, we'll help you create your first article.",
      disableBeacon: true,
      placement: "center",
      disableOverlay: true,
      hideBackButton: true,
    },
    {
      target: ".create-project-button",
      content:
        "To begin, click 'Create Project', type in a good name for it and off we go!",
      hideBackButton: true,
    },
    // {
    //   target: ".create-project-modal",
    //   content: "Give your project a name and description. Then press 'Create' to proceed.",
    //   hideBackButton: true,
    //   placement: "bottom",
    // },
    // {
    //   target: ".action-button.seo-content-button",
    //   content:
    //     "Click here to set an objective for your SEO article. You can perform web search or can also upload files as input sources",
    // },
  ];

  const handleTourCallback = (data) => {
    const { status, type, index } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      onEnd();
      setTourOpen(false);
      localStorage.setItem("showDashboardTour", "false");
    } else if (type === EVENTS.STEP_AFTER) {
      setStepIndex(index + 1);
      if (index === 0) {
      }
      if (index === 1) {
        // Trigger the create project button click

        const createProjectButton = document.querySelector(
          ".create-project-button"
        );
        if (createProjectButton) {
          createProjectButton.click();
        }
      }
    }
  };

  return (
    <>
      <Joyride
        steps={steps}
        continuous={true}
        showSkipButton={true}
        showProgress={false}
        callback={handleTourCallback}
        run={isTourOpen}
        disableOverlayClose={true}
        spotlightClicks={true}
        stepIndex={stepIndex}
        hideCloseButton={true}
        locale={{
          back: "Back",
          close: "Close",
          last: "Next",
          next: "Next",
          skip: "Skip",
        }}
        styles={{
          options: {
            arrowColor: "var(--xpub-secondary-color)",
            backgroundColor: "var(--xpub-secondary-color)",
            primaryColor: "var(--element-hover-color)",
            textColor: "var(--text-color-secondary)",
            zIndex: "1000",
          },
        }}
      />
    </>
  );
};

export default TourDashboard;

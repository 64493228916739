import React, { useState } from "react";
// import Spreadsheet from "react-spreadsheet";
import { Button } from "@carbon/react";
import * as XLSX from "xlsx";

const ExcelLikeGrid = ({ csvData, financial_name }) => {
  const [generatingLink, setGeneratingLink] = useState(false);

  function csvToMatrix(csv) {
    return csv
      .split("\n")
      .filter((line) => line)
      .map((row) => row.split(",").map((cell) => ({ value: cell.trim() })));
  }

  const matrix = csvToMatrix(csvData);
  const firstRow = matrix
    .shift()
    .map((cell) => ({ value: cell.value, readOnly: true }));
  const columnLabels = firstRow.map((obj) => obj.value);
  const data = matrix;

  const downloadFile = () => {
    const preparedData = [columnLabels].concat(
      data.map((row) => row.map((cell) => cell.value))
    );

    const ws = XLSX.utils.aoa_to_sheet(preparedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate download
    XLSX.writeFile(wb, `${financial_name.replace(/\s+/g, "_")}_statement.xlsx`);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <h4>Group {financial_name} statement</h4>
        <div
          style={{
            display: "flex",

            alignItems: "center",
          }}
        >
          <Button onClick={downloadFile}>
            {generatingLink ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <span style={{ marginRight: "10px" }}>Download</span>{" "}
                {/* Added margin for spacing */}
                <div style={{ transform: "scale(0.75)", display: "flex" }}>
                  {" "}
                  {/* Scale down the loader */}
                  <InlineLoading />
                </div>
              </div>
            ) : (
              "Download"
            )}
          </Button>
        </div>
      </div>
      {/* <div style={{ height: "70vh", overflowY: "auto", marginTop: "2%" }}>
        {" "}
        <Spreadsheet data={data} rowLabels="" columnLabels={columnLabels} />
      </div> */}
    </div>
  );
};

export default ExcelLikeGrid;

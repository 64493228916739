import React, { useState, useEffect } from "react";
import { api } from "../store";
import CMSTiles from "../Components/archives/CMSTiles";
import { supportedCMS } from "../util";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "@carbon/icons-react";

const AddArchives = () => {
  const [unConfiguredArchives, setUnConfiguredArchives] = useState([]);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/settings?tab=archives"); // Go back to the previous page
  };

  const getUserArchivesId = async () => {
    const response = await api.get("/archives/getUserArchivesId");
    if (response.data.archives.length > 0) {
      const unConfiguredCMSs = supportedCMS.filter(
        (cms) =>
          !response.data.archives.some(
            (archive) => archive.archiveType === cms.archiveType
          )
      );
      console.log(response.data.archives);
      console.log(unConfiguredCMSs);
      setUnConfiguredArchives(unConfiguredCMSs);
    } else {
      setUnConfiguredArchives(supportedCMS);
    }
  };

  useEffect(() => {
    //get archive IDs of the user
    getUserArchivesId();
  }, []);

  const toggleArchiveConfiguration = (val) => {
    getUserArchivesId();
  };

  return (
    <div style={{ padding: "3rem 2rem" }}>
      <button onClick={handleBackClick} style={{ marginBottom: "1rem" }}>
        <div style={{ display: "flex", gap: "0.5rem", marginBottom: "2rem" }}>
          <ArrowLeft />
          <span>Back</span>
        </div>
      </button>
      <CMSTiles
        setArchiveonfiguration={toggleArchiveConfiguration}
        showCMS={unConfiguredArchives}
        noArchives={false}
      />
    </div>
  );
};

export default AddArchives;

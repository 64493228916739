import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Column, Tile } from "@carbon/react";
import { Modal } from "@carbon/react";
import "./Dashboard.scss";
// import "react-chat-widget/lib/styles.css";
import ChatWidget from "../Components/ChatWidget";
import { ArrowRight } from "@carbon/icons-react";

const SalesPub = () => {
  const navigate = useNavigate();
  const [displayForm, setDisplayForm] = useState(true);
  const [open, setOpen] = useState(false);

  const handleRFPClick = () => {
    setOpen(true);

    if (process.env.REACT_APP_ENV !== "prod") {
      navigate("/generateRFP");
    } else {
      setOpen(true);
    }
  };

  const handleSEOClick = () => {
    navigate("/dashboard?modal=open");
  };

  const isDisabled = process.env.REACT_APP_ENV === "prod";

  return (
    <div>
      {process.env.REACT_APP_ENV === "staging" && <ChatWidget />}

      <Grid style={{ marginTop: "4rem" }} className="grid-dashboard">
        <Column lg={16} className="rounded-left column-dashboard">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "7%",
              marginLeft: "auto",
              marginRight: "auto",
              // gap: "50px",
            }}
          >
            <h4 style={{ fontWeight: "500" }}>Shorten B2B sales cycles</h4>
            {/* <h6 style={{ fontWeight: "450" }}>
              To get started, contact xPub to configure your archives
            </h6> */}
          </div>
          <Modal
            open={open}
            onRequestClose={() => setOpen(false)}
            passiveModal
            modalHeading="Connect your archives in a matter of minutes."
          >
            <p>
              Minimize time and headaches for RFP issuance, response, and
              evaluation with xPub! Choose from countless integrations to
              connect your archives, no matter where it is stored, and unleash
              your sales team's knowledge with AI.
            </p>
            <p style={{ marginTop: "1em" }}>
              Get started today by contacting us at support@xpub.ai.
            </p>
          </Modal>

          <div
            style={{
              display: "flex",
              flexGrow: "1",
              marginTop: "5%",
              marginLeft: "24%",
              gap: "100px",
            }}
          >
            <Tile className="clickable-cms-tile" onClick={handleSEOClick}>
              <img
                src="Salesdeck.png"
                alt="Logo"
                style={{ width: "50px", height: "50px", marginBottom: "2%" }}
              />
              <h6 style={{ fontWeight: "450", paddingTop: "3%" }}>
                Sales content
              </h6>
              <p
                style={{
                  fontSize: "var(--text-medium)",
                  fontWeight: "350",
                  paddingTop: "1%",
                  marginBottom: "10%",
                }}
              >
                Thought leadership, SEO, presentations
              </p>
              <ArrowRight style={{ marginLeft: "90%" }} />
            </Tile>
            <Tile
              // className={`clickable-cms-tile ${isDisabled ? "disabled" : ""}`}
              className="clickable-cms-tile"
              onClick={handleRFPClick}
            >
              <img
                src="RFP.png"
                alt="Logo"
                style={{ width: "50px", height: "50px", marginBottom: "2%" }}
              />
              <h6 style={{ fontWeight: "450", paddingTop: "3%" }}>RFP</h6>
              <p
                style={{
                  fontSize: "var(--text-medium)",
                  fontWeight: "350",
                  paddingTop: "1%",
                  marginBottom: "10%",
                }}
              >
                Issuance, responses and evaluation
              </p>
              <ArrowRight style={{ marginLeft: "90%" }} />
            </Tile>
          </div>
        </Column>
      </Grid>
    </div>
  );
};

export default SalesPub;

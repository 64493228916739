import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Column,
  Modal,
  TextInput,
  Tooltip,
  TextArea,
} from "@carbon/react";
import { Information } from "@carbon/icons-react";
import StyleCard from "../Components/StyleCard";
import { api } from "../store";
import FileUploader from "../Components/FileUploader";
import "./StyleProfile.scss";

const MAX_STYLE_EXAMPLE_WORD_COUNT = 1500;
const MIN_STYLE_EXAMPLES = 1;

const StyleProfilesPage = () => {
  const [styleProfiles, setStyleProfiles] = useState([]);
  const [isCreateStyleModalOpen, setCreateStyleModalState] = useState(false);
  const [styleName, setStyleName] = useState("");
  const [styleDesc, setStyleDescription] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Value cannot be empty");
  const [filesAndStatus, setFilesAndStatus] = useState([]);
  const [uploadErrorMessages, setUploadErrorMessages] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [isFilesError, setFilesError] = useState(false);

  useEffect(() => {
    // Function to fetch style profiles
    fetchStyleProfiles();
  }, []);

  const fetchStyleProfiles = async () => {
    try {
      let response = await api.get("style/getUserStyles");
      setStyleProfiles(response.data);
    } catch (error) {
      console.error("Error fetching style profiles:", error);
    }
  };

  const onModalClose = () => {
    setCreateStyleModalState(false);
    setStyleName("");
    setStyleDescription("");
    setFilesAndStatus([]);
    setUploadErrorMessages([]);
    setUploadError(false);
    setErrorMsg("Value cannot be empty");
    setFilesError("");
  };

  const rollbackStyleCreation = async (styleId) => {
    try {
      const response = await api.delete(
        "style/deleteStyleProfile?styleId=" + styleId
      );
    } catch (err) {
      console.log("Error:", err);
    }
  };

  const handleStyleCreate = async () => {
    setFilesError(false);
    if (!styleName.trim()) {
      setIsError(true);
      return;
    }
    if (filesAndStatus.length < MIN_STYLE_EXAMPLES) {
      setFilesError(true);
      return;
    }
    let styleId = "";

    try {
      const requestData = {
        name: styleName,
        description: styleDesc,
      };

      let response = await api.post("style/createStyleProfile", requestData);
      styleId = response.data.styleId;
      // Upload files with owner as styleId
      try {
        const fileIds = await submitUploadFiles(styleId);

        let updateData = {
          styleId: styleId,
          newFileIds: fileIds,
        };
        await api.put("style/updateStyleProfile", updateData);
        // Close modal
        setFilesAndStatus([]);
        onModalClose();
        fetchStyleProfiles();
      } catch (error) {
        rollbackStyleCreation(styleId);
      }
    } catch (error) {
      console.log(error);
      if (
        error.response.data.message ===
        "Style profile with this name already exists"
      ) {
        setIsError(true);
        setErrorMsg("Style profile with this name already exists");
      }
    }
  };

  function updateFileUploaderStatus(updatedFilesAndStatus) {
    setUploadErrorMessages([]);
    setFilesAndStatus(updatedFilesAndStatus);
    setUploadError(false);
  }

  async function uploadFiles(files, styleId) {
    const formData = new FormData();

    files.forEach((file) => formData.append("files", file));
    formData.append("ownerId", styleId);
    formData.append("fileTag", "style");
    formData.append("maxWordCount", MAX_STYLE_EXAMPLE_WORD_COUNT);
    try {
      const response = await api.post("file/uploadFiles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  }

  const submitUploadFiles = async (styleId) => {
    let updatedFilesAndStatus = filesAndStatus.map((fs) =>
      fs.status === "edit" ? { ...fs, status: "uploading" } : fs
    );
    let fileIds = [];
    let hasErrors = false;

    try {
      setFilesAndStatus(updatedFilesAndStatus);
      setUploadErrorMessages([]);

      // Only attempt upload if status is 'uploading'
      const filesToUpload = updatedFilesAndStatus
        .filter((fs) => fs.status === "uploading")
        .map((fs) => fs.file);

      const uploadResponses =
        filesToUpload.length > 0
          ? (await uploadFiles(filesToUpload, styleId)).data
          : [];

      for (let [index, response] of uploadResponses.entries()) {
        if (response.status !== 200) {
          updatedFilesAndStatus[index].status = "edit";
          setUploadErrorMessages((prevErrorMessages) => [
            ...prevErrorMessages,
            response.message,
          ]);
          hasErrors = true;
        } else {
          updatedFilesAndStatus[index].status = "complete";
          fileIds.push(response.filePath);
        }
      }

      // If any upload failed, throw an error
      if (hasErrors) {
        setFilesAndStatus(updatedFilesAndStatus);
        throw new Error("One or more file uploads failed");
      }

      // If no errors, clear all successfully completed files and errors
      const completedFilesAndStatus = updatedFilesAndStatus.filter(
        (fs) => fs.status === "complete"
      );
      if (completedFilesAndStatus.length === filesAndStatus.length) {
        setUploadErrorMessages([]);
      } else {
        setFilesAndStatus(updatedFilesAndStatus);
      }
    } catch (error) {
      updatedFilesAndStatus = filesAndStatus.map((fs) => {
        fs.status = "edit";
        return fs;
      });
      setUploadError(true);
      setFilesAndStatus(updatedFilesAndStatus);
      throw new Error("File upload failed");
    }
    return fileIds;
  };

  return (
    <div style={{ margin: "1.5rem 2rem" }}>
      <div className="page-header">
        <div className="page-title-container">
          <span className="page-title">Templates</span>
          <Button onClick={() => setCreateStyleModalState(true)}>
            Create template
          </Button>
        </div>
        <div className="page-support-text-container">
          <p>
            Style templates are utilized to emulate specific styles in generated
            content. Language and structure are extracted from the provided
            example files and applied to the generated content.
          </p>
        </div>
      </div>
      <Grid fullWidth>
        <Column
          xlg={16}
          lg={16}
          md={8}
          sm={4}
          style={{
            display: "flex",
            flexWrap: "wrap",
            height: "70vh",
            overflowY: "auto",
          }}
        >
          {styleProfiles.map((profile, index) => (
            <Column lg={4} md={4} sm={4} key={index}>
              <StyleCard
                styleName={profile.name}
                styleId={profile.id}
                styleDescription={profile.description}
                styleFiles={profile.files}
                isPreset={profile.preset}
                refreshStyles={fetchStyleProfiles}
                displayFile={profile.displayFile}
              />
            </Column>
          ))}
        </Column>
      </Grid>

      <Modal
        open={isCreateStyleModalOpen}
        modalHeading="Create template"
        primaryButtonText="Create"
        secondaryButtonText="Cancel"
        onRequestSubmit={handleStyleCreate}
        onRequestClose={onModalClose}
        size="md"
      >
        <div className="modal-description-text">
          <p>
            Create a template. The clearer and more similar your examples are,
            the better we can capture and replicate the style you're looking
            for. Mixing too many different styles in one template can make it
            tricky to get the results you want.
          </p>
        </div>
        <TextInput
          id="newStyle"
          labelText="Name"
          placeholder="e.g. Food blog post"
          value={styleName}
          invalid={isError}
          invalidText={errorMsg}
          onChange={(e) => {
            setIsError(false);
            setErrorMsg("Value cannot be empty");
            setStyleName(e.target.value);
          }}
          required
        />{" "}
        <br></br>
        <TextArea
          id="description"
          labelText="Description"
          placeholder={`A blog post that offers a detailed review of a delicious dish, sharing insights and personal experiences to help readers recreate it at home with confidence and delight.
          
Title: Catchy and relevant to the dish or recipe.
Introduction: Brief introduction to the dish or recipe and its appeal to potential readers.
Main Content:
* Dish Overview: Short summary of the dish or recipe and its origin or background.
* Ingredients: Detail the main ingredients used, focusing on quality and sourcing.
* Cooking Tips: Provide tips for preparing and cooking the dish optimally.
Conclusion: Recap the main points, suggest the ideal audience for the dish or recipe, and encourage reader engagement and feedback.`}
          value={styleDesc}
          onChange={(e) => setStyleDescription(e.target.value)}
          style={{ fontSize: "0.9em" }}
        />
        <br></br>
        <div style={{ display: "flex", gap: "10px" }}>
          <span
            style={{ display: "block", fontSize: "0.75rem", color: "#525252" }}
          >
            Style examples
          </span>
          <Tooltip
            align="top-left"
            label="Style examples are used to identify parameters like tone, structure, vocabulary, grade level etc."
          >
            <Information style={{ fill: "#525252" }} />
          </Tooltip>
        </div>
        <span
          style={{ display: "block", fontSize: "0.65rem", color: "#525252" }}
        >
          Minimum {MIN_STYLE_EXAMPLES} files required
        </span>{" "}
        <div style={{ margin: "10px 0" }}>
          {" "}
          <span
            style={{ display: "block", fontSize: "0.7rem", color: "#525252" }}
          >
            Accepted formats: .txt, .docx
          </span>
          <span
            style={{ display: "block", fontSize: "0.7rem", color: "#525252" }}
          >
            Maximum file size is 2GB.
          </span>
        </div>
        {isFilesError && (
          <div style={{ marginBottom: "20px" }}>
            <span
              style={{ display: "block", fontSize: "0.75rem", color: "red" }}
            >
              At least {MIN_STYLE_EXAMPLES} style examples are required
            </span>
          </div>
        )}
        <FileUploader
          uploadErrorMessages={uploadErrorMessages}
          filesAndStatus={filesAndStatus}
          updateFileUploaderStatus={updateFileUploaderStatus}
          maxFiles={100}
          uploadError={uploadError}
          allowAudioFiles={false}
          acceptTypes={[".txt", ".docx"]}
        />
      </Modal>
    </div>
  );
};

export default StyleProfilesPage;

import React, { useState } from "react";
import "./URLTagInput.scss";

const URLTagInput = ({ setUrls }) => {
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (
      (key === " " || key === "Enter" || key === ",") &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault();
      addTag(trimmedInput); // Use addTag for consistency
    }
  };

  const handleBlur = () => {
    const trimmedInput = input.trim();
    if (trimmedInput.length && !tags.includes(trimmedInput)) {
      addTag(trimmedInput);
    }
  };
  const addTag = (tag) => {
    const updatedTags = [...tags, tag];
    setTags(updatedTags);
    setUrls(updatedTags);
    setInput("");
  };

  const removeTag = (indexToRemove) => {
    const updatedTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(updatedTags);
    setUrls(updatedTags);
  };

  return (
    <div>
      <div className="tags-input-container">
        {tags.map((tag, index) => (
          <div key={index} className="tag-item">
            <span className="text">{tag}</span>
            <span className="close" onClick={() => removeTag(index)}>
              &times;
            </span>
          </div>
        ))}
        <input
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          type="text"
          className="tag-input"
          onBlur={handleBlur}
          //placeholder="Add URLs..."
        />
      </div>
    </div>
  );
};

export default URLTagInput;

import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Column,
  DataTable,
  TableContainer,
  TableToolbar,
  TableBatchActions,
  TableBatchAction,
  TableToolbarContent,
  TableToolbarSearch,
  Table,
  TableRow,
  TableSelectRow,
  TableHeader,
  TableBody,
  TableCell,
  TableHead,
  TextInput,
  Pagination,
  Modal,
  InlineLoading,
  TextArea,
} from "@carbon/react";
import { useNavigate } from "react-router-dom";
import { api } from "../store";
import { Save, Add } from "@carbon/icons-react";
import "./Dashboard.scss";
import "./RFPDashboard.scss";
import { TrashCan, Checkmark, DocumentAdd } from "@carbon/icons-react";

const RFPDashboard = () => {
  const navigate = useNavigate();
  const [rowData, setRows] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [issuerContext, setIssuerContext] = useState("");
  const [bidStrategy, setBidStrategy] = useState("");
  const [isModalOpen, setModalState] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRow, setSelectedRow] = useState({ id: null });
  const [newName, setNewName] = useState("");
  const [isUpdateModalOpen, setUpdateModalState] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);
  const [selectedRowProject, setselectedRowProject] = useState("");
  const [isDeleteError, setDeleteError] = useState(false);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");

  const changePaginationState = (pageInfo) => {
    if (page !== pageInfo.page) {
      setPage(pageInfo.page);
    }
    if (pageSize !== pageInfo.pageSize) {
      setPageSize(pageInfo.pageSize);
    }
  };

  useEffect(() => {
    if (!isModalOpen) {
      setIsLoading(false);
      setIsUploaded(false);
    }
  }, [isModalOpen]);

  const headers = [
    {
      key: "rfx_title",
      header: "RFX Title",
    },
    {
      key: "client",
      header: "Client",
    },
    {
      key: "owner",
      header: "Owner",
    },
    {
      key: "start_date",
      header: "Start Date",
    },
    {
      key: "due_date",
      header: "Due Date",
    },
    {
      key: "status",
      header: "Status",
    },
  ];

  const rfpProjects = [
    // {
    //   rfx_title: "Demo_CRM",
    //   client: "Innovative CRM Solutions",
    //   owner: "You",
    //   start_date: "15 July 2024",
    //   due_date: "23 Sep 2024",
    //   status: "In progress",
    // },
    {
      rfx_title: "Database Redesign",
      client: "Acme Inc.",
      owner: "You",
      start_date: "7 May 2024",
      due_date: "21 Aug 2024",
      status: "In progress",
    },
    {
      rfx_title: "Enterprise software implementation",
      client: "Globex Corp.",
      owner: "Jennifer",
      start_date: "17 Apr 2024",
      due_date: "30 Jul 2024",
      status: "Completed",
    },
    {
      rfx_title: "Mobile app development",
      client: "Stark Industries",
      owner: "Timothy",
      start_date: "5 Mar 2024",
      due_date: "27 Jul 2024",
      status: "Submitted",
    },
    {
      rfx_title: "Branding and marketing campaign",
      client: "Wayne Enterprises",
      owner: "Linda",
      start_date: "12 Feb 2024",
      due_date: "13 Sep 2024",
      status: "Submitted",
    },
    {
      rfx_title: "UI/UX design for web application",
      client: "Globex Corp.",
      owner: "You",
      start_date: "9 Jan 2024",
      due_date: "1 Feb 2024",
      status: "Secured",
    },
  ];

  const getProject = async () => {
    // let response = await api.get("project/getProjects");
    // const rowsWithId = response.data.projects.map((row, index) => ({

    const rowsWithId = rfpProjects.map((row, index) => ({
      ...row,
      id: `${index}`,
    }));
    setRows(rowsWithId);
  };

  useEffect(() => {
    getProject();
    // getUser();

    // Parse the query string for 'modal' parameter
    const queryParams = new URLSearchParams(location.search);
    const modalParam = queryParams.get("modal");
    if (modalParam === "open") {
      setModalState(true);
    }
  }, [location]);

  // const getUser = async () => {
  //   let response = await api.get("user/getUserProfile");
  //   setUser(response.data);
  // };

  // const addNewProject = async () => {
  //   let response = await api.post("project/createProject", {
  //     name: name,
  //     description: description,
  //   });
  //   return response;
  // };

  const handleCreate = async () => {
    if (name === "") {
      setIsError(true);
      return;
    }
    console.log("Creating project with name: " + name);
    console.log("Navigating to rfp edit page");
    navigate("/rfp-loader", { state: { fileName: uploadedFileName } });
    // let response = await addNewProject();
    // navigate("/editor", {
    //   state: {
    //     projectId: response.data.projectId,
    //     projectName: response.data.projectName,
    //     user: user,
    //   },
    // });
  };

  const handleProjectLinkClick = (event, projectId, projectName) => {
    event.preventDefault(); // Prevents the link from navigating to the URL
    console.log("RFX Project link clicked");
    // navigate("/editor", {
    //   state: {
    //     projectId: projectId,
    //     projectName: projectName,
    //     pregenerated: true,
    //     user: user,
    //   },
    // });
  };

  const handleRowClick = (selRow) => {
    const updatedRows = rowData.map((row) => {
      if (row.id === selectedRow.id) {
        return { ...row, isSelected: false };
      } else if (row.id === selRow.id) {
        return { ...row, isSelected: true };
      } else {
        return row;
      }
    });
    setRows(updatedRows);
    setSelectedRow(selRow);
    setselectedRowProject(selRow.cells[0].value);
  };

  const handleProjectDelete = async (event) => {
    event.preventDefault(); // Prevents the link from navigating to the URL
    const row = selectedRow;
    if (deleteName !== row.cells[0].value) {
      setDeleteError(true);
      return;
    }
    let projectId = row.id;
    try {
      // let response = await api.delete(
      //   "project/deleteProject?projectId=" + projectId
      // );
      //refresh the table
      setDeleteModalState(false);
      getProject();
      setDeleteName("");
      setSelectedRow({ id: null });
    } catch (error) {
      console.log("Error while deleting a project: " + error.msg);
    }
  };

  const handleAddClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    // const selectedFile = event.target.files[0];
    // setFile(selectedFile);
    // setError(null);
    if (file) setUploadedFileName(file.name);
    setIsLoading(true);
    setIsUploaded(false);

    // Simulate a delay (e.g., for file processing or upload)
    await new Promise((resolve) => setTimeout(resolve, 2000));

    setIsLoading(false);
    setIsUploaded(true);

    // const formData = new FormData();
    // formData.append("files", selectedFile);
    // formData.append("ownerId", props.projectId);
    // formData.append("fileTag", "uploaded");

    // try {
    //   if (selectedFile) {
    //     const response = await api.post("file/uploadFiles", formData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     });
    //     if (response.status === 200) {
    //       setIsUploaded(true);
    //     } else {
    //       setError("Failed to upload file");
    //     }
    //   }
    // } catch (err) {
    //   console.log(err);
    //   setError("Error uploading file");
    // } finally {
    //   setIsLoading(false);
    //   props.refreshFiles();
    //   props.refreshEditorFiles();
    // }
  };

  const handleProjectRename = async (event) => {
    event.preventDefault(); // Prevents the link from navigating to the URL
    if (newName === "") {
      setIsError(true);
      return;
    }
    const row = selectedRow;
    let projectId = row.id;
    try {
      // let response = await api.put("project/renameProject", {
      //   name: newName,
      //   projectId: projectId,
      // });
      setUpdateModalState(false);
      //refresh the table
      getProject();
      setselectedRowProject(newName);
      row.cells[0].value = newName;
      setSelectedRow({ id: null });
      setNewName("");
    } catch (error) {
      console.log("Error while deleting a project: " + error.msg);
    }
  };

  const Card = ({ title, value, subText }) => {
    return (
      <div className="card">
        <h2>{title}</h2>
        <p>{value}</p>
        <span>{subText}</span>
      </div>
    );
  };

  return (
    <div
      style={{
        margin: "1.5rem 2rem",
      }}
    >
      <div>
        <Modal
          open={isModalOpen}
          modalHeading="Create a new project"
          primaryButtonText="Create"
          secondaryButtonText="Cancel"
          onRequestSubmit={handleCreate}
          onRequestClose={() => {
            setModalState(false);
          }}
          size="sm"
          className="create-project-modal"
        >
          <TextInput
            id="projectname"
            labelText="Project Name"
            value={name}
            invalid={isError}
            invalidText="Value cannot be empty"
            onChange={(e) => setName(e.target.value)}
            className="create-project-name-input"
          />{" "}
          <br></br>
          {/* <TextInput
            id="projectdesc"
            labelText="Project Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            enableCounter={true}
            className="create-project-desc-input"
          />
          <br></br> */}
          <TextInput
            id="projectdesc"
            labelText="Issuer Name"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            enableCounter={true}
            className="create-project-desc-input"
          />
          <br></br>
          <TextInput
            id="issuerwebsite"
            labelText="Issuer Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            enableCounter={true}
            className="create-project-desc-input"
          />
          <br></br>
          <TextArea
            id="issuercontext"
            labelText="Additional Issuer Context"
            value={issuerContext}
            onChange={(e) => setIssuerContext(e.target.value)}
            enableCounter={true}
            className="create-project-desc-input"
          />
          <br></br>
          <TextArea
            id="bidstrategy"
            labelText="Winning Bid Strategy"
            value={bidStrategy}
            onChange={(e) => setBidStrategy(e.target.value)}
            enableCounter={true}
            className="create-project-desc-input"
          />
          <br></br>
          <input
            id="fileInput"
            type="file"
            multiple
            accept=".txt, .docx, .pdf"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {/* REPLACE WITH ADD FILE RESOURCE FOR THE REAL THING */}
          <button
            // kind="primary"
            onClick={handleAddClick}
            className="add-file-button"
          >
            <DocumentAdd />
            Upload RFX Files
          </button>
          {isLoading && (
            <InlineLoading
              description="Uploading file(s)..."
              status="active"
              style={{ marginTop: "0.5rem" }}
            />
          )}
          {isUploaded && (
            <InlineLoading
              style={{
                marginTop: "0.5rem",
                // display: "flex",
                // alignItems: "center",
              }}
              status="finished"
              description="File(s) uploaded successfully."
            >
              {/* <Checkmark style={{ marginRight: "0.5rem", color: "var(--element-hover-color)" }} /> File(s) uploaded
              successfully. */}
            </InlineLoading>
          )}
        </Modal>
        <Modal
          open={isUpdateModalOpen}
          modalHeading="Update Project"
          primaryButtonText="Update"
          secondaryButtonText="Cancel"
          onRequestSubmit={handleProjectRename}
          onRequestClose={() => {
            setUpdateModalState(false);
          }}
          size="sm"
        >
          <TextInput
            id="newName"
            labelText="Project Name"
            value={newName}
            invalid={isError}
            invalidText="Value cannot be empty"
            onChange={(e) => setNewName(e.target.value)}
          />{" "}
          <br></br>
        </Modal>
        <Modal
          open={isDeleteModalOpen}
          modalHeading="Confirm Delete Project"
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          onRequestSubmit={handleProjectDelete}
          onRequestClose={() => {
            setDeleteModalState(false);
          }}
          size="sm"
        >
          <TextInput
            id="deleteName"
            labelText={
              "To delete the project, type the project name: " +
              selectedRowProject
            }
            value={deleteName}
            invalid={isDeleteError}
            invalidText="Value cannot be empty or does not match the project name"
            onChange={(e) => {
              setDeleteError(false);
              setDeleteName(e.target.value);
            }}
          />{" "}
          <br></br>
        </Modal>
      </div>
      <div className="page-header">
        <div className="page-title-container">
          <span className="page-title">RFX Projects</span>
          <Button
            onClick={(e) => {
              setModalState(true);
              e.stopPropagation();
            }}
            kind="primary"
            className="create-project-button"
          >
            Create Project
          </Button>
        </div>
      </div>
      <Grid fullWidth style={{ padding: "0 1.3rem" }}>
        <Column xlg={16} lg={16} md={8} sm={4}>
          <div className="card-container">
            <Card title="Active RFXs" value="24" subText="+3 from this week" />
            <Card title="Due Soon" value="8" subText="in two weeks" />
            <Card title="Completed" value="12" subText="+1 from last week" />
          </div>
          <div className="project-container">
            <DataTable
              rows={rowData}
              headers={headers}
              selectedRows={[selectedRow.id]}
              onRowClick={(row) => handleRowClick(row)}
              radio
            >
              {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getSelectionProps,
                getBatchActionProps,
                onInputChange,
                selectedRows,
              }) => (
                <TableContainer>
                  <TableToolbar>
                    <TableBatchActions
                      {...getBatchActionProps()}
                      shouldShowBatchActions={selectedRow.id != null}
                      totalSelected={selectedRow.id != null ? 1 : 0}
                      onCancel={() => {
                        setSelectedRow({ id: null });
                        //setIsBatchActionsOpen(false); // close batch actions
                      }}
                    >
                      <TableBatchAction
                        tabIndex={
                          getBatchActionProps().shouldShowBatchActions ? 0 : -1
                        }
                        renderIcon={TrashCan}
                        onClick={(e) => {
                          setDeleteModalState(true);
                          e.stopPropagation();
                        }}
                      >
                        Delete
                      </TableBatchAction>
                      <TableBatchAction
                        tabIndex={
                          getBatchActionProps().shouldShowBatchActions ? 0 : -1
                        }
                        renderIcon={Save}
                        onClick={(e) => {
                          setUpdateModalState(true);
                          e.stopPropagation();
                        }}
                      >
                        Rename
                      </TableBatchAction>
                    </TableBatchActions>
                    <TableToolbarContent>
                      <TableToolbarSearch
                        tabIndex={
                          getBatchActionProps().shouldShowBatchActions ? -1 : 0
                        }
                        onChange={onInputChange}
                      />
                    </TableToolbarContent>
                  </TableToolbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <th scope="col" />
                        {headers.map((header, i) => (
                          <TableHeader
                            key={i}
                            {...getHeaderProps({
                              header,
                            })}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice((page - 1) * pageSize)
                        .slice(0, pageSize)
                        .map((row) => (
                          <TableRow
                            {...getRowProps({ row })}
                            onClick={() => handleRowClick(row)}
                            isSelected={selectedRow.id === row.id}
                          >
                            <TableSelectRow
                              {...getSelectionProps({ row })}
                              checked={selectedRow.id === row.id}
                              selected={row.isSelected}
                            ></TableSelectRow>
                            {row.cells.map((cell) => {
                              if (cell.info.header === "rfx_title") {
                                return (
                                  <TableCell key={cell.id}>
                                    <a
                                      style={{
                                        cursor: "pointer",
                                        color: "var(--button-primary)",
                                      }}
                                      onClick={(event) =>
                                        handleProjectLinkClick(
                                          event,
                                          row.id,
                                          cell.value
                                        )
                                      }
                                    >
                                      {cell.value}
                                    </a>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell key={cell.id}>
                                    {cell.value}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
            <Pagination
              onChange={changePaginationState}
              page={page}
              pageSize={pageSize}
              pageSizes={[5]}
              totalItems={rowData.length}
            />
          </div>
        </Column>
      </Grid>
    </div>
  );
};

export default RFPDashboard;

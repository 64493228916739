import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Column,
  Checkbox,
  Accordion,
  AccordionItem,
  RadioButton,
} from "@carbon/react";
import StatusPanel from "../Components/rfp/StatusPanel";
import "./Page.scss";
import ScopeTable from "../Components/rfp/ScopeTable";
import CustomTextArea from "../Components/rfp/CustomTextArea";
import ChatWidget from "../Components/ChatWidget";

const Page = ({}) => {
  const { state } = useLocation();
  const [reviewed, setReviewed] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const [index, setIndex] = useState(0);
  const fileName = state.fileName;
  const resp_CRM = [
    {
      title: "Introduction & Purpose",
      reviewed: false,
      contentType: "text",
      content:
        "The  Office  of  the  Treasurer  of  the  State  of  Connecticut  (the  “State”) hereby requests proposals from \
qualified  technology  firms  (each,  a  “Respondent”)  interested  in  providing  Customer  Relationship \
Management (“CRM”) services via an investment management-focused relationship management system \
for the Pension Funds Management Division (“PFM”). \n\n \
The Treasurer, elected quadrennially, serves as the principal fiduciary of the Connecticut Retirement Plans \
and Trust Funds, with a value of approximately $43 billion as of June 30, 2022.  PFM manages six pension \
plans  and  nine  trusts  collectively  known  as  the  “CRPTF.”  These  15  plans  and  trusts  may  invest  in  11 \
combined investment funds, which invest in various asset classes including public equities, fixed income, \
private equity, private credit, real estate, infrastructure/natural resources and, other alternative assets. An \
independent Investment Advisory Council (“IAC”), appointed pursuant to Connecticut General Statutes \
§3-13b, is charged with advising the Treasurer on matters pertaining to the investment of the CRPTF’s \
assets, and the Chief Investment Officer (“CIO”) of the Office of the Treasurer oversees the day-to-day \
operations. \n\n \
Respondents that would like to provide CRM services, as more fully described in Section II, herein, may \
submit proposals for consideration.  The State expects to select Respondent(s) to provide CRM services \
on an ongoing basis.  The selected Respondent(s) will be expected to execute a personal service agreement \
with the Treasurer, with an anticipated term of five (5) years. \
 The  State  expects  Respondents  to  complete  the  Legal  and  Policy  Attachments  (1A  through  7A)  in \
accordance with the instructions provided therein. The legal and policy attachments may be downloaded \
via  this  link:  Compliance  Reporting  (ct.gov).  Completion  of  the  Legal  and  Policy  Attachments  is  a \
requirement for consideration in this RFP. ",
      summary:
        "The Office of the Treasurer of Connecticut is seeking proposals from qualified technology firms to provide Customer Relationship Management (CRM) services for its Pension Funds Management Division (PFM). The Treasurer oversees the Connecticut Retirement Plans and Trust Funds (CRPTF), valued at approximately $43 billion, which includes six pension plans and nine trusts investing in various asset classes. Proposals are expected to comply with specified legal and policy requirements and the selected firm(s) will enter into a five-year service agreement. Interested firms must complete required attachments for consideration.",
    },
    {
      title: "Scope of Services",
      reviewed: false,
      header: ["Status", "Scope of Services", "Assigned to"],
      rows: [
        {
          Status: "Complete",
          "Scope of Services": "Contact and Data Storage",
          "Assigned to": "You",
          expandedContent: [
            {
              text: "Electronically store materials/files that can be retrieved in a discoverable fashion",
              status: "complete",
            },
            {
              text: "Control/limit access to documents and other data",
              status: "complete",
            },
            {
              text: "Customize system functionality for individual users or user groups including view, read/write access",
              status: "complete",
            },
            {
              text: "Sync contacts, emails, and calendar with Microsoft Outlook",
              status: "complete",
            },
            {
              text: "Easy search capabilities by name, date, keyword, etc. including PDF document text searching and ability to copy and paste",
              status: "complete",
            },
            {
              text: "Templates to import and export data in an easy to update format",
              status: "complete",
            },
            {
              text: "Integrate with external data sources (Outlook, Excel, Word, etc.)",
              status: "complete",
            },
            {
              text: "Email logging and tagging (both automated vs manual options)",
              status: "complete",
            },
          ],
          sources:
            "Source documentation: <br>\n\n\
1. www.innovativecrm.com/products/storage\n\
2. https://docs.google.com/spreadsheets/d/1it2l0aRCityPSOLRn6xOCN\n\n\
Past RFPs:\n\
1. CRM RFP 213491248o\n\
2. CRM RFP 7473494213\n\n\
Knowledge Center:\n\
1. /files/product-info/storage",
        },
        {
          Status: "Pending",
          "Scope of Services": "Research Management",
          "Assigned to": "Jennifer",
        },
        {
          Status: "Complete",
          "Scope of Services": "Document Management",
          "Assigned to": "Timothy",
        },
        {
          Status: "Complete",
          "Scope of Services": "Workflow Management",
          "Assigned to": "Linda",
        },
        {
          Status: "Pending",
          "Scope of Services": "Technology",
          "Assigned to": "You",
        },
        {
          Status: "Complete",
          "Scope of Services":
            "State of Connecticut Information Technology Standards and Guidelines",
          "Assigned to": "Linda",
        },
        {
          Status: "Pending",
          "Scope of Services": "Implementation and Support",
          "Assigned to": "You",
        },
      ],
      contentType: "table",
    },
    {
      title: "Fees",
      reviewed: false,
      header: ["Status", "Item", "Assigned to"],
      rows: [
        {
          Status: "Complete",
          Item: "Implementation costs (including license costs during implementation), if any.",
          "Assigned to": "Robin",
        },
        {
          Status: "Pending",
          Item: "License costs/price (include per user and enterprise-wide fee options)",
          "Assigned to": "Robin",
          expandedContent:
            "1. Annual enterprise license cost (up to 200 users): $ 350,000 \n\n \
2. Annual enterprise license cost (up to 1,000 users): $ 1,000,000 \n\n \
3. Annual enterprise license cost (up to 5000 users): $ 3,000,000 \n\n \
4. Annual per user license cost: $ 3,000",
          sources:
            "Source documentation: \n\n\
1. www.innovativecrm.com/pricing/enterprise-license\n\
2. https://docs.google.com/spreadsheets/d/1it2l0CityPSOLRn6xOCN\n\n\
Past RFPs:\n\
1. CRM RFP 491248o\n\
2. CRM RFP 3494213\n\n\
Knowledge Center:\n\
1. /files/product-info/pricing/enterprise",
        },
        {
          Status: "Complete",
          Item: "Support costs",
          "Assigned to": "Robin",
        },
        {
          Status: "Not Started",
          Item: "Any other additional and future costs to be expected",
          "Assigned to": "Robin",
        },
      ],
      contentType: "table",
    },
    {
      title: "Contract Term",
      reviewed: false,
      content:
        "The Treasurer intends to enter into the contract for a five-year term, expected to begin approximately \
January 1, 2023. The contract shall include a 30-day termination provision at the Treasurer’s sole discretion.",
      contentType: "text",
    },
    {
      title: "Minimum Qualifications",
      reviewed: false,
      content:
        "Respondents submitting a proposal in response to this RFP must demonstrate the following minimum \
qualifications in order to be considered: \n \
For CRM Services Proposal \n \
1. At least five (5) years of experience by the firm’s key professionals in providing CRM services \
via an investment management-focused relationship management system to institutional \
investors including foundations, endowments and pension funds. \n \n \
2. Neither key professionals nor the firm have any material or potential conflicts of interest that \
are not disclosed on Attachment 4A - Conflicts of Interest Certification submitted as part \
of the required Legal and Policy Attachments.",
      contentType: "text",
    },
    {
      title: "Due Dates",
      reviewed: false,
      contentType: "text",
      content:
        "Proposals must be recieved by 4:00 PM EST on Friday October 7, 2022. All proposals must be emailed to PFM.RFP \
      @ct.gov and include the words 'CRM SERVICES RFP' in the subject line. \n\n \
September 9, 2022 ----------------------------------------------------------------------------------------RFP Released \n \
September 23, 2022 ---------------------------------------------------------------Deadline for Submitting RFP Questions\n \
September 30, 2022 ---------------------------------------------------Responses to RFP Questions Posted on Agency Website\n \
October 7, 2022 4:00 PM ET --------------------------------------------------------------Deadline for Submitting Proposals\n \
October 2022 – November 2022 -----------------------------------------------Approximate Date of Interviews with Respondents\n \
November 22, 2022 --------------------------------------------------------Approximate Date for Contractor(s) To Be Selected\n \
No later than January 1, 2023 -------------------------------------------------------------Approximate Start of Contract(s)",
    },
    {
      title: "Evaluation Criteria",
      reviewed: false,
      header: ["Status", "Scope of Services", "Assigned to"],
      rows: [
        {
          Status: "Complete",
          "Scope of Services": "Firm",
          "Assigned to": "Jennifer",
        },
        {
          Status: "Pending",
          "Scope of Services": "Professional Staff",
          "Assigned to": "You",
        },
        {
          Status: "Pending",
          "Scope of Services": "Cost",
          "Assigned to": "You",
        },
      ],
      contentType: "table",
    },
    {
      title: "Content of Responses",
      reviewed: false,
      header: ["Status", "Items"],
      rows: [
        {
          Status: "Complete",
          Items: "Firm",
        },
        {
          Status: "Pending",
          Items: "Required Format and Content of Responses",
          expandedContent: [
            {
              text: "Cover letter",
              status: "complete",
              expandedContent: [
                {
                  text: "Instructions",
                  status: "complete",
                  expandedContent:
                    "The proposal should contain a cover letter with the following information: \n\n\
a. Respondent Information\n\
i. Name of Respondent\n\
ii. Business Location\n\
iii. Mailing Address\n\n\
b. Respondent’s Representatives\n\
The Respondent must designate an authorized representative and one alternate who may speak and act on behalf of the Respondent in all dealings with the agency, if necessary. Provide the following information for each individual. \n\
i. Name and title \n\
ii. Telephone Number \n\
iii. Email address \n\n\
c. A statement that the Respondent has the capability to provide the requested services. \n\n\
d. A statement that the Respondent meets the minimum qualifications set out in Section V. If the Respondent does not meet any of the minimum qualifications, the Respondent must identify which qualification(s) are not met and make a detailed case as to why the Treasurer should consider the Respondent’s firm and services. \n\n\
e. A statement that the Respondent has thoroughly reviewed the RFP and acknowledges and accepts all terms and conditions included in the RFP. \n\n\
f. A statement that the Respondent has read and accepts the agency’s Personal Services Agreement in its entirety and without amendment, or has submitted proposed revisions. \n\n\
g. A statement that the Respondent has read and accepts the State’s contract compliance requirements. \n\n\
The cover letter must be signed by a person authorized to bind the Respondent to all commitments made in its proposal. The signature should include the printed name and title of the authorized person.",
                },
                {
                  text: "Letter",
                  status: "complete",
                  expandedContent:
                    "[Date] \n\n\
Raymond Tuohey \n\
Pension Funds Management, 2nd floor \n\
Office of the Treasurer \n\
165 Capitol Avenue \n\
Hartford, CT 06106 \n\n\
Dear Mr. Tuohey, \n\n\
Subject: CRM SERVICES RFP \n\n\
I am writing to submit our proposal in response to the Request for Proposals (RFP) issued by the Office of the Treasurer of the State of Connecticut for Customer Relationship Management (CRM) services via an investment management-focused relationship management system for the Pension Funds Management Division (PFM). \n\n\
Capability Statement: \n\
We have extensive experience and expertise in providing advanced CRM solutions tailored for investment management. Our proven track record in delivering scalable and secure CRM systems ensures that we can effectively meet the specific needs of the Pension Funds Management Division. \n\n\
Minimum Qualifications: \n\
We confirm that we meet all the minimum qualifications as outlined in Section V of the RFP. We have a highly skilled team and a robust infrastructure that align with the requirements set forth by the Office of the Treasurer. \n\n\
Acknowledgement of RFP Terms and Conditions: \n\
We have thoroughly reviewed the RFP and hereby acknowledge and accept all the terms and conditions included therein. Furthermore, we confirm that we have read and accept the agency’s Personal Services Agreement in its entirety and without amendment. \n\n\
Contract Compliance Requirements: \n\
[Your Company Name] has read and fully accepts the State’s contract compliance requirements. We are committed to adhering to all specified guidelines and regulations to ensure a compliant and successful partnership.",
                },
              ],
            },
            { text: "Fee proposal", status: "complete" },
            { text: "Compliance, legal & policy", status: "complete" },
            {
              text: "Firm Questionnaire",
              status: "pending",
              expandedContent: [
                {
                  text: "Firm Overview",
                  status: "pending",
                  question:
                    "Provide your firm’s complete name and primary address, include the name, title,\
address, telephone number and email address of a primary and an alternate contact\
person who is authorized to act for the firm for this RFP. Briefly list the other\
geographic locations of your offices and describe the functions performed in each\
of those offices.",
                  expandedContent:
                    "Company Name: Innovative CRM Solutions, Inc. \n\n\
Primary Address: Innovative CRM Solutions, Inc. 123 Tech Avenue, Suite 400 San Francisco, CA 94103 USA \n\n\
Primary Contact Person: John Smith\n\
Title: Director of Sales\n\
Address: Innovative CRM Solutions, Inc. 123 Tech Avenue, Suite 400 San Francisco, CA 94103 USA\n\
(415) 555-1234,  john.smith@innovativecrm.com\n\n\
Alternate Contact Person: Emily Johnson\n\
Title: Senior Account Manager\n\
Address: Innovative CRM Solutions, Inc. 123 Tech Avenue, Suite 400 San Francisco, CA 94103 USA\n\
(415) 555-5678, emily.johnson@innovativecrm.com\n\n\
Geographic Locations and Functions\n\
New York Office: 456 Madison Avenue, 10th Floor, New York, NY 10022, USA\n\
Functions: Sales and Marketing, Client Relations, Technical Support\n\n\
Chicago Office: 789 Wacker Drive, Suite 2000, Chicago, IL 60601, USA\n\
Functions: Product Development Research and Development Customer Service\n\n\
London Office: 25 Old Broad Street, 5th Floor, London, EC2N 1HN, UK\n\
Functions: European Market Sales, Client Support, Custom Solutions Development\n\n\
Additional Information\n\
Innovative CRM Solutions, Inc. prides itself on providing comprehensive CRM services to clients globally, ensuring personalized support and development tailored to each region’s unique needs.",
                  sources:
                    "Source documentation:\n\n\
1. www.innovativecrm.com/contact\n\
2. https://docs.google.com/spreadsheets/d/LRn6xOCN\n\n\
Past RFPs:\n\
1. CRM RFP 213491248o\n\
2. CRM RFP 7473494213\n\n\
Knowledge Center:\n\
1. /files/company-info/contact",
                },
                { text: "Firm Ownership", status: "pending" },
                {
                  text: "Organization - Legal, Insurance, Audit and Data Security",
                  status: "complete",
                },
              ],
            },
            { text: "Clients", status: "complete" },
            {
              text: "Personnel - Qualifications, Roles and Compensation",
              status: "pending",
            },
            { text: "Contact and Data Storage", status: "complete" },
            { text: "Research Management", status: "pending" },
          ],
        },
      ],
      contentType: "table",
    },
    {
      title: "RFP Conditions",
      reviewed: false,
      contentType: "text",
      content:
        "1. All proposals submitted in response to this RFP will become the sole property of the Office of the State Treasurer. \n\n \
2. The State Treasurer shall be required, as a part of the procurement process, to certify that the \
Respondent awarded this contract was not selected as a result of collusion, the giving of a gift \
or the promise of a gift, compensation, fraud or inappropriate influence from any person. \n\n \
3. The successful Respondent will be required to complete certain representations and \
certifications in the contract (a copy of which is provided in this RFP), including, without \
limitation, representations regarding the giving of gifts, use of consultants, nondiscrimination, \
compliance with laws). Failure to agree to such representations and certifications in the \
contract. shall be grounds for disqualification. \n\n \
4. Inclusion of Taxes in Prices – The Office of the Treasurer is exempt from the payment of \
excise, transportation, and sales and use taxes imposed by the Federal Government or any state \
or local government. Such taxes must not be included in your fee proposal. \n\n \
5. Any product, whether acceptable or unacceptable, developed under a contract awarded as a \
result of the RFP will become the sole property of the Office of the State Treasurer. \n\n \
6. Timing and sequence of events resulting from this RFP will ultimately be determined by the \
Office of the State Treasurer. \n\n \
7. The Respondent agrees that the proposal will remain valid for a period of 365 days after the \
deadline for submission and may be extended beyond that time by mutual agreement. \n\n \
8. The Office of the State Treasurer may amend or cancel this RFP, prior to the due date and time, \
if the agency deems it to be necessary, appropriate or otherwise in the best interests of the \
State. Failure to acknowledge receipt of amendments, in accordance with the instructions \
contained in the amendments, may result in a proposal not being considered. \n\n \
9. Any costs and expenses incurred by Respondents in preparing or submitting proposals, \
including travel expenses incurred to attend Respondents’ meetings or interviews are the sole \
responsibility of the Respondent. \n\n \
10. No additions or changes to the original proposal will be allowed after submission. While \
changes are not permitted, clarification of proposals may be required by the Office of the State \
Treasurer at the Respondent’s sole cost and expense. \n\n \
11. The Respondent represents and warrants that the proposal is not made in connection with any \
other Respondent and is in all respects fair and without collusion or fraud. The Respondent \
further represents and warrants that the Respondent did not participate in any part of the RFP \
development process, had no knowledge of the specific contents of the RFP prior to its \
issuance, and that no agent, representative or employee of Office of the State Treasurer \
participated directly in the Respondent’s proposal preparation. \n\n \
12. All responses to the RFP must conform to the instructions. Failure to include any required \
signatures, provide the required number of copies, to meet deadlines, answer all questions, \
follow the required format, or failure to comply with any other requirements of this RFP may \
be considered appropriate cause for rejection of the response. \n\n \
13. The Respondent must accept Office of the State Treasurer’s standard contract language and \
conditions. See Personal Services Agreement, attached hereto. \n\n \
14. The Office of the State Treasurer reserves the right to award in part or to reject any and all \
proposals in whole or in part for misrepresentation or if the Respondent is in default of any \
prior State contract, or if the proposal limits or modifies any of the terms and conditions and/or \
specifications of the RFP. The Office of the State Treasurer also reserves the right to waive \
technical defects, irregularities and omissions if, in its judgment, the best interest of the State \
will be served. \n\n \
15. The Office of the State Treasurer reserves the right to correct inaccurate awards resulting from \
its clerical errors. This may include, in extreme circumstances, revoking the awarding of a \
contract already made to a Respondent and subsequently awarding the contract to another \
Respondent. Such action on the part of the Office of the State Treasurer shall not constitute a \
breach of contract on the part of the agency since the contract with the initial Respondent is \
deemed to be void ab initio and of no effect as if no contract ever existed between Office of \
the State Treasurer and the Respondent. \n\n \
16. Prior to its engagement by the Office of the Treasurer, the successful Respondent shall furnish \
the Office of the Treasurer with a current and valid Letter of Good Standing issued by the State \
of Connecticut Department of Revenue Services, pursuant to Connecticut General Statutes 12- \
2. The failure of the successful Respondent to timely provide a Letter of Good Standing prior \
to engagement may result in the removal and replacement of the successful Respondent.",
    },
    {
      title: "Services Agreement",
      reviewed: false,
      contentType: "text",
      content:
        "This PERSONAL SERVICES AGREEMENT (“Agreement”) is entered into as of ________ \
__, 2022 (the “Commencement Date”), between the STATE OF CONNECTICUT , acting through its \
Treasurer (the “Treasurer” or the “State”) and -_____________ a _____________ corporation, having a \
principal place of business at _________ (the “Contractor”). \
WHEREAS, Section 3-11a of the Connecticut General Statutes authorizes the Treasurer to enter \
into contracts to as may be necessary and proper for the discharge of his duties; \
WHEREAS, having engaged in a process designed to solicit and evaluate bids, the Treasurer has \
selected the Contractor based on the Contractor’s proposal to provide __________ services, which may \
include but are not limited to, - \
______________________________. The Treasurer wishes to appoint \
the Contractor to provide such services, and the Contractor wishes to accept this appointment, on the terms \
and conditions set forth below; and \
WHEREAS, the Contractor hereby reaffirms the reliability and accuracy of the written and oral \
representations made to the Treasurer in Contractor’s solicitation of this Agreement; \
NOW, THEREFORE, in consideration of the foregoing recitals that are incorporated herein, and \
for other good and valuable consideration, the receipt and sufficiency of which are hereby \
acknowledged, the parties agree as follows: \n \
Term  \
This Agreement shall commence on the Commencement Date and shall expire on the earlier of (i) \
_________, or (ii) termination by either party as set forth in Section 33 hereof (Termination). \
Definitions \
A. “Agreement” shall mean this Personal Services Agreement. \
“C.G.S.” shall mean the Connecticut General Statutes. \
“Commencement Date” shall have the meaning set forth in the introductory paragraph \
hereto. \
“Commission” shall mean the Connecticut Commission on Human Rights and \
Opportunities. \
“Contractor” shall mean \
_________________ \
.  \
“Election Laws” shall mean C.G.S. Section 9-612, 9-613, et seq, as amended from time \
to time. \
“Internal Investigation” shall have the meaning set forth in Section 21 (Legal \
proceedings) hereof. \
2 \
“Proceeding” shall have the meaning set forth in Section 21 (Legal Proceedings) hereof. \
“State” shall mean the State of Connecticut, acting through its Treasurer. \
“State Ethics Code” shall mean Chapter 10 of the Connecticut General Statutes. \
“Treasurer” shall mean (i) the Office of the State Treasurer; (ii) the then-current \
Treasurer of the State of Connecticut in his capacity as trustee; and/or (iii) the State \
Treasurer’s authorized agent, employee or designee.",
    },
    { title: "Templates", reviewed: false, content: "Content for Templates" },
  ];
  const resp_Staffing = [
    {
      title: "RFP Introduction & Information",
      reviewed: false,
      contentType: "text",
      content:
        "The purpose of this document is to provide interested and qualified parties with information \
to enable them to prepare and submit competitive proposals for staffing services for \
professional positions. ETF’s two buildings in Madison will be the main work-site. \n\
Some requirements for a Position will be pre-defined; others will not. Each Position \
Request may call for a sub-set of the Position’s requirements, and/or ETF may include new \
requirements that are not currently listed under that Position. Proposers must be able to \
accommodate all of these possibilities and provide one rate for the Position on the Cost \
Proposal/Appendix F. \n\nProposers may provide different rates for different Positions within a \
Job Category to reflect the skills, duties, and experience required for each Position. \n\
Proposers need not provide coverage or pricing for Other Positions as Needed but are \
encouraged to do so. However, proposers must provide pricing for some other Job \
Category besides Other Positions as Needed to be considered for an award. The State of \
Wisconsin as represented by the Department of Employee Trust Funds (ETF) intends to \
use the results of this process to award one or more contracts. More than one award per \
Job Category is likely. \n\nETF administers retirement, insurance and other benefit programs for state and local \
government employees and retirees of the Wisconsin Retirement System. \n\
This section of the Request for Proposal (RFP) details the procedure the proposer must follow to submit a proposal. \
This procurement is authorized under Chapter 16 of the Wisconsin State statutes. \n\nThis RFP is issued by ETF. ETF is the sole point of contact for the State in the selection\
process. Prospective proposers are prohibited from contacting any person other than the individual \
listed here regarding this RFP. Violation of this requirement may result in the proposer\
being disqualified from further consideration.",
      summary:
        "This RFP from the Wisconsin Department of Employee Trust Funds (ETF) invites proposals for staffing services\
        at their Madison locations. Proposers must submit competitive bids with rates for various professional \
        positions, accommodating both predefined and new requirements. ETF may award multiple contracts, and proposers\
        are encouraged to offer pricing for additional job categories. Strict adherence to submission procedures is \
        required, and unauthorized contact with ETF personnel may lead to disqualification.",
    },
    {
      title: "Staffing Services – Job categories and position descriptions",
      reviewed: false,
      contentType: "table",
      header: ["Status", "Items", "Assigned to"],
      rows: [
        {
          Items: "Category: Accountant",
          Status: "pending",
          "Assigned to": "Timothy",
          expandedContent: [
            {
              text: "Assist in the maintenance or analysis of financial records.",
              status: "complete",
            },
            {
              text: "Interpret and analyze financial data.",
              status: "complete",
            },
            {
              text: "Design, test, implement and maintain automated and/or manual financial systems.",
              status: "complete",
            },
            {
              text: "Establish and maintain financial journals, accounts, ledgers and records within an automated financial system.",
              status: "complete",
            },
            {
              text: "Allocate revenues and expenses among funds.",
              status: "complete",
            },
            {
              text: "Reconcile accounts on a periodic basis to other accounting systems and reviewing other staff output.",
              status: "complete",
            },
            {
              text: "Prepare financial statements, reports or other financial documents.",
              status: "complete",
            },
            {
              text: "Advise management on methods to improve accounting or other operational procedures.",
              status: "complete",
            },
            {
              text: "Communicate fiscal issues effectively with division/program staff.",
              status: "complete",
            },
            {
              text: "Develop ad hoc fiscal reports to meet user needs.",
              status: "complete",
            },
            {
              text: "Knowledge in governmental and fund accounting.",
              status: "complete",
            },
            {
              text: "Analysis and reconciliation skills.",
              status: "complete",
            },
            {
              text: "Knowledge of PC-based spreadsheet and database applications and word processing software.",
              status: "complete",
            },
            {
              text: "Knowledge of accounting or ERP systems, preferably PeopleSoft.",
              status: "pending",
            },
            {
              text: "Problem solving techniques.",
              status: "complete",
            },
            {
              text: "Good written and oral communication skills.",
              status: "complete",
            },
          ],
        },
        {
          Items: "Category: Benefits Specialist",
          Status: "Complete",
          "Assigned to": "Timothy",
          expandedContent: [
            {
              text: "Analyze records to determine eligibility for benefits.",
              status: "complete",
            },
            {
              text: "Ability to understand and communicate complex information.",
              status: "complete",
            },
            {
              text: "Calculate estimated and final benefit amounts.",
              status: "complete",
            },
            {
              text: "Provide complex and detailed benefit information.",
              status: "complete",
            },
            {
              text: "Effectively communicating benefit options.",
              status: "complete",
            },
            {
              text: "Effective customer service techniques.",
              status: "complete",
            },
            {
              text: "Participate and provide input regarding benefit policy and administrative rules.",
              status: "complete",
            },
            {
              text: "Obtain relevant information without disclosing confidential information.",
              status: "complete",
            },
            {
              text: "Apply state and federal tax laws and regulations pertaining to available benefits.",
              status: "complete",
            },
            {
              text: "Ability to interact positively with a variety of persons at different levels within and outside the organization.",
              status: "complete",
            },
            {
              text: "Excellent written, oral and interpersonal skills required.",
              status: "complete",
            },
            {
              text: "Good phone presence.",
              status: "complete",
            },
          ],
        },
        {
          Items: "Category: Auditor (Internal)",
          Status: "pending",
          "Assigned to": "Gary",
          expandedContent: [
            {
              text: "Assist in the maintenance or analysis of financial records.",
              status: "complete",
            },
            {
              text: "Interpret and analyze financial data.",
              status: "complete",
            },
            {
              text: "Design, test, implement and maintain automated and/or manual financial systems.",
              status: "complete",
            },
            {
              text: "Establish and maintain financial journals, accounts, ledgers and records within an automated financial system.",
              status: "complete",
            },
            {
              text: "Allocate revenues and expenses among funds.",
              status: "complete",
            },
            {
              text: "Reconcile accounts on a periodic basis to other accounting systems and reviewing other staff output.",
              status: "complete",
            },
            {
              text: "Prepare financial statements, reports or other financial documents.",
              status: "complete",
            },
            {
              text: "Advise management on methods to improve accounting or other operational procedures.",
              status: "complete",
            },
            {
              text: "Communicate fiscal issues effectively with division/program staff.",
              status: "complete",
            },
            {
              text: "Develop ad hoc fiscal reports to meet user needs.",
              status: "complete",
            },
            {
              text: "Knowledge in governmental and fund accounting.",
              status: "complete",
            },
            {
              text: "Analysis and reconciliation skills.",
              status: "complete",
            },
            {
              text: "Knowledge of PC-based spreadsheet and database applications and word processing software.",
              status: "complete",
            },
            {
              text: "Knowledge of accounting or ERP systems, preferably PeopleSoft.",
              status: "pending",
            },
            {
              text: "Problem solving techniques.",
              status: "complete",
            },
            {
              text: "Good written and oral communication skills.",
              status: "complete",
            },
          ],
        },
        {
          Items: "Category: Publications and communications speacialist",
          Status: "pending",
          "Assigned to": "You",
          expandedContent: [
            {
              text: "Assist in the maintenance or analysis of financial records.",
              status: "pending",
            },
            {
              text: "Interpret and analyze financial data.",
              status: "pending",
            },
            {
              text: "Design, test, implement and maintain automated and/or manual financial systems.",
              status: "pending",
            },
            {
              text: "Establish and maintain financial journals, accounts, ledgers and records within an automated financial system.",
              status: "pending",
            },
            {
              text: "Allocate revenues and expenses among funds.",
              status: "pending",
            },
            {
              text: "Reconcile accounts on a periodic basis to other accounting systems and reviewing other staff output.",
              status: "pending",
            },
            {
              text: "Prepare financial statements, reports or other financial documents.",
              status: "pending",
            },
            {
              text: "Advise management on methods to improve accounting or other operational procedures.",
              status: "pending",
            },
            {
              text: "Communicate fiscal issues effectively with division/program staff.",
              status: "pending",
            },
            {
              text: "Develop ad hoc fiscal reports to meet user needs.",
              status: "pending",
            },
            {
              text: "Knowledge in governmental and fund accounting.",
              status: "pending",
            },
            {
              text: "Analysis and reconciliation skills.",
              status: "pending",
            },
            {
              text: "Knowledge of PC-based spreadsheet and database applications and word processing software.",
              status: "pending",
            },
            {
              text: "Knowledge of accounting or ERP systems, preferably PeopleSoft.",
              status: "pending",
            },
            {
              text: "Problem solving techniques.",
              status: "pending",
            },
            {
              text: "Good written and oral communication skills.",
              status: "pending",
            },
          ],
        },
        {
          Items: "Category: Purchasing and contracts specialist",
          Status: "Complete",
          "Assigned to": "You",
          expandedContent: [
            {
              text: "Procurement of commodities, including printing, computer equipment, consulting services, benefit management services, and other professional services.",
              status: "complete",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
            {
              text: "Administering the resulting contracts, addressing any protest actions, acting as liaison between the agency and vendors to resolve disputes.",
              status: "complete",
            },
            {
              text: "Administering the resulting contracts, addressing any protest actions, acting as liaison between the agency and vendors to resolve disputes.",
              status: "complete",
            },
            {
              text: "Negotiate and draft contract language.",
              status: "complete",
            },
          ],
        },
        {
          Items: "Category: Training Officer/Coordinator",
          Status: "Complete",
          "Assigned to": "Timothy",
          expandedContent: [
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
          ],
        },
        {
          Items: "Category: Budget analyst",
          Status: "Complete",
          "Assigned to": "Gary",
          expandedContent: [
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
          ],
        },
        {
          Items: "Category: Benefits program and policy analyst",
          Status: "Complete",
          "Assigned to": "Gary",
          expandedContent: [
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "complete",
            },
          ],
        },
        {
          Items: "Category: Other positions as needed",
          Status: "pending",
          "Assigned to": "Gary",
          expandedContent: [
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "pending",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "pending",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "pending",
            },
            {
              text: "Preparing bids, developing requests for proposals, selecting vendors, negotiating\
        contracts, awarding contracts, maintaining required documentation.",
              status: "pending",
            },
          ],
        },
      ],
    },
    {
      title: "Due Dates",
      reviewed: false,
      contentType: "text",
      content:
        "Listed below are the important dates by which actions related to this RFP must be completed.\
 In the event ETF finds it necessary to change any of the specific dates and times in the calendar of events \
listed below, it will do so by issuing a supplement to this RFP via the ETF Extranet listed in \
Section 1.2.3. No other formal notificatication will be issued. All dates are estimated except the RFP proposal due date and time. \n\n \
February 9, 2016 ----------------------------------------ETF Issues RFP \n \
February 15, 2016 ---------------------------------------Proposer Questions and Letter of Intent Due\n \
February 17, 2016 ---------------------------------------ETF Posts Answers to Questions on ETF Extranet\n \
March 8, 2016 2 P.M. CST --------------------------------Proposals Due\n \
April 5, 2016 -------------------------------------------ETF Notifies Proposers of Intent to Award Contract\n \
April 25, 2016 ------------------------------------------Contract start date",
    },
    {
      title: "Contract Term",
      reviewed: false,
      content:
        "The contract will begin on April 25, 2016 - estimated. The contract period will be for a three \
(3)-year initial term, plus two (2) one (1)-year optional renewals to be agreed upon by \
mutual written consent. Proposers must provide pricing on the Cost Proposal/Appendix F \
for the entire length of the contract, including any optional renewals. There will be no price \
increases for the length of the contract, including contract renewals, except for negotiated \
adjustments to a particular candidate’s or worker’s hourly rate, with the rate bid on the Cost \
Proposal as the ceiling, the Maximum Bill Rate, for any such adjustment. \n\n \
ETF may also agree to move a candidate or worker from an entry level Position to a more \
senior Position, or into the Other Positions as Needed Category. For example, a move from \
Accountant-Entry to Accountant-Advanced, may effectively provide an increase in the \
hourly rate. But the Maximum Bill Rate on the Cost Proposal for the Accountant-Advanced \
is the ceiling for any such adjustments. ETF could also move the candidate or worker into \
Other Positions as Needed and apply the Entry, Intermediate, or Advanced Maximum Bill \
Rate(s). The same logic applies for all Positions and Categories.",
      contentType: "text",
    },
    {
      title: "Mandatory Requirements",
      reviewed: false,
      contentType: "table",
      header: ["Status", "Requirement", "Assigned to"],
      rows: [
        {
          Status: "Complete",
          Requirement:
            "Wisconsin. The firm adhered to the instructions in this RFP on preparing and submitting the proposal.",
          "Assigned to": "Jennifer",
        },
        {
          Status: "Complete",
          Requirement:
            "The firm has not been suspended or debarred from performing government work.",
          "Assigned to": "You",
        },
        {
          Status: "Pending",
          Requirement:
            "The firm has not been the subject of any disciplinary action or inquiry by any regulatory authority during the\
past five (5) calendar years. This includes the business as a whole, but also any individuals associated with it,\
including hired staff, contractors, and individuals the business would refer to ETF to staff ETF’s positions.",
          "Assigned to": "Jennifer",
        },
        {
          Status: "Complete",
          Requirement:
            "The firm has not been in bankruptcy and/or receivership within the last five calendar years.",
          "Assigned to": "Jennifer",
        },
        {
          Status: "Complete",
          Requirement:
            "During the past five years, the firm has not been subject to any litigation alleging breach of contract, fraud, breach of fiduciary duty or other willful or negligent misconduct.",
          "Assigned to": "Jennifer",
        },
        {
          Status: "Pending",
          Requirement:
            "Replacement of personnel who have terminated employment with the contractor shall be with persons of equal ability and qualifications.",
          "Assigned to": "Jennifer",
        },
        {
          Status: "Pending",
          Requirement:
            "purchase order for the replacement staff. Each lead account manager must have the authority to make binding managerial and operational decisions.",
          "Assigned to": "Jennifer",
        },
        {
          Status: "Pending",
          Requirement:
            "present these security credentials to ETF in person. Contractor’s personnel must reasonably cooperate with \
ETF’s other contractors and not commit any act that interferes with the performance of work or provision of \
services by any other ETF contractor.",
          "Assigned to": "Jennifer",
        },
      ],
    },
    {
      title: "Background Information",
      reviewed: false,
      contentType: "text",
      content:
        "Background information is provided to assist the proposer in completing the RFP response \
document. This section is for informational purposes; no response is required from the proposer. \n\n\
Employee Trust Funds Internet Site------------------http://etf.wi.gov\n\
Employee Trust Funds Extranet Site------------------https://etfonline.wi.gov/etf/internet/RFP/\n",
    },
    {
      title: "Special terms and conditions",
      reviewed: false,
      contentType: "table",
      header: ["Status", "Item", "Assigned to"],
      rows: [
        {
          Status: "Pending",
          Item: "Legal Relations",
          "Assigned to": "Jennifer",
          expandedContent: [
            {
              text: "The contractor shall at all times comply with and observe all federal and State laws,\
local laws, ordinances, and regulations which are in effect during the period of this\
contract and which in any manner affect the work or its conduct. This includes but is\
not limited to laws regarding compensation, hours of work, conditions of employment\
and equal opportunities for employment.",
              status: "pending",
            },
            {
              text: "In carrying out any provisions of this RFP or in exercising any power or authority\
granted to the contractor thereby, there shall be no liability upon ETF, it being\
understood that in such matters that ETF acts as an agent of the State.",
              status: "pending",
            },
            {
              text: "The contractor accepts full liability and agrees to hold harmless ETF, its employees,\
agents and contractors for any act or omission of the contractor, or any of its\
employees, in connection with this contract.",
              status: "pending",
            },
            {
              text: "No employee of the contractor may represent himself or herself as an employee of\
ETF or the State.",
              status: "pending",
            },
          ],
        },
        {
          Status: "Pending",
          Item: "Termination of contract",
          "Assigned to": "Jennifer",
          expandedContent: [
            {
              text: "ETF may terminate any contract issued as a result of this RFP at any time at its sole\
discretion by delivering one-hundred eighty (180) calendar days written notice to the contractor.",
              status: "pending",
            },
            {
              text: "Upon termination, ETF's liability shall be limited to the prorated cost of the services\
performed as of the date of termination plus expenses incurred with the prior written\
approval of ETF.",
              status: "pending",
            },
            {
              text: "If the contractor terminates this contract, it shall refund all payments made\
hereunder by ETF to the contractor for work not completed or not accepted by ETF.\
Such termination shall require written notice to that effect to be delivered by the\
contractor to ETF not less than one-hundred eighty (180) days prior to said\
termination.",
              status: "pending",
            },
          ],
        },
        {
          Status: "Complete",
          Item: "Continuance of RFP",
          "Assigned to": "Jennifer",
          expandedContent: [
            {
              text: "Continuation of this RFP beyond the limits of funds available shall be contingent upon \
appropriation of the necessary funds, and the termination of this RFP by lack of \
appropriations shall be without penalty to ETF. Termination under this clause is effective \
immediately, without advance notice and without further payment.",
              status: "complete",
            },
          ],
        },
        {
          Status: "Pending",
          Item: "Payment Terms",
          "Assigned to": "Linda",
          expandedContent: [
            {
              text: "Invoices must refer to the ETF Purchase Order number for the work being invoiced.",
              status: "pending",
            },
            {
              text: "Invoices must be itemized by cost proposal categories of expenses actually incurred.",
              status: "pending",
            },
            {
              text: "Approved payment will be made to the contractor within thirty (30) calendar days of receipt of a proper invoice.",
              status: "complete",
            },
          ],
        },
      ],
    },
    {
      title: "Proposer's checklist",
      reviewed: false,
      contentType: "table",
      header: ["Status", "Items"],
      rows: [
        {
          Items: "Front Cover",
          Status: "Complete",
          expandedContent:
            "[Date] \n\n\
Kristen Schipper \n\
Dept. of Employee Trust Funds \n\
PO Box 7931 \n\
Madison, WI 53707-7931 \n\n\
Subject: ETE0015 – Staffing Services for Professional Positions \n\n\
To Whom It May Concern:\n\n\
This letter certifies that TalentPro is the Prime Vendor submitting the Proposal.\
TalentPro has not attempted to induce any other person or legal entity to submit or not to submit a\
Proposal under this RFP. TalentPro certifies that it has neither directly nor indirectly entered into\
any agreement or participated in any collusion or otherwise taken any action in restraint of free\
competition. This Proposal has been independently arrived at without collusion with any other\
vendor(s), competitor(s) or potential competitor(s); and that this proposal has not been knowingly\
disclosed to any other vendor or competitor prior to the due date of the proposal.\
The Proposal is valid for a minimum of 6 months from the Proposal due date. TalentPro, if awarded\
a contract, will agree to execute and fulfill the contract according to the conditions and terms specified\
in the RFP.\n\n\
This Proposal, in its entirety, is predicated upon the requirements, terms and conditions of this RFP as\
published, all published Questions and Answers, all published amendments and addenda, and any other\
published attachments, supplements, or revisions.\n\n\
TalentPro has the experience as specified in Section 2 Proposal Qualifications and Terms.\
Submission of this Proposal indicates acceptance of all conditions specified in this RFP.\n",
        },
        {
          Items: "DOA 3261",
          Status: "pending",
        },
        {
          Items: "Transmittal Letter",
          Status: "pending",
        },
        {
          Items: "Tab 3 - Response to section 2.2 & 3",
          Status: "pending",
          expandedContent: [
            {
              text: "The firm's name, home office, address of the office providing services under the contract \
and the telephone number and appropriate FAX number.",
              status: "pending",
              expandedContent:
                "Talent Pro Inc.\n\
2256 Demming way, Suite 381\n\
iddleton, WI 53562\n\
608-872-9333(phone)\n\
608-872-9334(Fax)",
            },
            {
              text: "A general description of the proposer, including size, number of employees, number of\
offices and locations, primary business (e.g. consulting, pension planning, insurance, etc.),\
other business or services, type of organization (franchise, corporation, partnership, etc.),\
and other descriptive material. Describe what you believe are your firm’s strengths\
regarding client service; what distinguishes your firm from your competitors? Highlight any\
acquisitions, and/or mergers or other material developments (changes in ownership,\
personnel, business, etc.) pending now or that occurred in the past five years at your firm.\
Disclose any potential mergers or acquisitions that have been recently discussed by senior\
officials, and could potentially take place within the next three years after the contract\
start date.",
              status: "complete",
              expandedContent:
                "Founded in 1996, TalentPro is a leading Madison-area Staff Augmentation firm. We offer\
exceptional consulting, contracting, and recruitment services. We’re known for our ability\
to recruit top professionals for a full range of IT disciplines as well as several areas outside of IT. \n\n\
Since 1996, we have grown to be one of the areas dominant Staff Augmentation and\
Consulting organizations, recognized for the outstanding quality of our contractors and our\
strong customer service with almost 200 contractors on staff. Our office is located in\
Middleton, WI and we have 30 employees (3 owners, 4 Account Managers, 1 Business\
Development Manager, 9 support staff – HR, Accounting, Administrative – and a Recruiting team of 11).\n\n\
As for TalentPro's structure and management team, we are a locally owned and operated\
corporation. The three TalentPro owners (Paul Heberer, Eric Larson and Brian Worden) are\
senior industry professionals with more than 70 years of combined, real-world experience\
in software development, project management and IT systems and infrastructure.\n\n\
We also offer an extensive benefits package for our consultants including health, dental and\
vision insurances as well as a match on our 401(k) program and an annual book allowance\
for training, professional associations, etc. We also offer salaried and hourly payroll\
classifications to our employees.\n\n\
TalentPro does not have any acquisitions, and/or mergers or other material developments\
pending now or that have occurred in the past five years nor are there any potential\
mergers or acquisitions that have been recently discussed by senior officials, or could\
potentially take place within the next three years after the contract start date.",
              sources:
                "Source documentation:\n\n\
1. https://talentproinc.com/#candidates\n\
2. https://talentproinc.com/#about\n\n\
Past RFPs:\n\
1. SS-101 STAFFING SERVICE RFP \n\
2.STAFFING 1222000011\n\n\
Knowledge Center:\n\
1. /files/company-info/contact\n\
2. /files/company-info/about",
            },
            {
              text: "It is the expectation that there would be a Lead Account Manager in charge of all\
programs assigned at all times, in addition to other personnel. \
Identify the Lead Account Manager available for these programs.",
              status: "pending",
              suggestedAnswers: [
                {
                  answer:
                    "Our Lead Account Manager available for these programs is Jennifer Parker. Jennifer has\
over 12 years’ staffing experience in the Madison area. This time has been split between \
account management, recruiting and resource management. She has focused on both \
consulting/contract recruiting as well as direct hire/search placements. Her staffing career \
has also been split between IT staffing and finance and accounting.\n\nAbility to solve problems, and understand and effectively resolve any \
financial matters.\n\
Jennifer Parker\
i) Periodically, there are hours that are entered incorrectly into \
Fieldglass by consultants or invoices that are not processed properly \
with the State of Wisconsin/TAPFIN. When this happens, Jennifer and \
the Accounting Team work closely with TAPFIN to resolve the matter.\
This may include issuing a debit/credit memo or, when necessary \
investigating more to ensure resolution.\
ii) Jennifer thoroughly reviews each Work Order that comes through \
 Fieldglass for both new consultants as well as renewals to ensure bill \
rates are accurate.",
                  sources:
                    "Source documentation:\n\n\
1. https://talentproinc.com/#candidates\n\n\
Knowledge Center:\n\
1. /files/candidates",
                },
                {
                  answer:
                    "Greg Shemanek has over 13 years of staffing experience in the Los Angeles, Phoenix and\
Madison areas. He has been in Madison for the past eight years. During his years in the\
staffing field, his time has been split between Finance and Accounting and IT staffing\
services. In addition, Greg has served in a leadership role in both lines of business. In 2005,\
Greg was promoted to open a new Finance and Accounting branch of Ajilon Professional\
Staffing. He was responsible for building a team of Account Managers and Recruiters. In\
2006, the branch was named “New Branch of the Year”.\n\n\
Ability to solve problems, and understand and effectively resolve any\
financial matters.\nGreg Shemanek\
i) Negotiating financial pricing of staffing business is a core job of an AM\
and Greg has over 13 years of understanding market forces in the\
staffing industry. Part of a long term successful business relationship\
is the ability of an AM to generate pricing where the value is\
real. Greg’s history of outstanding client relationships is proof of his\
ability to resolve financial matters.\n\
ii) During Greg’s time working with Waste Management, there would be\
frequent need for Accounts Receivable teams to start in contract roles. WM would start teams of 10 or more on a monthly\
basis. Occasionally, some members of the team were not up to the\
desired quality. Greg worked in partnership with the leadership at\
WM to develop SLA’s for each party that greatly improved the quality\
and reliability of the workers.",
                  sources:
                    "Source documentation:\n\n\
1. https://talentproinc.com/#candidates\n\n\
Knowledge Center:\n\
1. /files/candidates",
                },
              ],
            },
            {
              text: "Information regarding the professional and experience qualifications of all\
administrative staff who will perform work for this specific contract besides the Lead\
Account Manager and backup. This includes anyone who will be involved with\
processing the contractor’s payroll, billing ETF, or handling payments from ETF.",
              status: "complete",
              expandedContent:
                "Jaquie Rice has been working in the accounting field since 1994, graduated from Madison\
College with an Associate’s Degree in Accounting in 2000. She obtained a Bachelor of Arts\
Degree in 2004 for Accounting from Lakeland College. She worked in public accounting\
from 2000 to 2008. She currently has had her own business since 2008 doing small business\
accounting and bookkeeping, preparing tax returns for small businesses, not for profit\
organizations, partnerships and individuals. She has been with TalentPro as the Senior\
Accountant since October 2011 working in all aspects of accounting including: payroll,\
invoicing, accounts payable and various spreadsheet projects for the owners and other managers.\n\n\
For the State of Wisconsin (including ETF), she receives timesheets from TalentPro\
consultants via email and creates an invoice based on those hours in our accounting\
software. When payments are made from the State/TAPFIN, she then applies those\
payments and reconciles to the timesheets entered. Her knowledge also expands to\
understanding the new process for Consultants entering their time into STAR PeopleSoft Time and Labor.",
            },
            {
              text: "ETF may require that the contractor meet certain performance measures to continue as an\
awarded contractor. Performance Standards and Guarantees (PS&G) may be negotiated during contract\
negotiations and become part of the contract. Penalties may also be negotiated and\
established. Penalties may be assessed for any PS&G not met.\
List any performance measurements your organization currently uses and specify how\
these measurements are derived and would be applied to the contract with ETF.",
              status: "complete",
              expandedContent:
                "We check often with hiring managers and our consultants to make sure the assignment is\
progressing optimally. We talk with our consultants about expectations and their\
commitment to completion. In the event that a concern is raised, we address it immediately\
through open communication. Follow up meetings with their manager ensures that the\
consultant is meeting expectations on the client side as well. Incidentally, we never pull\
consultants from a client mid-assignment to place them on another client, and we demand\
the same for those consultants who subcontract through us from another firm. We also\
perform annual reviews for all of our consultants. Part of this process involves requesting\
feedback from the hiring manager as well.",
            },
          ],
        },
      ],
    },
    { title: "Templates", reviewed: false, content: "Content for Templates" },
  ];
  const [steps, setSteps] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});

  const handleToggle = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    // List of words to check for CRM
    const crmKeywords = ["crm"];

    // Check if fileName contains any of the CRM keywords
    const isCRM = crmKeywords.some((keyword) =>
      fileName.toLowerCase().includes(keyword.toLowerCase())
    );

    // Set steps based on the condition
    if (isCRM) {
      setSteps(resp_CRM);
      setIndex(6.1);
    } else {
      setSteps(resp_Staffing);
      setIndex(5.9);
    }
  }, []);

  const handleSectionClick = (index) => {
    setCurrentSection(index);
    setReviewed(steps[index].reviewed);
  };

  const handleReviewChange = (event) => {
    const isChecked = event.target.checked;
    const updatedSteps = steps.map((step, index) =>
      index === currentSection ? { ...step, reviewed: isChecked } : step
    );
    setSteps(updatedSteps);
    setReviewed(isChecked);
  };

  return (
    <div>
      <ChatWidget />
      <Grid fullWidth>
        {steps && (
          <Column sm={4} md={2} lg={4}>
            <StatusPanel
              steps={steps}
              onSectionClick={handleSectionClick}
              currentSection={currentSection}
              index={index}
            />
          </Column>
        )}
        {steps && (
          <Column sm={4} md={6} lg={12} className="right-panel">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span className="page-title">{steps[currentSection].title}</span>
              <div className="tiles-container">
                <div className="tile">
                  <span>Items reviewed:</span>
                  <span>{steps.filter((step) => step.reviewed).length}</span>
                </div>
                <div className="tile">
                  <span>Items under review: </span>
                  <span>
                    {steps.length -
                      steps.filter((step) => step.reviewed).length}
                  </span>
                </div>
              </div>
            </div>

            <div className="main-section">
              {steps[currentSection].contentType === "text" && (
                <CustomTextArea
                  value={steps[currentSection].content}
                  currentSection={currentSection}
                  steps={steps}
                  summary={steps[currentSection].summary}
                />
              )}
              {steps[currentSection].contentType === "table" && (
                <div className="table-container">
                  <ScopeTable
                    data={steps[currentSection].rows}
                    headers={steps[currentSection].header}
                  />
                </div>
              )}
              {steps[currentSection].contentType === "section" && (
                <div>
                  <Accordion className="section-accordion">
                    {steps[currentSection].sections.map(
                      (section, sectionIndex) => (
                        <AccordionItem
                          key={sectionIndex}
                          open={expandedRows[sectionIndex]}
                          onHeadingClick={() => handleToggle(sectionIndex)}
                          title={
                            <div className="accordion-title">
                              <span> {section.title}</span>
                            </div>
                          }
                        >
                          <div className="accordion-content">
                            <ul className="no-bullets">
                              {section.expandedContent &&
                                section.expandedContent.map(
                                  (item, itemIndex) => (
                                    <li key={itemIndex} className="list-item">
                                      <RadioButton
                                        id={`status-radio-${sectionIndex}-${itemIndex}`}
                                        labelText=""
                                        checked={item.status === "complete"}
                                        onChange={() =>
                                          handleStatusChange(
                                            sectionIndex,
                                            itemIndex
                                          )
                                        }
                                      />
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: item.text.replace(
                                            /\n/g,
                                            "<br/>"
                                          ),
                                        }}
                                      />
                                    </li>
                                  )
                                )}
                            </ul>
                            {section.content && (
                              <span
                                style={{
                                  fontSize: "var(--text-medium)",
                                  fontWeight: "400",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: section.content.replace(
                                    /\n/g,
                                    "<br/><br/>"
                                  ),
                                }}
                              />
                            )}
                          </div>
                        </AccordionItem>
                      )
                    )}
                  </Accordion>
                </div>
              )}
            </div>
            <div className="mark-reviewed-container">
              <div className="mark-reviewed">
                <Checkbox
                  id="mark-reviewed"
                  labelText="Mark reviewed"
                  checked={reviewed}
                  onChange={handleReviewChange}
                />
              </div>
            </div>
          </Column>
        )}
      </Grid>
    </div>
  );
};

export default Page;

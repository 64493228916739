import React, { useState } from "react";
import {
  Dropdown,
  FileUploaderButton,
  FileUploaderItem,
  IconButton,
} from "@carbon/react";
import { Add } from "@carbon/icons-react";

const QuaterlyReport = ({
  id,
  handleFileUpload,
  handleFileRemove,
  showAddButton,
  onAddClick,
}) => {
  const [fileAndStatus, setFileAndStatus] = useState(null);

  const dropdownOptions = [
    { label: "Q1", value: "Q1" },
    { label: "Q2", value: "Q2" },
    { label: "Q3", value: "Q3" },
  ];

  const onFileUpload = (evt) => {
    const uploadedFile = evt.target.files[0]; // Assuming single file upload
    if (uploadedFile) {
      setFileAndStatus({
        file: uploadedFile,
        status: "edit", // Set the initial status of the file
      });
      handleFileUpload(uploadedFile); // Pass the file up to the parent component
    }
  };

  const onFileRemove = () => {
    if (fileAndStatus && fileAndStatus.file) {
      setFileAndStatus(null);
      handleFileRemove(fileAndStatus.file); // Pass the file to be removed up to the parent component
    }
  };

  return (
    <div
      key={id}
      style={{
        display: "flex",
        alignItems: "center",
        gap: "20px",
        marginTop: "1%",
      }}
    >
      <Dropdown
        id={`quarter-year-dropdown-${id}`}
        titleText=""
        label="Select quarter"
        items={dropdownOptions}
        itemToString={(item) => (item ? item.label : "")}
        required
        style={{ width: "500px" }}
      />
      <FileUploaderButton
        accept={[".pdf"]}
        labelText="Add file"
        buttonKind="primary"
        size="lg"
        disableLabelChanges
        onChange={onFileUpload}
      />
      {fileAndStatus && (
        <FileUploaderItem
          name={fileAndStatus.file.name}
          status={fileAndStatus.status}
          onDelete={onFileRemove}
          style={{
            border: "1px solid #ccc",
            borderRadius: "var(--border-radius)",
            padding: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
          }}
        />
      )}
      {showAddButton && (
        <IconButton
          label="Add another report"
          kind="secondary"
          align="bottom"
          size="sm"
          onClick={onAddClick}
          onToggleClick={() => {}}
          style={{ position: "relative" }}
        >
          <Add />
        </IconButton>
      )}
    </div>
  );
};

export default QuaterlyReport;

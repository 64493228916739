import React, { useState, useEffect } from "react";
import { Grid, Column, InlineLoading, Button } from "@carbon/react";
import { api, api_external } from "../store";
import "./Dashboard.scss";
import env from "../config";

// import "react-chat-widget/lib/styles.css";
// import ChatWidget from "../Components/ChatWidget";
import FileUploaderModal from "../Components/FileUploaderModal";
import VerticalStepperTemp from "../Components/VerticalStepperTemp";

const RFPGenerations = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [rfpView, setRFPView] = useState(false);
  const [rfpLoading, setRFPLoading] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      let response = await api.get("user/getUserProfile");
      setUser(response.data);
    };
    getUser();
  }, []);

  const data = [
    {
      question:
        "What CRM capabilities do you offer in maintaining the set-up of, and relations with external managers?",
      answer:
        "Our CRM system provides robust features for managing contacts and relationships with external managers. \nKey capabilities include:\n- Centralized contact database to store and manage detailed information on each external manager, including name, title, company, email, phone number, address, and custom fields[1]\n-Ability to track all interactions and communication history with each external manager, including emails, calls, meetings, and notes[1]\n- Tools to segment and categorize external manager contacts based on customizable criteria such as region, specialty, assets under management, etc.\n-Automated workflows to manage key activities like contact updates, meeting scheduling, and email outreach[1]\n-Reporting and analytics to measure engagement and identify top external manager relationships",
      sources: [
        {
          extract:
            "Our CRM system excels in managing relationships with external managers through a centralized database that stores comprehensive details about each manager. Key features include tracking all forms of communication, segmenting contacts by various criteria, automating essential CRM tasks, and providing detailed analytics to monitor and enhance engagement",
          source_path: "Infoflo.txt",
          uuid: 1,
        },
      ],
      mismatchedRequirements: "",
      matchingRequirements: "true",
    },
    {
      question:
        "What other systems does the CRM sync contacts, emails, and calendars with?",
      answer:
        "Our CRM integrates seamlessly with leading business systems to sync contacts, emails, and calendars, providing a unified view of client interactions.[1] \nKey integrations include:\n-Marketing automation platforms like Marketo, Pardot and Hubspot to align sales and marketing efforts[2]\n-Social media management tools for social listening and engagement insights\n-Data enrichment services to keep contact information up-to-date and complete[1]\n-Our open API also allows custom integrations with your existing systems",
      sources: [
        {
          extract:
            "Our CRM system offers comprehensive integration capabilities with key business systems to streamline contact, email, and calendar synchronization, ensuring a cohesive view of client interactions. It supports integration with major platforms such as Microsoft 365 and Google Workspace for basic sync needs; advanced marketing platforms like Marketo, Pardot, and Hubspot for marketing alignment; and social media tools for engagement analytics. Additionally, it features data enrichment services for accurate contact management and an open API for customized integrations, enhancing flexibility and efficiency",
          source_path: "Infoflo.txt",
          uuid: 1,
        },
        {
          extract:
            "advanced marketing platforms like Marketo, Pardot, and Hubspot for marketing alignment; and social media tools for engagement analytics",
          source_path: "CRM-integrations.txt",
          uuid: 2,
        },
      ],
      mismatchedRequirements: [
        "sync contacts, emails, and calendar with Microsoft Outlook",
      ],
      matchingRequirements: "false",
    },
    {
      question: "Describe your contact system search capabilities",
      answer:
        "We offer advanced search and query tools to help quickly find the right contacts:\n-Search across all contact fields and communication history\n-Build complex queries combining multiple filters and conditions\n-Save frequently used searches for easy future access\n-Fuzzy matching to find contacts despite spelling variations or incomplete information\n-Phonetic name search to find global contacts\n-Search for contacts by role, seniority level or other attributes",
      sources: [
        {
          extract:
            "Our CRM system offers comprehensive integration capabilities with key business systems to streamline contact, email, and calendar synchronization, ensuring a cohesive view of client interactions. It supports integration with major platforms such as Microsoft 365 and Google Workspace for basic sync needs; advanced marketing platforms like Marketo, Pardot, and Hubspot for marketing alignment; and social media tools for engagement analytics. Additionally, it features data enrichment services for accurate contact management and an open API for customized integrations, enhancing flexibility and efficiency",
          source_path: "Infoflo.txt",
          uuid: 1,
        },
      ],
      mismatchedRequirements: "",
      matchingRequirements: "true",
    },
    {
      question:
        "Describe how data can be entered into your system (bulk uploads/downloads and one-offupload/download)",
      answer:
        "We offer advanced search and query tools to help quickly find the right contacts:\n-Search across all contact fields and communication history\n-Build complex queries combining multiple filters and conditions\n-Save frequently used searches for easy future access\n-Fuzzy matching to find contacts despite spelling variations or incomplete information\n-Phonetic name search to find global contacts\n-Search for contacts by role, seniority level or other attributes",
      sources: [
        {
          extract:
            "Our CRM system offers comprehensive integration capabilities with key business systems to streamline contact, email, and calendar synchronization, ensuring a cohesive view of client interactions. It supports integration with major platforms such as Microsoft 365 and Google Workspace for basic sync needs; advanced marketing platforms like Marketo, Pardot, and Hubspot for marketing alignment; and social media tools for engagement analytics. Additionally, it features data enrichment services for accurate contact management and an open API for customized integrations, enhancing flexibility and efficiency",
          source_path: "Infoflo.txt",
          uuid: 1,
        },
      ],
      mismatchedRequirements: "",
      matchingRequirements: "true",
    },
    {
      question:
        "What third-party systems does the research management portion of the software sync with?",
      answer:
        "We offer advanced search and query tools to help quickly find the right contacts:\n-Search across all contact fields and communication history\n-Build complex queries combining multiple filters and conditions\n-Save frequently used searches for easy future access\n-Fuzzy matching to find contacts despite spelling variations or incomplete information\n-Phonetic name search to find global contacts\n-Search for contacts by role, seniority level or other attributes",
      sources: [
        {
          extract:
            "Our CRM system offers comprehensive integration capabilities with key business systems to streamline contact, email, and calendar synchronization, ensuring a cohesive view of client interactions. It supports integration with major platforms such as Microsoft 365 and Google Workspace for basic sync needs; advanced marketing platforms like Marketo, Pardot, and Hubspot for marketing alignment; and social media tools for engagement analytics. Additionally, it features data enrichment services for accurate contact management and an open API for customized integrations, enhancing flexibility and efficiency",
          source_path: "Infoflo.txt",
          uuid: 1,
        },
      ],
      mismatchedRequirements: "",
      matchingRequirements: "true",
    },
    {
      question:
        "How many custom columns can a firm add? What are the limitations of those columns?",
      answer:
        "We offer advanced search and query tools to help quickly find the right contacts:\n-Search across all contact fields and communication history\n-Build complex queries combining multiple filters and conditions\n-Save frequently used searches for easy future access\n-Fuzzy matching to find contacts despite spelling variations or incomplete information\n-Phonetic name search to find global contacts\n-Search for contacts by role, seniority level or other attributes",
      sources: [
        {
          extract:
            "Our CRM system offers comprehensive integration capabilities with key business systems to streamline contact, email, and calendar synchronization, ensuring a cohesive view of client interactions. It supports integration with major platforms such as Microsoft 365 and Google Workspace for basic sync needs; advanced marketing platforms like Marketo, Pardot, and Hubspot for marketing alignment; and social media tools for engagement analytics. Additionally, it features data enrichment services for accurate contact management and an open API for customized integrations, enhancing flexibility and efficiency",
          source_path: "Infoflo.txt",
          uuid: 1,
        },
      ],
      mismatchedRequirements: "",
      matchingRequirements: "false",
    },
    {
      question:
        "Does the system allow for tagging investments with specific, and customized key words?",
      answer:
        "We offer advanced search and query tools to help quickly find the right contacts:\n-Search across all contact fields and communication history\n-Build complex queries combining multiple filters and conditions\n-Save frequently used searches for easy future access\n-Fuzzy matching to find contacts despite spelling variations or incomplete information\n-Phonetic name search to find global contacts\n-Search for contacts by role, seniority level or other attributes",
      sources: [
        {
          extract:
            "Our CRM system offers comprehensive integration capabilities with key business systems to streamline contact, email, and calendar synchronization, ensuring a cohesive view of client interactions. It supports integration with major platforms such as Microsoft 365 and Google Workspace for basic sync needs; advanced marketing platforms like Marketo, Pardot, and Hubspot for marketing alignment; and social media tools for engagement analytics. Additionally, it features data enrichment services for accurate contact management and an open API for customized integrations, enhancing flexibility and efficiency",
          source_path: "Infoflo.txt",
          uuid: 1,
        },
      ],
      mismatchedRequirements: "",
      matchingRequirements: "true",
    },
    {
      question:
        "Can the system integrate with DocuSign to store documents that are electronically signed?",
      answer:
        "We offer advanced search and query tools to help quickly find the right contacts:\n-Search across all contact fields and communication history\n-Build complex queries combining multiple filters and conditions\n-Save frequently used searches for easy future access\n-Fuzzy matching to find contacts despite spelling variations or incomplete information\n-Phonetic name search to find global contacts\n-Search for contacts by role, seniority level or other attributes",
      sources: [
        {
          extract:
            "Our CRM system offers comprehensive integration capabilities with key business systems to streamline contact, email, and calendar synchronization, ensuring a cohesive view of client interactions. It supports integration with major platforms such as Microsoft 365 and Google Workspace for basic sync needs; advanced marketing platforms like Marketo, Pardot, and Hubspot for marketing alignment; and social media tools for engagement analytics. Additionally, it features data enrichment services for accurate contact management and an open API for customized integrations, enhancing flexibility and efficiency",
          source_path: "Infoflo.txt",
          uuid: 1,
        },
      ],
      mismatchedRequirements: "",
      matchingRequirements: "true",
    },
    {
      question:
        "Does the system integrate with Outlook to upload emails and attachments?",
      answer:
        "We offer advanced search and query tools to help quickly find the right contacts:\n-Search across all contact fields and communication history\n-Build complex queries combining multiple filters and conditions\n-Save frequently used searches for easy future access\n-Fuzzy matching to find contacts despite spelling variations or incomplete information\n-Phonetic name search to find global contacts\n-Search for contacts by role, seniority level or other attributes",
      sources: [
        {
          extract:
            "Our CRM system offers comprehensive integration capabilities with key business systems to streamline contact, email, and calendar synchronization, ensuring a cohesive view of client interactions. It supports integration with major platforms such as Microsoft 365 and Google Workspace for basic sync needs; advanced marketing platforms like Marketo, Pardot, and Hubspot for marketing alignment; and social media tools for engagement analytics. Additionally, it features data enrichment services for accurate contact management and an open API for customized integrations, enhancing flexibility and efficiency",
          source_path: "Infoflo.txt",
          uuid: 1,
        },
      ],
      mismatchedRequirements: "",
      matchingRequirements: "true",
    },
    {
      question: "Do you accept manager pitchbooks in PDF or PowerPoint format?",
      answer:
        "We offer advanced search and query tools to help quickly find the right contacts:\n-Search across all contact fields and communication history\n-Build complex queries combining multiple filters and conditions\n-Save frequently used searches for easy future access\n-Fuzzy matching to find contacts despite spelling variations or incomplete information\n-Phonetic name search to find global contacts\n-Search for contacts by role, seniority level or other attributes",
      sources: [
        {
          extract:
            "Our CRM system offers comprehensive integration capabilities with key business systems to streamline contact, email, and calendar synchronization, ensuring a cohesive view of client interactions. It supports integration with major platforms such as Microsoft 365 and Google Workspace for basic sync needs; advanced marketing platforms like Marketo, Pardot, and Hubspot for marketing alignment; and social media tools for engagement analytics. Additionally, it features data enrichment services for accurate contact management and an open API for customized integrations, enhancing flexibility and efficiency",
          source_path: "Infoflo.txt",
          uuid: 1,
        },
      ],
      mismatchedRequirements: "",
      matchingRequirements: "true",
    },
    {
      question:
        "Can a portal be used for centralizing meeting minutes/research notes? If yes, please elaborate.",
      answer:
        "We offer advanced search and query tools to help quickly find the right contacts:\n-Search across all contact fields and communication history\n-Build complex queries combining multiple filters and conditions\n-Save frequently used searches for easy future access\n-Fuzzy matching to find contacts despite spelling variations or incomplete information\n-Phonetic name search to find global contacts\n-Search for contacts by role, seniority level or other attributes",
      sources: [
        {
          extract:
            "Our CRM system offers comprehensive integration capabilities with key business systems to streamline contact, email, and calendar synchronization, ensuring a cohesive view of client interactions. It supports integration with major platforms such as Microsoft 365 and Google Workspace for basic sync needs; advanced marketing platforms like Marketo, Pardot, and Hubspot for marketing alignment; and social media tools for engagement analytics. Additionally, it features data enrichment services for accurate contact management and an open API for customized integrations, enhancing flexibility and efficiency",
          source_path: "Infoflo.txt",
          uuid: 1,
        },
      ],
      mismatchedRequirements: "",
      matchingRequirements: "true",
    },
  ];

  const refreshFiles = () => {
    // Start by setting loading to true
    setRFPLoading(true);

    // After 10 seconds, set loading to false and view to true
    setTimeout(() => {
      setRFPLoading(false);
      setRFPView(true);
    }, 10000); // 10000 milliseconds is 10 seconds
  };

  return (
    <div>

      <Grid style={{ marginTop: "4rem" }} className="grid-dashboard">
        <Column lg={16} className="rounded-left column-dashboard">
          <div style={{ margin: "2% 5% 0 5%" }}>
            <div style={{ width: "100%", marginTop: "2%" }}>
              {!rfpView && !rfpLoading && (
                <div
                  style={{
                    fontSize: "var(--text-medium)",
                    marginBottom: "2%",
                    marginTop: "15%",
                    marginLeft: "30%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "var(--text-medium)",
                      fontWeight: "500",
                      marginBottom: "2%",
                    }}
                  >
                    {" "}
                    Start crafting your responses by uploading an RFP document
                  </div>
                  <div
                    style={{
                      width: "50%",

                      marginLeft: "15%",
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      Upload an RFP
                    </Button>
                  </div>
                </div>
              )}

              <FileUploaderModal
                modalHeading={"Upload an RFP file"}
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                allowedFileTypes={[".txt, .docx, .pdf"]}
                projectId={user ? user._id : ""}
                refreshFiles={refreshFiles}
                allowAudio={false}
              />
            </div>
            {rfpLoading && (
              <InlineLoading
                style={{ marginLeft: "0.5rem" }}
                description="Analysing RFP and fetching questions from the proposal"
                status="active"
              />
            )}
            {rfpView && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "2%",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ fontSize: "var(--text-large)", fontWeight: "600" }}
                  >
                    Request for proposal
                  </div>
                  <Button
                    type="primary"
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  >
                    Export
                  </Button>
                </div>
                <div style={{ height: "60vh", overflowY: "auto" }}>
                  {data.map((item, index) => (
                    <VerticalStepperTemp
                      key={index}
                      title={item.question}
                      displayText={item.answer}
                      sources={item.sources}
                      rowData={[]}
                      onGenerate={() => console.log("Regenerate clicked!")}
                      matchingRequirements={item.matchingRequirements}
                      misMatchedRequirements={item.mismatchedRequirements}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </Column>
      </Grid>
    </div>
  );
};

export default RFPGenerations;

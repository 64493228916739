import React, { useEffect, useState } from "react";
import { Tile } from "@carbon/react";
import { api } from "../store";
import StyleTemplate from "./StyleTemplate";

import DOMPurify from "dompurify";

const HtmlContentDisplay = ({ htmlContent }) => {
  // Function to check if the content contains HTML tags

  const removeLinks = (content) => {
    return content.replace(
      /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1.*?>(.*?)<\/a>/g,
      "$3"
    );
  };

  const containsHtml = /<\/?[a-z][\s\S]*>/i.test(htmlContent);

  let processedContent;
  if (!containsHtml) {
    // Convert newlines to <br> tags or wrap the lines in <p> tags
    processedContent = htmlContent
      .split("\n")
      .filter((line) => line) // Filter out empty lines if you don't want extra space
      .map((line, index) => `<p key={index}>${line}</p>`)
      .join("");
  } else {
    processedContent = htmlContent;
  }

  processedContent = removeLinks(processedContent);

  // Modify the HTML content to adjust the font size of h1 and h2 tags
  processedContent = processedContent.replace(
    /<h1/g,
    '<h1 style="font-size: 1.25rem;"'
  );
  processedContent = processedContent.replace(
    /<h2/g,
    '<h2 style="font-size: 1.15rem;"'
  );
  processedContent = processedContent.replace(
    /<p/g,
    '<p style="font-size: 0.875rem;"'
  );
  processedContent = processedContent.replace(
    /<ul/g,
    '<ul style="font-size: 0.875rem; list-style-type: disc; margin-left: 1rem;"'
  );
  processedContent = processedContent.replace(
    /<ol/g,
    '<ol style="font-size: 0.875rem; list-style-type: decimal; margin-left: 2rem;"'
  );

  processedContent = processedContent.replace(/<\/h1>/g, "</h1><br />");
  processedContent = processedContent.replace(/<\/h1>/g, "</h2><br />");
  processedContent = processedContent.replace(/<\/p>/g, "</p><br />");
  processedContent = processedContent.replace(/<\/ul>/g, "</ul><br />");
  processedContent = processedContent.replace(/<\/ol>/g, "</ol><br />");

  // Sanitize the potentially new HTML content
  const cleanHtml = DOMPurify.sanitize(processedContent);

  const containerStyle = {
    width: "100%",
    overflow: "hidden",
    color: "black",
    backgroundColor: "white",
    borderRadius: "var(--border-radius) var(--border-radius) 0 0",
    padding: "0.5rem",
    height: "200vh",
    zoom: "0.6",
    margin: "2rem 2rem",
  };

  return (
    <div
      style={containerStyle}
      dangerouslySetInnerHTML={{ __html: cleanHtml }}
    />
  );
};

const StyleCard = ({
  styleName,
  styleId,
  styleDescription,
  styleFiles,
  isPreset,
  refreshStyles,
  displayFile,
}) => {
  const [contents, setFileContents] = useState(null);
  const [isStyleModal, setStyleModal] = useState(false);

  useEffect(() => {
    const fetchFileContent = async () => {
      if (styleFiles.length > 0) {
        try {
          console.log(displayFile);
          const response = await api.post("file/download", null, {
            params: {
              id: displayFile ? displayFile : styleFiles[0],
              ownerId: styleId,
            },
          });
          setFileContents(response.data.contents);
        } catch (error) {
          console.error("Failed to fetch file content:", error);
        }
      }
    };

    fetchFileContent();
  }, []);

  return (
    <div>
      <Tile
        light={false}
        onClick={() => setStyleModal(true)}
        style={{
          height: "35rem",
          width: "25rem",
          cursor: "pointer",
          borderRadius: "var(--border-radius)",
          padding: "0.5rem",
          marginBottom: "1rem",
        }}
      >
        <div
          style={{
            height: "30rem",
            paddingBottom: "1rem",
            marginBottom: "1rem",
            borderRadius: "var(--border-radius) var(--border-radius) 0 0",
            backgroundColor: "white",
            display: "flex",
            overflow: "hidden",
          }}
        >
          {contents && <HtmlContentDisplay htmlContent={contents} />}
        </div>

        <div
          style={{
            height: "1px",
            backgroundColor: "#808080",
            marginBottom: "0.5rem",
          }}
        ></div>
        <div style={{ height: "5rem" }}>
          <span
            style={{
              display: "block",
              marginTop: "0.5rem",
              fontSize: "var(--text-medium)",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer",
              marginLeft: "0.5rem",
            }}
            title={styleName}
          >
            {styleName}
          </span>
        </div>
      </Tile>
      <StyleTemplate
        isOpen={isStyleModal}
        onClose={() => setStyleModal(false)}
        styleData={{
          id: styleId,
          name: styleName,
          description: styleDescription,
          preset: isPreset,
          userOwned: !isPreset,
          files: styleFiles,
        }}
        refreshStyles={refreshStyles}
      />
    </div>
  );
};

export default StyleCard;

import React, { useState } from "react";
import { Button, TextInput, InlineLoading } from "@carbon/react";
import { TrashCan, Checkmark, Link } from "@carbon/icons-react";
import { api_external } from "../store";
import env from "../config";

const AddWebSource = (props) => {
  const [inputList, setInputList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setShowError] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddClick = () => {
    setShowInput(true);
  };

  const handleDelete = (index) => {
    const newList = [...inputList];
    newList.splice(index, 1);
    setInputList(newList);
  };

  const handleAccept = async (input) => {
    setShowError(false);
    setIsLoading(true); // Start the inline loader
    try {
      const requestData = {
        link: inputValue.trim(),
      };
      const response = await api_external.post(
        env.searchAPI + "/validate-link",
        requestData
      );

      if (response.data && response.data.link) {
        props.addValidatedWebSource(response.data);
        setInputValue(""); // Clear the input field
        setShowInput(false);
      } else {
        setShowError(true);
      }
    } catch (error) {
      console.error("Error validating source:", error);
      setShowError(true);
    }
    setIsLoading(false); // Stop the inline loader
  };

  const handleBlur = () => {
    if (inputValue.trim() === "") {
      setShowInput(false);
    }
  };

  return (
    <div>
      {inputList.map((item, index) => (
        <div
          key={index}
          style={{
            marginBottom: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextInput
            id={`input-${index}`}
            labelText=""
            value={item}
            readOnly
            style={{ flexGrow: 1 }}
          />
          <Button
            kind="ghost"
            renderIcon={TrashCan}
            iconDescription="Delete"
            onClick={() => handleDelete(index)}
            style={{ marginLeft: "0.5rem" }}
          />
        </div>
      ))}
      {showInput && (
        <div style={{ display: "flex", marginTop: "2%" }}>
          <TextInput
            id="input-new"
            labelText=""
            placeholder="Add new web source"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            style={{ flexGrow: 1 }}
          />
          <div style={{ display: "flex", gap: "0px", width: "15%" }}>
            <Button
              kind="ghost"
              renderIcon={() => (
                <TrashCan
                  style={{ fill: "var(--xpub-brand-color)", padding: "0" }}
                />
              )}
              iconDescription="Cancel"
              onClick={() => setShowInput(false)}
              style={{ marginLeft: "0.2rem" }}
            />
            {isLoading ? (
              <InlineLoading
                style={{ marginLeft: "0.5rem" }}
                description="Loading..."
                status="active"
              />
            ) : (
              <Button
                kind="ghost"
                renderIcon={() => (
                  <Checkmark style={{ fill: "var(--xpub-brand-color)" }} />
                )}
                iconDescription="Submit"
                onClick={handleAccept}
                style={{ marginLeft: "0.1rem" }}
              />
            )}
          </div>
        </div>
      )}
      {error && (
        <div
          style={{
            marginLeft: "1rem",
            color: "red",
            display: "flex",
            alignItems: "center",
          }}
        >
          <TrashCan style={{ marginRight: "0.5rem" }} /> Source could not be
          validated
        </div>
      )}
      <Button
        kind="tertiary"
        onClick={handleAddClick}
        style={{
          marginTop: "1rem",
          width: "15rem",
          gap: "1rem",
          borderRadius: "var(--border-radius)",
        }}
      >
        <Link />
        Add Web Source
      </Button>
    </div>
  );
};

export default AddWebSource;

import React, { useState, useEffect } from "react";
import { InlineNotification, Button } from "@carbon/react";
import { ArrowLeft } from "@carbon/icons-react";
import "./FactCheckWindow.scss";
import ClickableTile from "./ClickableTile";
import SearchResults from "../Components/SearchResults";
import { api, api_external } from "../store";
import FactCheckSkeletonLoader from "./FactCheckSkeletonLoader";
import AddClaim from "./AddClaim";
import env from "../config";

const FactCheckWindow = ({
  projectId,
  userText,
  addCitation,
  toggleFactCheckMode,
  fileName,
  factCheck,
  setFactCheck,
}) => {
  const [displaySearchResults, setDisplaySearchResults] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState("");
  const [claims, setClaims] = useState([]);
  const [results, setResults] = useState([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState(false);
  const [isResultsLoading, setIsResultsLoading] = useState(false);
  const [claimsGenError, setClaimsGenError] = useState(false);
  const [claimsGenErrorMsg, setClaimsGenErrorMsg] = useState("");
  const [searchError, setSearchResultError] = useState("");

  const response = {
    data: {
      cqs: [
        // {
        //   "claim": "String is cheese",
        //   "query": "String cheese definition"
        // },
        // {
        //   "claim": "String is cheese",
        //   "query": "String cheese definition"
        // },
        // {
        //   "claim": "String is cheese",
        //   "query": "String cheese definition"
        // },
        // {
        //   "claim": "String is cheese",
        //   "query": "String cheese definition"
        // },
        // {
        //   "claim": "String is cheese",
        //   "query": "String cheese definition"
        // },
        // {
        //   "claim": "String is cheese",
        //   "query": "String cheese definition"
        // },
        // {
        //   "claim": "String is cheese",
        //   "query": "String cheese definition"
        // },
        // {
        //   "claim": "String is cheese",
        //   "query": "String cheese definition"
        // },
        // {
        //   "claim": "String is cheese",
        //   "query": "String cheese definition"
        // },
        // {
        //   "claim": "String is cheese",
        //   "query": "String cheese definition"
        // }
      ],
      usage: [
        {
          model: "gpt-4-1106-preview",
          service_tag: "generateClaimsAndQueries",
          usage: {
            prompt_tokens: 556,
            completion_tokens: 21,
            total_tokens: 577,
          },
        },
      ],
    },
  };
  const setResultLoader = (val) => {
    setIsResultsLoading(val);
  };

  useEffect(() => {
    if (factCheck) {
      setDisplaySearchResults(false);
      getClaims();
    }
  }, [factCheck]);

  const getClaims = async () => {
    // Log the call
    setIsClaimsLoading(true);
    setClaimsGenError(false);
    try {
      const selectedTxtObj = JSON.parse(userText);
      const selected_text = selectedTxtObj.selectedText;
      const context = selectedTxtObj.context;
      const requestData = {
        selected_text,
        context,
      };

      const response = await api_external.post(
        env.searchAPI + "/claim-query-gen",
        requestData
      );
      const generatedClaims = response.data.cqs;
      if (generatedClaims.length === 0) {
        setClaimsGenError(true);
        setClaimsGenErrorMsg("No claims identified");
      }
      api.post("/user/logAsyncServiceCall", {
        projectId: projectId,
        service_tag: "claim-query-gen",
        service_parent_id: null,
        service_filename: fileName,
        options: {
          service_progress_status: false,
          service_payload: {
            input: { selected_text, context },
            output: response.data.cqs,
          },
        },
        logs: response.data.usage,
      });
      setIsClaimsLoading(false);
      setClaims(generatedClaims);
      setFactCheck(false);
    } catch (err) {
      console.log(err);
      api.post("/user/logAsyncServiceCall", {
        projectId: projectId,
        service_tag: "claim-query-gen",
        service_parent_id: null,
        service_filename: null,
        options: {
          service_progress_status: false,
          service_error: true,
          service_error_msg: err?.message,
        },
      });
      setClaimsGenError(true);
      setClaimsGenErrorMsg(err?.message);
      setIsClaimsLoading(false);
    }
  };

  const displaySearchResult = (results, claim) => {
    setDisplaySearchResults(true);
    setSelectedClaim(claim);
    setResults(results);
  };

  const displaySearchError = (errMsg) => {
    setDisplaySearchResults(true);
    setSearchResultError(errMsg);
  };

  return (
    <div class="factcheck-container">
      {displaySearchResults ? (
        <h6
          onClick={() => {
            setDisplaySearchResults(false);
            setSearchResultError("");
          }}
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "2%",
            marginBottom: "5%",
            cursor: "pointer",
            color: "grey",
          }}
        >
          <ArrowLeft style={{ marginRight: "1%" }} /> back to claims
        </h6>
      ) : (
        <h6
          onClick={() => toggleFactCheckMode(false)}
          className="back-to-editor"
        >
          <ArrowLeft style={{ height: "1rem", width: "1rem" }} />
        </h6>
      )}
      {searchError && (
        <InlineNotification
          lowContrast
          kind="error"
          subtitle={
            "Oops! Something went wrong. Please try again or \
          contact us at support@xpub.ai for further support."
          } //errorMsg
          title="Error in fetching search results"
          hideCloseButton={true}
        />
      )}
      {isClaimsLoading && <FactCheckSkeletonLoader childComponent="Tile" />}
      {isResultsLoading && (
        <FactCheckSkeletonLoader childComponent="Accordion" />
      )}
      {!isClaimsLoading && !isResultsLoading && !displaySearchResults && (
        <div>
          <h5
            style={{
              border: "1px solid lightgray",
              borderRadius: "var(--border-radius)",
              padding: "2%",
              marginBottom: "2%",
            }}
          >
            <b>Selected text:</b>{" "}
            <span style={{ fontWeight: "400" }}>
              {JSON.parse(userText).selectedText}
            </span>
          </h5>
          {claimsGenError && claimsGenErrorMsg === "No claims identified" && (
            <InlineNotification
              lowContrast
              kind="info"
              subtitle={"Select different text or try Regenerate claims."} //errorMsg
              title="No claims generated"
              hideCloseButton={true}
            />
          )}
          {claimsGenError && claimsGenErrorMsg !== "No claims identified" && (
            <InlineNotification
              lowContrast
              kind="error"
              subtitle={
                "Oops! Something went wrong. Please try again or \
          contact us at support@xpub.ai for further support."
              } //errorMsg
              title="Error in claims generation"
              hideCloseButton={true}
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2%",
            }}
          >
            <div>
              {!claimsGenError ? (
                <div>
                  <h4 style={{ fontWeight: "500" }}>Identified claims</h4>
                  <h6 style={{ paddingTop: "1%", paddingBottom: "2%" }}>
                    Select one of the below claims to fact-check
                  </h6>
                </div>
              ) : null}
            </div>
            <div>
              {!isClaimsLoading &&
                !isResultsLoading &&
                !displaySearchResults && (
                  <Button style={{ marginTop: "3%" }} onClick={getClaims}>
                    Regenerate claims
                  </Button>
                )}
            </div>
          </div>
        </div>
      )}
      {!isClaimsLoading &&
        !isResultsLoading &&
        !displaySearchResults &&
        claimsGenError && (
          <div
            className="tile-list"
            style={{ overflowY: "auto", minHeight: "40vh", height: "40vh" }}
          ></div>
        )}
      {!isClaimsLoading &&
        !isResultsLoading &&
        !displaySearchResults &&
        !claimsGenError && (
          <div
            className="tile-list"
            style={{ overflowY: "auto", minHeight: "45vh", height: "45vh" }}
          >
            {claims.map((tile, index) => (
              <ClickableTile
                key={index}
                {...tile}
                searchResults={displaySearchResult}
                searchError={displaySearchError}
                resultsLoader={setResultLoader}
                projectId={projectId}
              />
            ))}
          </div>
        )}
      {!isClaimsLoading && !isResultsLoading && !displaySearchResults && (
        <AddClaim
          searchResults={displaySearchResult}
          searchError={displaySearchError}
          resultsLoader={setResultLoader}
          projectId={projectId}
        />
      )}

      {displaySearchResults && searchError === "" && (
        <div>
          <h5
            style={{
              border: "1px solid lightgray",
              borderRadius: "var(--border-radius)",
              padding: "2%",
              marginBottom: "2%",
            }}
          >
            <b>Selected claim:</b>{" "}
            <span style={{ fontWeight: "400" }}>{selectedClaim}</span>
          </h5>
          <SearchResults data={results} addCitation={addCitation} />
        </div>
      )}
    </div>
  );
};

export default FactCheckWindow;

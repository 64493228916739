import React, { useState } from "react";
import { Button, InlineLoading } from "@carbon/react";
import { TrashCan, Checkmark, DocumentAdd } from "@carbon/icons-react";
import { api } from "../store";

const AddFileResource = (props) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setError(null);
    setIsLoading(true);
    setIsUploaded(false);

    const formData = new FormData();
    formData.append("files", selectedFile);
    formData.append("ownerId", props.projectId);
    formData.append("fileTag", "uploaded");

    try {
      if (selectedFile) {
        const response = await api.post("file/uploadFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status === 200) {
          setIsUploaded(true);
        } else {
          setError("Failed to upload file");
        }
      }
    } catch (err) {
      console.log(err);
      setError("Error uploading file");
    } finally {
      setIsLoading(false);
      props.refreshFiles();
      props.refreshEditorFiles();
    }
  };

  const handleAddClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <div>
      <input
        id="fileInput"
        type="file"
        accept=".txt, .docx, .pdf"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <div>
        <Button
          kind="tertiary"
          style={{
            marginTop: "1rem",
            width: "15rem",
            gap: "1rem",
            borderRadius: "var(--border-radius)",
          }}
          onClick={handleAddClick}
        >
          <DocumentAdd />
          Add File Source
        </Button>
        {isLoading && (
          <InlineLoading
            description="Uploading file..."
            status="active"
            style={{ marginLeft: "1rem" }}
          />
        )}
        {isUploaded && (
          <div
            style={{
              marginLeft: "1rem",
              color: "green",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkmark style={{ marginRight: "0.5rem" }} /> File uploaded
            successfully.
          </div>
        )}
        {error && (
          <div
            style={{
              marginLeft: "1rem",
              color: "red",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TrashCan style={{ marginRight: "0.5rem" }} /> {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddFileResource;

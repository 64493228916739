import React, { useEffect } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useNavigate } from "react-router-dom";
import logo from "../Assets/xPub_Logo.png";
import { Grid, Column, Tag } from "@carbon/react";
import "./Login.scss";
import supabase from "../supabaseclient";
import env from "../config";

const ForgotPassword = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (event === "PASSWORD_RECOVERY") {
          navigate("/reset-password");
        }
      }
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [navigate]);
  const customTheme = {
    default: {
      colors: {
        brand: "var(--button-primary)",
        brandAccent: "var(--button-primary)",
      },
    },
  };

  return (
    <Grid justify="center" align="center" fullWidth className="no-padding">
      <Column sm={0} md={0} lg={10} xl={10} className="left no-padding">
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Tag
            type="outline"
            title="Clear Filter"
            className="tag"
            style={{ marginRight: "-20rem", marginBottom: "15rem" }}
          >
            Beta
          </Tag>
          <img src={logo} width={300} alt="xPub" />
        </div>
      </Column>
      <Column
        sm={4}
        md={8}
        lg={6}
        xl={6}
        className="no-padding"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div style={{ height: "100vh" }}>
          <div className="login-container">
            <div className="login-header">
              <span>Forgotten Password</span>
            </div>
            <div className="auth-full-width">
              <Auth
                supabaseClient={supabase}
                view="forgotten_password"
                redirectTo={`${env.landingPageUrl}/reset-password`}
                showLinks={false}
                appearance={{ theme: ThemeSupa, variables: customTheme }}
                localization={{
                  variables: {},
                }}
              />
            </div>
            <button
              className="supabase-auth-ui_ui-anchor c-dumjqv login-links"
              onClick={() => {
                navigate("/login");
              }}
            >
              Already have an account? Sign in
            </button>
          </div>
        </div>
      </Column>
    </Grid>
  );
};

export default ForgotPassword;

import React, { useState, useEffect } from "react";
import { Grid, Button, Column } from "@carbon/react";
import { useNavigate } from "react-router-dom";
import { api, api_external } from "../store";
import "./Dashboard.scss";
import env from "../config";
// import "react-chat-widget/lib/styles.css";
import ChatWidget from "../Components/ChatWidget";
import SalesForm from "../Components/SalesForm";
import VerticalStepper from "../Components/VerticalStepper";

const SalesDeck = () => {
  const navigate = useNavigate();
  const [rowData, setRows] = useState([]);
  const [displayForm, setDisplayForm] = useState(true);
  const [formData, setFormData] = useState({});
  const [salesPrep, setSalesPrepResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userArchiveIDs, setUserArchiveIDs] = useState([]);

  const getUserArchivesId = async () => {
    const response = await api.get("/archives/getUserArchivesId");
    if (response.data.archives.length > 0) {
      setUserArchiveIDs(response.data.archives);
      getArchives(response.data.archives);
    } else {
      setUserArchiveIDs([]);
    }
  };

  const getArchives = async (archiveIds) => {
    const idsString = archiveIds
      .map((item) => `${item.id}:${item.archiveType}`)
      .join(",");
    let response = await api.get(
      "/archives/getUserArchives?userArchivesId=" + idsString
    );
    const archives = response.data;
    setRows(archives);
  };

  useEffect(() => {
    //get archive IDs of the user
    getUserArchivesId();
  }, []);

  const handleSubmit = async (formData) => {
    setDisplayForm(false);
    try {
      // setIsLoading(true);
      const requestData = {
        company_name: formData.companyName,
        industry: formData.industry.value,
        team_type: formData.team,
        urls: formData.companyWebsite,
        location: formData.location,
        ownerIds: userArchiveIDs,
        fast_model: "gpt-3.5-turbo-0125",
        accurate_model: "gpt-4-0125-preview",
        web_model: "onar_medium_online",
      };
      // const response = await api_external.post(
      //   env.salesPubAPI+ "/sales-prep-pipeline",
      //   requestData
      // );
      // const results = response.data;
      // setSalesPrepResponse(results);
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {/* {process.env.REACT_APP_ENV === "staging" && <ChatWidget />} */}

      <Grid style={{ marginTop: "4rem" }} className="grid-dashboard">
        <Column lg={16} className="rounded-left column-dashboard">
          {displayForm && (
            <div
              style={{ margin: "0 0 0 5%", height: "73vh", overflow: "auto" }}
            >
              <div style={{ width: "100%" }}>
                {displayForm && (
                  <SalesForm
                    onSubmit={(formData) => {
                      setFormData(formData);
                      handleSubmit(formData);
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {!displayForm && salesPrep && (
            <div style={{ margin: "2% 5% 0 5%" }}>
              <div style={{ width: "100%" }}>
                {!displayForm && (
                  <div>
                    <VerticalStepper
                      formData={formData}
                      salesPrep={salesPrep}
                      isLoading={isLoading}
                      ownerIds={userArchiveIDs}
                      rowData={rowData}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </Column>
      </Grid>
    </div>
  );
};

export default SalesDeck;

import React, { useState } from "react";
import { Accordion, AccordionItem, Button, InlineLoading } from "@carbon/react";
import {
  WatsonHealthRotate_360,
  Copy,
  Launch,
  Document,
} from "@carbon/icons-react";
import { api } from "../store";
import AutoAdjustingTextArea from "./CustomTextArea";

const CustomAccordionItem = ({
  title,
  onGenerate,
  displayText,
  isLoading,
  sources,
  rowData,
  key,
}) => {
  const [openSourceUuid, setOpenSourceUuid] = useState(null);

  const handleSourceClick = (uuid) => {
    setOpenSourceUuid(openSourceUuid === uuid ? null : uuid);
  };

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const handleRegenerate = () => {
    onGenerate();
  };

  function getFileNameFromPath(path, start_time) {
    // Extracts the file name from the path
    if (path !== undefined) {
      // Check if the path contains "/"
      if (path.includes("/")) {
        const fileId = String(path).split("/").pop(); // Splitting and getting the last part
        const foundItem = rowData.find((item) => item.id === fileId); // Finding the item
        return foundItem ? foundItem.name : fileId; // Returning the found name or the ID
      } else {
        // If there is no "/", return the path directly
        return String(path);
      }
    } else {
      return String(path); // Return the path if it is undefined
    }
  }

  const handleArchiveFile = async (path, start_time) => {
    try {
      const fileId = String(path).split("/").pop();
      let foundItem = rowData.find((item) => item.id === fileId);
      if (foundItem === undefined)
        foundItem = rowData.find((item) => item.name === path);
      const response = await api.post("file/download", null, {
        params: {
          id: foundItem.id,
          ownerId: foundItem.archiveId,
        },
      });
      const contentType = response.data.fileType;
      if (contentType.includes("video")) {
        setShowVideoModal(true);
        setVideoUrl(response.data.url);
        setStartTime(start_time);
      } else if (contentType.includes("pdf")) {
        const pdfUrl = response.data.url;
        window.open(pdfUrl, "_blank");
      } else {
        const contents = response.data.contents;
        var newTab = window.open();
        newTab.document.write(contents.replace(/\n/g, "<br>"));
        newTab.document.close();
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  const handleLink = async (path) => {
    // Check if the path contains a '/'
    if (!path.includes("/")) {
      // If there is no '/', do nothing and return early
      return;
    }

    try {
      window.open(path, "_blank");
    } catch (err) {
      console.log("Error:", err);
    }
  };

  return (
    <div key={key} style={{ padding: "0 2%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          backgroundColor: "var(--xpub-secondary-color)",
          padding: "10px 16px",
          borderRadius: "var(--border-radius)",
          marginBottom: "8px",
        }}
      >
        {!isLoading && (
          <div>
            <h5
              style={{
                fontSize: "var(--text-medium)",
                fontWeight: "600",
                marginRight: "16px",
              }}
            >
              {title}
            </h5>
          </div>
        )}

        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ flex: "0 1 auto", minWidth: isLoading ? "auto" : "0px" }}
          >
            {isLoading ? (
              <InlineLoading
                description="Hang tight! We are generating research from your data and preferred sources"
                style={{ marginRight: "8px" }}
              />
            ) : (
              <div
                className="sales-button-panel archive-button"
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => {
                    handleRegenerate();
                  }}
                  iconDescription="Regenerate"
                  renderIcon={WatsonHealthRotate_360}
                  hasIconOnly
                />
                <Button
                  onClick={() => handleCopy(displayText)}
                  iconDescription="Copy"
                  renderIcon={Copy}
                  hasIconOnly
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          height: "50vh",
          overflowY: "auto",
          paddingRight: "1%",
        }}
      >
        <div>
          <AutoAdjustingTextArea
            displayText={displayText}
            onChangeHandler={() => {}}
          />
        </div>

        {sources && sources.length > 0 && (
          <div style={{ width: "100%", marginBottom: "2%", marginTop: "2%" }}>
            <h2
              style={{
                fontSize: "var(--text-medium)",
                fontWeight: "500",
                marginBottom: "1%",
                marginTop: "1%",
              }}
            >
              Sources
            </h2>
            <Accordion size="sm">
              {sources.map(({ extract, source_path, uuid, start_time }) => (
                <AccordionItem
                  title={`[${uuid}] ${extract.substring(0, 85)}...`}
                  key={uuid}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "2%",
                      marginLeft: "5%",
                      marginRight: "2%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "var(--text-medium)",
                        fontWeight: "400",
                      }}
                    >
                      {extract}
                    </p>
                    {source_path.includes("user_file_uploads/o") ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2%",
                          marginBottom: "1.5%",
                        }}
                      >
                        <Document style={{ marginRight: "2%" }} />
                        <span
                          title={getFileNameFromPath(source_path)}
                          style={{
                            marginRight: "auto",
                            fontSize: "var(--text-medium)",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "70%",
                            display: "inline-block",
                          }}
                        >
                          {getFileNameFromPath(source_path)}
                        </span>
                        <Launch
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleArchiveFile(source_path, start_time)
                          }
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2%",
                          marginBottom: "1.5%",
                        }}
                      >
                        <span
                          title={source_path}
                          style={{
                            marginRight: "auto",
                            fontSize: "var(--text-medium)",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "70%",
                            display: "inline-block",
                            padding: "5px 0",
                          }}
                        >
                          {source_path}
                        </span>
                        <Launch
                          style={{ cursor: "pointer" }}
                          onClick={() => handleLink(source_path)}
                        />
                      </div>
                    )}
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomAccordionItem;

import React, { useEffect, useState } from "react";
import { Modal, Dropdown, TextInput, Loading } from "@carbon/react";
import { api } from "../../store";
import { stripExtension } from "../../utils/Utils";

const SummarizeModal = ({
  isOpen,
  modalHeaderName,
  sourceText,
  onClose,
  // fileName,
  generateFileName,
  saveFile,
  setOutput,
  setTabLoading,
  tabIndex,
  setLoadingMsg,
  setShowMsg,
  onContentChange,
  setShowErrorMsg,
  setErrorMsg,
  listItems,
  getFileContent,
}) => {
  const [isOtherError, setOtherIsError] = useState(false);
  const [summaryLength, setSummaryLength] = useState("");
  // const [outputFileName, setOutputFileName] = useState(
  //   generateFileName(`${stripExtension(fileName)}_summary.docx`)
  // );
  const [filenameError, setFileNameError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);  


  const filesList = (listItems && listItems.length > 0 && listItems[0].items) ? listItems[0].items : [];

  // useEffect(() => {

  //   console.log("listItem", filesList);
  //   const fileName2 = selectedFile ? selectedFile.name : fileName;
  //   setOutputFileName(
  //     generateFileName(`${stripExtension(fileName2)}_summary.docx`)
  //   )
  // }, [fileName, sourceText, selectedFile]);

  const summarizeText = async function () {
    onModalClose();
    setShowErrorMsg(false);
    setTabLoading(true, tabIndex);
    setShowMsg(true);

    const outputFileName = generateFileName(`${stripExtension(selectedFile.name)}_summary.docx`);
    const text = sourceText ? sourceText : await getFileContent(selectedFile.name);
    console.log("text", text);



    onContentChange(outputFileName, "", tabIndex, false, "", []);
    setLoadingMsg(
      "Hang tight! We're crafting a summary of your text based on the length you indicated."
    );
    try {
      const response = await api.post(
        "ai/summarize",
        {
          fileName: outputFileName,
          text: text,
          type: summaryLength,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200 || response.data.error) {
        throw new Error(response.data.error || "Error calling summarize API");
      }

      const summary = response.data.summary;
      setOutput(
        summary,
        "summary",
        outputFileName,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        true,
        tabIndex
      );
      saveFile(
        summary,
        outputFileName,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "summary"
      );
    } catch (error) {
      setShowErrorMsg(true);
      setErrorMsg(
        error.message
          ? error.message
          : "Error in generating summary. Please contact support@xpub.ai."
      );
      console.error("Error in summary:", error);
    } finally {
      setShowMsg(false);
      setTabLoading(false, tabIndex);
    }
  };

  const selectOptions = [
    { id: "short", label: "Short" },
    { id: "medium", label: "Medium" },
    { id: "long", label: "Long" },
  ];

  const handleLengthDropdownChange = ({ selectedItem }) => {
    setOtherIsError(false);
    setSummaryLength(selectedItem ? selectedItem.id : "");
  };

  const handleFileSelectDropdownChange = ({ selectedItem }) => {
    setSelectedFile(selectedItem ? selectedItem : null);
  };

  const onModalClose = () => {
    setSummaryLength("");
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      modalHeading={modalHeaderName}
      primaryButtonText={"Summarize"}
      secondaryButtonText="Cancel"
      onRequestSubmit={summarizeText}
      onRequestClose={onModalClose}
      size="sm"
    >
      <div>
        <Dropdown
          id="file-dropdown"
          titleText="Select target file"
          label="Select file to summarize"
          items={filesList.filter(item => item.contentType !== ".mp3")}
          itemToString={(item) => (item ? item.name : "")}
          onChange={handleFileSelectDropdownChange}
          required
        />
      </div>
      {/* <div>
        <TextInput
          id="filename"
          labelText="Output file name"
          value={outputFileName}
          invalid={filenameError}
          className="text_input"
          onChange={(e) => {
            setFileNameError(false);
            setOutputFileName(e.target.value);
          }}
        />
      </div> */}

      <div style={{ marginTop: "1rem", marginBottom: "4rem" }}>
        <Dropdown
          id="length-dropdown"
          invalid={isOtherError}
          titleText="Summary length"
          label="Select the summary length"
          items={selectOptions}
          itemToString={(item) => (item ? item.label : "")}
          selectedItem={
            selectOptions.find((option) => option.id === summaryLength) || null
          }
          onChange={handleLengthDropdownChange}
        />
      </div>
    </Modal>
  );
};

export default SummarizeModal;

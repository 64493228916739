import React, { useState } from "react";
import { Form, TextInput, Dropdown, Button, TextArea } from "@carbon/react";
import URLTagInput from "./URLTagInput";

const SalesForm = (props) => {
  const [urls, setUrls] = useState([]);
  const [formData, setFormData] = useState({
    companyName: "",
    companyWebsite: urls,
    industry: "",
    location: "",
    team: "",
    pp: "",
  });

  const industryOptions = [
    { label: "CPG", value: "cpg" },
    { label: "Retail", value: "retail" },
    { label: "Healthcare & Life Sciences", value: "healthcare_lifesciences" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropdownChange = ({ selectedItem }) => {
    setFormData((prev) => ({
      ...prev,
      industry: selectedItem,
    }));
  };

  const setWebsiteUrls = (urls) => {
    setFormData((prev) => ({
      ...prev,
      companyWebsite: urls,
    }));
  };

  return (
    // <div>
    //   <h4>Fill customer details to generate a sales deck</h4>
    //   <br></br>
    //   <Form
    //     onSubmit={(e) => {
    //       e.preventDefault();
    //       props.onSubmit(formData);
    //     }}
    //   >
    //     <TextInput
    //       id="company-name"
    //       name="companyName"
    //       labelText="Customer company name"
    //       value={formData.companyName}
    //       onChange={handleChange}
    //       required
    //     />{" "}
    //     <br></br>
    //     <span className="label-multiple-url-heading">Company webpages</span>
    //     <br></br>
    //     <span className="label-multiple-url">
    //       Webpages should provide information about the company's values,
    //       mission, financials, etc. Use pages like "about us."
    //       Add multiple webpages by separating them with a comma, space, or
    //       newline. <br></br>
    //     </span>
    //     <URLTagInput setUrls={(urls) => setWebsiteUrls(urls)} />
    //     <br></br>
    //     <Dropdown
    //       id="industry"
    //       name="industry"
    //       titleText="Industry"
    //       label="Select an industry"
    //       items={industryOptions}
    //       itemToString={(item) => (item ? item.label : "")}
    //       onChange={handleDropdownChange}
    //       selectedItem={industryOptions.find(
    //         (option) => option.value === formData.industry
    //       )}
    //       required
    //     />
    //     <br></br>
    //     <TextInput
    //       id="location"
    //       name="location"
    //       labelText="Customer location"
    //       placeholder="New York City"
    //       value={formData.location}
    //       onChange={handleChange}
    //       required
    //     />{" "}
    //     <br></br>
    //     <TextInput
    //       id="team"
    //       name="team"
    //       labelText="Customer team"
    //       placeholder="Sales, Marketing"
    //       value={formData.team}
    //       onChange={handleChange}
    //       required
    //     />{" "}
    //     <br></br>
    //     <TextArea
    //       id="pp"
    //       name="pp"
    //       labelText="Problem statement"
    //       value={formData.pp}
    //       onChange={handleChange}
    //       required
    //     />{" "}
    //     <br></br>
    //     <Button type="submit">Generate</Button>
    //   </Form>
    // </div>
    <div style={{ width: "50%" }}>
      <br />
      <br />
      <br />
      <h4>Fill customer details to generate a sales deck</h4>
      <br />
      <br />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          props.onSubmit(formData);
        }}
      >
        <TextInput
          id="company-name"
          name="companyName"
          labelText="Customer company name"
          value={formData.companyName}
          onChange={handleChange}
          required
        />
        <br />
        <br />
        <span className="label-multiple-url-heading">Company webpages</span>
        <br />
        <span className="label-multiple-url">
          Webpages should provide information about the company's values,
          mission, financials, etc. Use pages like "about us." Add multiple
          webpages by separating them with a comma, space, or newline.
        </span>
        <br />
        <br />
        <URLTagInput setUrls={(urls) => setWebsiteUrls(urls)} />
        <br />
        <br />
        <Dropdown
          id="industry"
          name="industry"
          titleText="Industry"
          label="Select an industry"
          items={industryOptions}
          itemToString={(item) => (item ? item.label : "")}
          onChange={handleDropdownChange}
          selectedItem={industryOptions.find(
            (option) => option.value === formData.industry
          )}
          required
        />
        <br />
        <br />
        <TextInput
          id="location"
          name="location"
          labelText="Customer location"
          placeholder="New York City"
          value={formData.location}
          onChange={handleChange}
          required
        />
        <br />
        <br />
        <TextInput
          id="team"
          name="team"
          labelText="Customer team"
          placeholder="Sales, Marketing"
          value={formData.team}
          onChange={handleChange}
          required
        />
        <br />
        <br />
        <TextArea
          id="pp"
          name="pp"
          labelText="Problem statement"
          value={formData.pp}
          onChange={handleChange}
          required
        />
        <br />
        <br />
        <Button type="submit">Generate</Button>
        <br />
        <br />
      </Form>
    </div>
  );
};

export default SalesForm;

import React, { useState } from "react";
import { Grid, Column } from "@carbon/react";
import LeftPanel from "./Components/LeftPanel";
import "./Layout.scss";
import Tour  from "./Components/Tour.js";

const Layout = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsNavOpen(true);
  };

  const handleMouseLeave = () => {
    setIsNavOpen(false);
  };

  return (
    <div className="layout-container">
      <LeftPanel
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isNavOpen={isNavOpen}
      />
      <Grid className={`main-content ${isNavOpen ? "nav-open" : ""}`} fullWidth>
        <Column max={16} xlg={16} lg={16} md={8} sm={4} className="main-column">
          {children}
        </Column>
      </Grid>
      <Tour 
      onEnd={() => {}}
      setIsNavOpen={setIsNavOpen}
      />
    </div>
  );
};

export default Layout;

import React, { useState, useEffect } from "react";
import Joyride, { STATUS, EVENTS } from "react-joyride";

const TourEditor = ({ onEnd }) => {
  const [isTourOpen, setTourOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    // Check if the tour has been shown before
    const showTour = localStorage.getItem("showEditorTour") !== "false";
    if (showTour) {
      setTourOpen(true);
    }
  }, []);

  useEffect(() => {
    const handleDirectClick = () => {
      if (stepIndex === 2) {
        setStepIndex((prevIndex) => prevIndex + 1);
      }
    };

    const ideaButton = document.querySelector(".action-button.idea-button");

    if (ideaButton) {
      ideaButton.addEventListener("click", handleDirectClick);
    }

    return () => {
      if (ideaButton) {
        ideaButton.removeEventListener("click", handleDirectClick);
      }
    };
  }, [stepIndex]);

  const steps = [
    {
      target: "body",
      content:
        "You made it to the editor! Here, you can edit, transcribe, translate, summarize, and fact-check your documents.",
      placement: "center",
      disableOverlay: true,
      hideBackButton: true,
    },
    {
      target: "body",
      content:
        "But first lets checkout some ideas that have been generated based on your industry, business offerings and latest trends",
      placement: "center",
      disableOverlay: true,
      hideBackButton: true,
    },
    {
      target: ".action-button.idea-button",
      content:
        "Click here to get fresh ideas for your articles.",
      hideBackButton: true,
    },
  ];

  const handleTourCallback = (data) => {
    const { status, type, index } = data;
    console.log(status);

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      onEnd();
      setTourOpen(false);
      localStorage.setItem("showEditorTour", "false");
    } else if (type === EVENTS.STEP_AFTER) {
      setStepIndex(index + 1);
      if (index === 2) {
        // Trigger the create project button click
        const idea_button = document.querySelector(
          ".action-button.idea-button"
        );

        if (idea_button) {
          idea_button.click();
        }
      }
    }
  };

  return (
    <>
      <Joyride
        steps={steps}
        continuous={true}
        showSkipButton={true}
        showProgress={false}
        callback={handleTourCallback}
        run={isTourOpen}
        disableOverlayClose={true}
        spotlightClicks={true}
        hideCloseButton={true}
        stepIndex={stepIndex}
        locale={{
          back: "Back",
          close: "Close",
          last: "Next",
          next: "Next",
          skip: "Skip",
        }}
        styles={{
          options: {
            arrowColor: "var(--xpub-secondary-color)",
            backgroundColor: "var(--xpub-secondary-color)",
            primaryColor: "var(--element-hover-color)",
            textColor: "var(--text-color-secondary)",
            hideBackButton: true,
          },
        }}
      />
    </>
  );
};

export default TourEditor;

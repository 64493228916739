import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  Button,
  TextArea,
  InlineLoading,
} from "@carbon/react";
import {
  WatsonHealthRotate_360,
  Copy,
  Launch,
  Document,
  ChevronDown,
  ChevronUp,
  CheckmarkFilled,
  CloseFilled,
} from "@carbon/icons-react";
import { api } from "../store";
import AutoAdjustingTextArea from "./CustomTextArea";

const VerticalStepperTemp = ({
  key,
  title,
  onGenerate,
  displayText,
  sources,
  rowData,
  matchingRequirements,
  misMatchedRequirements,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showTick, setShowTick] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const [openSourceUuid, setOpenSourceUuid] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleSourceClick = (uuid) => {
    setOpenSourceUuid(openSourceUuid === uuid ? null : uuid);
  };

  useEffect(() => {
    // Sets a timer to switch off loading and decide on tick/X after 7-8 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
      setShowTick(matchingRequirements === "true"); // Sets true for tick, false for X
    }, 8000); // 8000 milliseconds = 8 seconds

    return () => clearTimeout(timer); // Cleanup the timer when the component unmounts
  }, [matchingRequirements]);

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const handleRegenerate = () => {
    onGenerate();
  };

  function getFileNameFromPath(path, start_time) {
    // Extracts the file name from the path
    if (path !== undefined) {
      const fileId = String(path).split("/").pop();
      const foundItem = rowData.find((item) => item.id === fileId);
      return foundItem ? foundItem.name : fileId;
    } else return path;
  }

  const handleArchiveFile = async (path, start_time) => {
    try {
      const fileId = String(path).split("/").pop();
      let foundItem = rowData.find((item) => item.id === fileId);
      if (foundItem === undefined)
        foundItem = rowData.find((item) => item.name === path);
      const response = await api.post("file/download", null, {
        params: {
          id: foundItem.id,
          ownerId: foundItem.archiveId,
        },
      });
      const contentType = response.data.fileType;
      if (contentType.includes("video")) {
        setShowVideoModal(true);
        setVideoUrl(response.data.url);
        setStartTime(start_time);
      } else if (contentType.includes("pdf")) {
        const pdfUrl = response.data.url;
        window.open(pdfUrl, "_blank");
      } else {
        const contents = response.data.contents;
        var newTab = window.open();
        newTab.document.write(contents.replace(/\n/g, "<br>"));
        newTab.document.close();
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  return (
    <div key={key} style={{ padding: "0 2%" }}>
      <div
        onClick={toggleOpen}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          backgroundColor: "var(--xpub-secondary-color)",
          padding: "10px 16px",
          borderRadius: "var(--border-radius)",
          marginBottom: "8px",
        }}
      >
        <div>
          <h5
            style={{
              fontSize: "var(--text-medium)",
              fontWeight: "600",
              marginRight: "16px",
            }}
          >
            {title}
          </h5>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ flex: "0 1 auto", minWidth: isLoading ? "auto" : "0px" }}
          >
            <div>
              {isLoading ? (
                <InlineLoading />
              ) : showTick ? (
                <CheckmarkFilled
                  style={{
                    width: "22px",
                    height: "22px",
                    fill: "green",
                    marginLeft: "2%",
                    marginTop: "1%",
                    marginRight: "5%",
                  }}
                />
              ) : (
                <CloseFilled
                  style={{
                    width: "22px",
                    height: "22px",
                    fill: "#db2121",
                    marginLeft: "2%",
                  }}
                />
              )}
            </div>
          </div>
          <div style={{ flexShrink: 0 }}>
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </div>
        </div>
      </div>
      {isOpen && (
        <div style={{ marginLeft: "4%", marginRight: "3%" }}>
          <AutoAdjustingTextArea
            displayText={displayText}
            onChangeHandler={() => {}}
          />
          <div
            className="sales-button-panel archive-button"
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "2%",
              marginTop: "1%",
            }}
          >
            <Button
              onClick={() => handleRegenerate()}
              iconDescription="Regenerate"
              renderIcon={WatsonHealthRotate_360}
              hasIconOnly
            />
            <Button
              onClick={() => handleCopy(displayText)}
              iconDescription="Copy"
              renderIcon={Copy}
              hasIconOnly
            />
          </div>
          {misMatchedRequirements && (
            <div style={{ width: "100%", marginBottom: "2%" }}>
              <h2
                style={{
                  fontSize: "var(--text-medium)",
                  fontWeight: "500",
                  marginBottom: "1%",
                  marginTop: "1%",
                }}
              >
                Mismatched Requirements
              </h2>
              {misMatchedRequirements.map((requirement, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "5px",
                    fontSize: "var(--text-medium)",
                  }}
                >
                  -{requirement.trim()}
                </div>
              ))}
            </div>
          )}
          {sources && sources.length > 0 && (
            <div style={{ width: "100%", marginBottom: "2%" }}>
              <h2
                style={{
                  fontSize: "var(--text-medium)",
                  fontWeight: "500",
                  marginBottom: "1%",
                  marginTop: "1%",
                }}
              >
                Sources
              </h2>
              <Accordion size="sm">
                {sources.map(({ extract, source_path, uuid, start_time }) => (
                  <AccordionItem
                    title={`[${uuid}] ${extract.substring(0, 85)}...`}
                    key={uuid}
                    open={openSourceUuid === uuid}
                    onClick={() => handleSourceClick(uuid)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "2%",
                        marginLeft: "5%",
                        marginRight: "2%",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "var(--text-medium)",
                          fontWeight: "400",
                        }}
                      >
                        {extract}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2%",
                          marginBottom: "1.5%",
                        }}
                      >
                        <Document style={{ marginRight: "2%" }} />
                        <span
                          title={getFileNameFromPath(source_path)}
                          style={{
                            marginRight: "auto",
                            fontSize: "var(--text-medium)",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "70%",
                            display: "inline-block",
                          }}
                        >
                          {getFileNameFromPath(source_path)}
                        </span>
                        <Launch
                          onClick={() =>
                            handleArchiveFile(source_path, start_time)
                          }
                        />
                      </div>
                    </div>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VerticalStepperTemp;

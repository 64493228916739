import React, { useState, useEffect } from "react";
import { Grid, Column, InlineNotification } from "@carbon/react";
import { api } from "../store";
import "./UserProfile.scss";
import PreferenceList from "../Components/PreferenceList";

const ArchiveSettings = () => {
  const [preferredItems, setPreferredItems] = useState([]);
  const [disallowedItems, setDisallowedItems] = useState([]);
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [invalidDomainError, setInvalidDomainError] = useState(false);
  const [invalidSourceName, setInvalidSourceName] = useState("");

  const handleAddItem = async (setItems, newItem) => {
    setInvalidDomainError(false);
    if (newItem) {
      const response = await api.post("/factcheck/validate-domain", {
        domain: newItem,
      });
      if (response && response.data && response.data.exists) {
        newItem = response.data.finalDomain;
        setItems((prevItems) => {
          if (!prevItems.includes(newItem)) {
            return [...prevItems, newItem];
          } else {
            return prevItems;
          }
        });
      } else {
        setInvalidSourceName(newItem);
        setInvalidDomainError(true);
      }
    }
  };

  const handleDeleteItem = (items, setItems) => (index) => {
    setInvalidDomainError(false);
    const updatedItems = items.filter((_, itemIndex) => itemIndex !== index);
    setItems(updatedItems);
  };

  async function getFactcheckProfile() {
    try {
      const response = await api.get("/factcheck/getFactcheckProfile");
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async function createFactcheckProfile() {
    try {
      const allowList = [
        "mckinsey.com",
        "wsj.com",
        "bain.com",
        "bcg.com",
        "kpmg.com",
        "deloitte.com",
        "ey.com",
        "pwc.com",
      ];
      const disallowList = [
        "quora.com",
        "reddit.com",
        "tiktok.com",
        "instagram.com",
        "wikipedia.org",
        "facebook.com",
        "linkedin.com",
      ];
      await api.post("/factcheck/createFactcheckProfile", {
        allowList: allowList,
        disallowList: disallowList,
        description: "",
      });
      setPreferredItems(allowList);
      setDisallowedItems(disallowList);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async function updateFactcheckProfile() {
    try {
      await api.put("/factcheck/updateFactcheckProfile", {
        allowList: preferredItems,
        disallowList: disallowedItems,
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  useEffect(() => {
    const initializeFactcheckProfile = async () => {
      try {
        let factcheckProfile = await getFactcheckProfile();

        if (!factcheckProfile) {
          await createFactcheckProfile();
        } else {
          setPreferredItems(factcheckProfile.allow_list);
          setDisallowedItems(factcheckProfile.disallow_list);
        }
        setIsInitialLoadComplete(true);
      } catch (err) {
        console.log(err);
      }
    };

    initializeFactcheckProfile();
  }, []);

  useEffect(() => {
    if (isInitialLoadComplete) {
      const update = async () => {
        try {
          await updateFactcheckProfile();
        } catch (err) {
          console.log(err);
        }
      };

      update();
    }
  }, [preferredItems, disallowedItems, isInitialLoadComplete]);

  return (
    <div style={{ margin: "1.5rem 2rem" }}>
      <div className="page-header">
        <div className="page-title-container">
          <span className="page-title" style={{ marginTop: "0.7rem" }}>
            Research Configuration
          </span>
        </div>
        <div className="page-support-text-container">
          <p>
            Set your sourcing preferences for research and fact-checking.
            Identify domain names or extension (for example 'un.org' or '.gov')
            to prefer and block in research.
          </p>
        </div>
      </div>
      <Grid
        fullWidth
        style={{
          padding: "0 1.3rem",
          height: "70vh",
          overflowY: "auto",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Column lg={16} md={8} sm={4}>
          {invalidDomainError && (
            <InlineNotification
              lowContrast
              kind="error"
              subtitle={`${invalidSourceName} is not a valid source. Retry the operation with a valid source.`}
              style={{ maxInlineSize: "70rem" }}
            />
          )}
        </Column>
        <Column className="preferred-sources" lg={7} md={8} sm={4}>
          <PreferenceList
            addOnClick={(newPreferredItem) => {
              handleAddItem(setPreferredItems, newPreferredItem);
            }}
            inputPlaceholder="Enter a source to target"
            listTitle="Preferred sources"
            listItems={preferredItems}
            itemAction={handleDeleteItem(preferredItems, setPreferredItems)}
          />
        </Column>
        <Column lg={1} md={8} sm={4}></Column>
        <Column className="ignored-sources" lg={7} md={8} sm={4}>
          <PreferenceList
            addOnClick={(newDisallowedItem) =>
              handleAddItem(setDisallowedItems, newDisallowedItem)
            }
            inputPlaceholder="Enter a source to be ignored"
            listTitle="Blocked sources"
            listItems={disallowedItems}
            itemAction={handleDeleteItem(disallowedItems, setDisallowedItems)}
          />
        </Column>
      </Grid>
    </div>
  );
};

export default ArchiveSettings;

import React, { useEffect, useState } from "react";
import { Widget, addResponseMessage } from "@ryaneewx/react-chat-widget";
import env from "../config";

import "@ryaneewx/react-chat-widget/lib/styles.css";
import "./ChatWidget.scss";

import { api, api_external } from "../store";

const ChatWidget = (props) => {
  const [userArchiveIDs, setUserArchiveIDs] = useState([]);
  const [isWelcomeMessageSent, setIsWelcomeMessageSent] = useState(false);

  useEffect(() => {
    const checkIfWidgetIsOpen = () => {
      const widgetContainer = document.querySelector(
        ".rcw-conversation-container"
      );
      const isWidgetOpen =
        widgetContainer &&
        !widgetContainer.classList.contains("rcw-close-widget-container");

      if (isWidgetOpen && !isWelcomeMessageSent) {
        addResponseMessage("How can I help you today?");
        setIsWelcomeMessageSent(true);
      }
    };

    // Add event listener to check widget status
    document.addEventListener("click", checkIfWidgetIsOpen);

    return () => {
      // Clean up the event listener
      document.removeEventListener("click", checkIfWidgetIsOpen);
    };
  }, [isWelcomeMessageSent]);

  useEffect(() => {
    getUserArchivesIds();

    async function getUserArchivesIds() {
      const response = await api.get("/archives/getUserArchivesId");
      if (response.data.archives.length > 0) {
        setUserArchiveIDs(response.data.archives);
      } else {
        setUserArchiveIDs([]);
      }
    }
  }, []);

  const handleNewUserMessage = async (newMessage) => {
    addResponseMessage("Fetching your answer...");
    if (newMessage.toLowerCase().startsWith("share some ideas")) {
      const answer = ideaResponse.answer;
      setTimeout(() => {
        addResponseMessage(answer[0].content);
      }, 5000);
    } else {
      // call the search API and get results
      const requestData = {
        query: newMessage,
        ownerIds: userArchiveIDs.map((item) => item.id),
        index_name: "archive-main",
      };
      const response = await api_external.post(
        env.searchAPI + "/archive-query",
        requestData
      );
      const results = response.data;
      const answer = results.answer;X
      addResponseMessage(answer[0].content);
    }
  };

  const ideaResponse = {
    answer: [
      {
        content:
          "1. The Environment Fund: The article could focus on the contributions of various countries since the past few years from the infographics that were recently published by UNEP. A focus on the top performing countries can be more engaging content. Expected impact: 2.5 million readers. \
          2. Progress in Climate Action Post- COP28: Discussions could focus on the advancements made since the Dubai Consensus at COP28, particularly in moving away from fossil fuels and the establishment of the Loss & Damage Fund.Expected impact: 2.2 million readers. \
          3. Energy Transition and Sustainable Mining: UNEP's work in promoting responsible mining and sustainable minerals and metals use, which are crucial for scaling up renewable energy and electric vehicles, would be a pertinent topic. Expected impact: 2.1 million readers. \n\n\
          4. Desertification, Drought, and Land Restoration: Highlighting efforts in combating desertification and drought, as well as proactive land restoration projects, such as those observed in Saudi Arabia. Expected impact: 2 million readers. \n\n\
          5. Plastic Pollution: Articles could delve into the development of an international legally binding instrument on plastic pollution, particularly focusing on marine environments, as part of the resolutions from UNEA-5.2. Expected impact: 2 million readers. \n\n\
          6. Global Environmental Rule of Law: The role of the Montevideo Environmental Law Programme in promoting the development and implementation of environmental rule of law and its contribution to the environmental dimension of the 2030 Agenda. Expected impact: 1.2 million readers. \n\n\
          7. Science-Policy Panel on Chemicals and Waste: Covering the work of the Open Ended Working Group to finalize proposals for a Science-Policy Panel focused on sound chemical management and pollution prevention. Expected impact: 1.1 million readers. \n\n\
          8. Antimicrobial Resistance (AMR): Discussing the 2024 United Nations General Assembly High-level Meeting on AMR and its global implications on healthcare and mortality rates. Expected impact: 1.1 million readers. \n\n\
          9. Biodiversity Conservation Efforts: The 16th Conference of the Parties to the Convention on Biological Diversity, focusing on global efforts to conserve biodiversity, biosafety, and access to genetic resources. Expected impact: 1 million readers. \n\n\
          10. Methane Emissions Reduction: Highlighting UNEP's initiatives such as the International Methane Emissions Observatory and the Climate and Clean Air Coalition's Global Methane Assessment in reducing methane emissions. Expected impact: 0.9 million readers. \n\n 11. Nature-based Solutions and Community Resilience: Exploring UNEP's work on Blue Forests Solutions and the influence of blue carbon in Nationally Determined Contributions(NDCs), as well as projects for ecosystem restoration benefiting communities.Expected impact: 0.9 million readers.\n\n",
        type: "text",
      },
      { content: "resource_1.png", type: "image_file" },
      { content: "resource_2.png", type: "image_file" },
      { content: "resource_3.png", type: "image_file" },
    ],
    sources: [],
    usage: [],
  };

  const handleSubmit = () => {};

  return (
    <div>
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        launcherCloseImg=""
        launcherOpenImg="data--center.svg"
        title="Ask archives"
        subtitle=""
        senderPlaceHolder="  Type a question"
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default ChatWidget;

import React, { useState } from "react";
import { Modal, TextArea } from "@carbon/react";
import { api } from "../store";

const FeedbackModal = ({ isOpen, onClose }) => {
  const [like, setLike] = useState("");
  const [dislike, setDislike] = useState("");

  const handleLikeChange = (event) => {
    setLike(event.target.value);
  };

  const handleDislikeChange = (event) => {
    setDislike(event.target.value);
  };

  const handleSubmitFeedback = () => {
    api.post("user/feedback", {
      message: { like, dislike },
    });
    onClose();
    setLike("");
    setDislike("");
  };

  return (
    <div>
      <Modal
        open={isOpen}
        modalHeading="Feedback"
        primaryButtonText="Submit"
        secondaryButtonText="Cancel"
        onRequestSubmit={handleSubmitFeedback}
        onRequestClose={onClose}
        size="sm"
      >
        <TextArea
          labelText="What did you like?"
          value={like}
          onChange={handleLikeChange}
        />
        <br></br>
        <TextArea
          labelText="How can we improve?"
          value={dislike}
          onChange={handleDislikeChange}
        />
      </Modal>
    </div>
  );
};

export default FeedbackModal;

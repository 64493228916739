import React, { useEffect, useState } from "react";
import { Modal, Button } from "@carbon/react";
import { Launch } from "@carbon/icons-react";
import { api } from "../store";
import DOMPurify from "dompurify";

const SafeDescription = ({ htmlContent }) => {
  // Early return if content is undefined or empty
  if (!htmlContent) {
    return null; // or return <div>No description available.</div> if you prefer to show a message
  }

  // Replace \n with <br> tags and then sanitize the HTML

  const formattedHtmlContent = htmlContent.replace(
    /•/g,
    "&nbsp;&nbsp;&nbsp;&nbsp;•"
  );

  // Replace newline characters with <br> tags for HTML rendering
  const finalHtmlContent = formattedHtmlContent.replace(/\\n/g, "<br>");
  const safeHTML = DOMPurify.sanitize(finalHtmlContent);

  return (
    <div style={{ marginBottom: "20px" }}>
      <strong>Template description</strong>
      <br></br>
      <span dangerouslySetInnerHTML={{ __html: safeHTML }}></span>
    </div>
  );
};

const StyleTemplate = ({ isOpen, styleData, onClose, refreshStyles }) => {
  const [files, setFiles] = useState([]);

  if (!styleData) return null;

  useEffect(() => {
    const fetchFileDetails = async () => {
      if (!styleData || !styleData.files) return;

      const response = await api.post("file/getFilesById", {
        files: styleData.files,
      });
      setFiles(response.data.files);
    };

    fetchFileDetails();
  }, []);

  const handleFileClick = async (fileId, owner) => {
    try {
      const response = await api.post("file/download", null, {
        params: {
          id: fileId,
          ownerId: owner,
        },
      });
      const contents = response.data.contents;
      var newTab = window.open();
      newTab.document.write(contents.replace(/\n/g, "<br>"));
      newTab.document.close();
    } catch (err) {
      console.log("Error:", err);
    }
  };

  const deleteProfile = async () => {
    try {
      const response = await api.delete(
        "style/deleteStyleProfile?styleId=" + styleData.id
      );
      onClose();
      refreshStyles();
    } catch (err) {
      console.log("Error:", err);
    }
  };

  return (
    <Modal
      open={isOpen}
      onRequestClose={onClose}
      passiveModal={true}
      size="md"
      modalHeading={styleData.name}
      className="styleTemplate"
      selectorPrimaryFocus="#dummyid"
    >
      <div id="dummyid">
        <SafeDescription htmlContent={styleData.description} />

        {!styleData.preset && (
          <div>
            {files.map((file, index) => (
              <div
                key={index}
                style={{
                  marginBottom: "5px",
                  border: "1px solid #ccc",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  borderRadius: "var(--border-radius)",
                  padding: "20px",
                  width: "60%",
                  backgroundColor: "var(--xpub-secondary-color)",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  maxHeight: "4em",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "90%",
                  }}
                >
                  {file.name}
                </span>{" "}
                <Launch
                  style={{ width: "10%" }}
                  onClick={() => handleFileClick(file._id, file.owner[0])}
                />
              </div>
            ))}
          </div>
        )}

        <br></br>
        {styleData.userOwned && (
          <div>
            <Button
              style={{ minWidth: "205px", marginBottom: "3%" }}
              onClick={deleteProfile}
              disabled={!styleData.userOwned}
              kind="danger"
            >
              Delete profile
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default StyleTemplate;

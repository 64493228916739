import React, { useEffect, useState } from "react";
import {
  Modal,
  SkeletonPlaceholder,
  TextInput,
  Button,
  Form,
  FormGroup,
  TextArea,
} from "@carbon/react";
import { api, api_external } from "../../store";
import env from "../../config";
import "./scss/GenerationModal.scss";
import { Copy, CheckmarkFilled } from "@carbon/icons-react";

const GenerateIdeasLoader = () => {
  const commonStyle = {
    width: "100%",
    height: "5rem",
    width: "100%",
  };

  return (
    <div>
      <div
          style={{
            // backgroundColor: "white",
            borderRadius: "var(--border-radius)",
            padding: "1rem 1rem",
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
      <span style={{ width: "100%", textAlign: "left"}}>
        Generating ideas based on your industry, business offerings, and latest trends...
      </span>
        <SkeletonPlaceholder style={commonStyle} />
        <SkeletonPlaceholder style={commonStyle} />
        <SkeletonPlaceholder style={commonStyle} />
        <SkeletonPlaceholder style={commonStyle} />
        <SkeletonPlaceholder style={commonStyle} />
        {/* <SkeletonPlaceholder style={commonStyle} /> */}
      </div>
    </div>
  );
};


const IdeaModal = ({ isOpen, onClose, projectId, setArticleModalOpen }) => {
  const [ideas, setIdeas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setErrorMsg] = useState("");
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    industry: "",
    businessOfferings: "",
    icp: "",
  });
  const [copiedIdeaIndex, setCopiedIdeaIndex] = useState(null);

  useEffect(() => {
    if (isOpen) {
      const getUser = async () => {
        let response = await api.get("user/getUserProfile");
        return response.data;
      };

      const getIdeas = async () => {
        setIsLoading(true);
        const user = await getUser();
        setUser(user);

        if (user.ideas.length > 0) {
          setIdeas(user.ideas);
          setIsLoading(false);
        } else {
          if (
            !user.industry ||
            !user.businessOfferings ||
            !user.icp ||
            !user.industryNews
          ) {
            setFormData({
              industry: user.industry || "",
              businessOfferings: user.businessOfferings || "",
              icp: user.icp || "",
            });
            setIsLoading(false);
          } else {
            await generateIdeas(user);
          }
        }
      };
      getIdeas();
    }
  }, [isOpen]);

  const generateIdeas = async (user) => {
    setIsLoading(true);
    setErrorMsg("");
    const requestData = {
      news: user.industryNews,
      industry: user.industry,
      offerings: user.businessOfferings,
      icp: user.icp,
    };
    try {
      const response = await api_external.post(
        env.searchAPI + "/ideas-gen",
        requestData
      );
      setIdeas(response.data.ideas);
      await updateUserProfile({ ideas: response.data.ideas });
      setIsLoading(false);
      api.post("/user/logAsyncServiceCall", {
        projectId: projectId,
        service_tag: "generateIdeas",
        service_parent_id: null,
        service_filename: null,
        options: {
          service_progress_status: false,
          service_payload: {
            input: {
              offerings: user.businessOfferings,
              icp: user.icp,
              industry: user.industry,
              news: user.news,
            },
            output: {
              ideas: response.data.ideas,
              usage: response.data.usage,
            },
          },
        },
        logs: response.data.usage,
      });
    } catch (error) {
      console.log(error);
      setErrorMsg(
        "Error generating ideas. Please retry the operation or contact support@xpub.ai."
      );
      setIsLoading(false);
      api.post("/user/logAsyncServiceCall", {
        projectId: projectId,
        service_tag: "generateIdeas",
        service_filename: "",
        options: {
          service_progress_status: false,
          service_error: true,
          service_error_msg: error?.message,
        },
      });
    }
  };

  // WHAT IS THIS HEALTHCARE DEFAULT?
  const generateNews = async (user) => {
    try {
      const response = await api_external.post(env.searchAPI + "/news-gen", {
        industry: user.industry ? user.industry : "Healthcare",
        offerings: user.businessOfferings
          ? user.businessOfferings
          : "AI based prior authorization",
        icp: user.icp ? user.icp : "Clinics and hospitals",
      });
      await api.put("user/update-user-news", {
        industry_news: response.data.news,
      });
      return response.data.news;
    } catch (err) {
      console.log(err);
    }
  };

  const updateUserProfile = async (updateObj) => {
    try {
      await api.put("/user/updateUserProfile", updateObj);
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    updateUserProfile(formData);
    const updatedUser = {
      ...user,
      ...formData,
    };
    const news = await generateNews(updatedUser);
    updatedUser.industryNews = news;
    await generateIdeas(updatedUser);
  };

  const onModalClose = () => {
    setIdeas([]);
    onClose();
  };

  const handleTileClick = (topic, narrative) => {
    setSelectedIdea(topic + " " + narrative);
  };

  const handleCopy = (index, text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIdeaIndex(index);
      setTimeout(() => setCopiedIdeaIndex(null), 5000); // Hide tooltip after 5 seconds
    });
  };

  return (
    <Modal
      open={isOpen}
      modalHeading=""
      primaryButtonText={"Generate article"}
      secondaryButtonText="Cancel"
      onRequestSubmit={() => {
        onModalClose();
        setArticleModalOpen(selectedIdea);
      }}
      onRequestClose={onModalClose}
      size="lg"
      primaryButtonDisabled={isLoading || selectedIdea === null}
      className="idea-modal"
    >
      <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1rem",
              padding: "0rem 1rem",
            }}
          >
            <span className="page-title">Ideas</span>
            <Button
              onClick={() => generateIdeas(user)}
              kind="primary"
              className="create-project-button"
            >
              Regenerate ideas
            </Button>
      </div>
      {isLoading && <GenerateIdeasLoader />}
      {!isLoading && error && !ideas && (
        <InlineNotification
          lowContrast
          kind="error"
          subtitle={error}
          title=""
        />
      )}
      {!isLoading &&
        user &&
        (!user.industry ||
          !user.businessOfferings ||
          !user.icp ||
          !user.industryNews ||
          !ideas) && (
          <Form onSubmit={handleFormSubmit}>
            <FormGroup
              legendText="Please provide details about your industry, business offerings, 
            and ideal customer profile to help us generate relevant ideas for you"
            >
              <TextInput
                id="industry"
                labelText="Industry"
                value={formData.industry}
                onChange={(e) =>
                  setFormData({ ...formData, industry: e.target.value })
                }
                required
              />
              <br></br>
              <TextInput
                id="businessOfferings"
                labelText="Business Offerings"
                value={formData.businessOfferings}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    businessOfferings: e.target.value,
                  })
                }
                required
              />
              <br></br>
              <TextArea
                id="icp"
                labelText="Ideal Customer Profile (ICP)"
                value={formData.icp}
                onChange={(e) =>
                  setFormData({ ...formData, icp: e.target.value })
                }
                required
              />
              <br></br>
              <Button type="submit" disabled={isLoading}>
                Submit
              </Button>
            </FormGroup>
          </Form>
        )}
      {!isLoading && ideas && (
        <div
          style={{
            // backgroundColor: "white",
            borderRadius: "var(--border-radius)",
            padding: "1rem 1rem",
            marginTop: "1rem",
            
          }}
        >
          <span>
            Select an idea and click on generate article to proceed with content
            generation
          </span>
          <div style={{ height: "60vh", overflowY: "auto" }}>
            {ideas.length > 0 &&
              ideas.map((idea, index) => (
                <div
                  key={index}
                  className={`file-tile ${
                    selectedIdea === idea.topic + " " + idea.narrative
                      ? "selected-tile"
                      : ""
                  }`}
                  onClick={() => handleTileClick(idea.topic, idea.narrative)}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: "1rem",
                    border:
                      selectedIdea === idea.topic + " " + idea.narrative
                        ? "2px solid purple"
                        : "2px solid #e0e0e0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span style={{ marginRight: "10px", fontWeight: "500" }}>
                        {idea.topic}
                      </span>
                      <span
                        style={{
                          display: "block",
                          margin: "0.2rem 0",
                          fontStyle: "italic",
                          fontSize: "var(--text-small)",
                        }}
                      >
                        {idea.narrative}
                      </span>
                    </div>
                    <div style={{ marginTop: "0.2rem" }}>
                      <button
                        title="Copy to clipboard"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopy(index, idea.topic + " " + idea.narrative);
                        }}
                        style={{
                          marginLeft: "10px",
                          padding: "0",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        {copiedIdeaIndex === index ? (
                          <div
                            style={{
                              display: "flex",
                              flex: "1",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <CheckmarkFilled
                              style={{ color: "var(--xpub-accent-color-2)" }}
                            />
                            <span style={{ fontSize: "var(--text-small)" }}>
                              Text copied!
                            </span>
                          </div>
                        ) : (
                          <Copy />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default IdeaModal;

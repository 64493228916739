import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Column,
  DataTable,
  TableContainer,
  TableToolbar,
  TableBatchActions,
  TableBatchAction,
  TableToolbarContent,
  TableToolbarSearch,
  Table,
  TableRow,
  TableSelectRow,
  TableHeader,
  TableBody,
  TableCell,
  TableHead,
  TextInput,
  Pagination,
  Modal,
} from "@carbon/react";
import { useNavigate } from "react-router-dom";
import { api } from "../store";
import { TrashCan } from "@carbon/icons-react";
import { Save, Add } from "@carbon/icons-react";
import "./Dashboard.scss";

const Dashboard = () => {
  const navigate = useNavigate();
  const [rowData, setRows] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isModalOpen, setModalState] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRow, setSelectedRow] = useState({ id: null });
  const [newName, setNewName] = useState("");
  const [isUpdateModalOpen, setUpdateModalState] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);
  const [selectedRowProject, setselectedRowProject] = useState("");
  const [isDeleteError, setDeleteError] = useState(false);
  const [user, setUser] = useState({});

  const changePaginationState = (pageInfo) => {
    if (page !== pageInfo.page) {
      setPage(pageInfo.page);
    }
    if (pageSize !== pageInfo.pageSize) {
      setPageSize(pageInfo.pageSize);
    }
  };

  const headers = [
    {
      key: "name",
      header: "Name",
    },
    {
      key: "created",
      header: "Creation Date",
    },
    {
      key: "description",
      header: "Description",
    },
  ];

  const getProject = async () => {
    let response = await api.get("project/getProjects");
    const rowsWithId = response.data.projects.map((row, index) => ({
      ...row,
      id: `${row._id}`,
    }));
    setRows(rowsWithId);
  };

  useEffect(() => {
    getProject();
    getUser();

    // Parse the query string for 'modal' parameter
    const queryParams = new URLSearchParams(location.search);
    const modalParam = queryParams.get("modal");
    if (modalParam === "open") {
      setModalState(true);
    }
  }, [location]);

  const getUser = async () => {
    let response = await api.get("user/getUserProfile");
    setUser(response.data);
  };

  const addNewProject = async () => {
    let response = await api.post("project/createProject", {
      name: name,
      description: description,
    });
    return response;
  };

  const handleCreate = async () => {
    if (name === "") {
      setIsError(true);
      return;
    }
    let response = await addNewProject();
    navigate("/editor", {
      state: {
        projectId: response.data.projectId,
        projectName: response.data.projectName,
        user: user,
      },
    });
  };

  const handleProjectLinkClick = (event, projectId, projectName) => {
    event.preventDefault(); // Prevents the link from navigating to the URL
    navigate("/editor", {
      state: {
        projectId: projectId,
        projectName: projectName,
        pregenerated: true,
        user: user,
      },
    });
  };

  const handleRowClick = (selRow) => {
    const updatedRows = rowData.map((row) => {
      if (row.id === selectedRow.id) {
        return { ...row, isSelected: false };
      } else if (row.id === selRow.id) {
        return { ...row, isSelected: true };
      } else {
        return row;
      }
    });
    setRows(updatedRows);
    setSelectedRow(selRow);
    setselectedRowProject(selRow.cells[0].value);
  };

  const handleProjectDelete = async (event) => {
    event.preventDefault(); // Prevents the link from navigating to the URL
    const row = selectedRow;
    if (deleteName !== row.cells[0].value) {
      setDeleteError(true);
      return;
    }
    let projectId = row.id;
    try {
      let response = await api.delete(
        "project/deleteProject?projectId=" + projectId
      );
      //refresh the table
      setDeleteModalState(false);
      getProject();
      setDeleteName("");
      setSelectedRow({ id: null });
    } catch (error) {
      console.log("Error while deleting a project: " + error.msg);
    }
  };

  const handleProjectRename = async (event) => {
    event.preventDefault(); // Prevents the link from navigating to the URL
    if (newName === "") {
      setIsError(true);
      return;
    }
    const row = selectedRow;
    let projectId = row.id;
    try {
      let response = await api.put("project/renameProject", {
        name: newName,
        projectId: projectId,
      });
      setUpdateModalState(false);
      //refresh the table
      getProject();
      setselectedRowProject(newName);
      row.cells[0].value = newName;
      setSelectedRow({ id: null });
      setNewName("");
    } catch (error) {
      console.log("Error while deleting a project: " + error.msg);
    }
  };

  return (
    <div
      style={{
        margin: "1.5rem 2rem",
      }}
    >
      <div>
        <Modal
          open={isModalOpen}
          modalHeading="Create a new project"
          primaryButtonText="Create"
          secondaryButtonText="Cancel"
          onRequestSubmit={handleCreate}
          onRequestClose={() => {
            setModalState(false);
          }}
          size="sm"
          className="create-project-modal"
        >
          <TextInput
            id="projectname"
            labelText="Project Name"
            value={name}
            invalid={isError}
            invalidText="Value cannot be empty"
            onChange={(e) => setName(e.target.value)}
            className="create-project-name-input"
          />{" "}
          <br></br>
          <TextInput
            id="projectdesc"
            labelText="Project Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            enableCounter={true}
            className="create-project-desc-input"
            maxCount={100}
          />
        </Modal>
        <Modal
          open={isUpdateModalOpen}
          modalHeading="Update Project"
          primaryButtonText="Update"
          secondaryButtonText="Cancel"
          onRequestSubmit={handleProjectRename}
          onRequestClose={() => {
            setUpdateModalState(false);
          }}
          size="sm"
        >
          <TextInput
            id="newName"
            labelText="Project Name"
            value={newName}
            invalid={isError}
            invalidText="Value cannot be empty"
            onChange={(e) => setNewName(e.target.value)}
          />{" "}
          <br></br>
        </Modal>
        <Modal
          open={isDeleteModalOpen}
          modalHeading="Confirm Delete Project"
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          onRequestSubmit={handleProjectDelete}
          onRequestClose={() => {
            setDeleteModalState(false);
          }}
          size="sm"
        >
          <TextInput
            id="deleteName"
            labelText={
              "To delete the project, type the project name: " +
              selectedRowProject
            }
            value={deleteName}
            invalid={isDeleteError}
            invalidText="Value cannot be empty or does not match the project name"
            onChange={(e) => {
              setDeleteError(false);
              setDeleteName(e.target.value);
            }}
          />{" "}
          <br></br>
        </Modal>
      </div>
      <div className="page-header">
        <div className="page-title-container">
          <span className="page-title">Projects</span>
          <Button
            onClick={(e) => {
              setModalState(true);
              e.stopPropagation();
            }}
            kind="primary"
            className="create-project-button"
          >
            Create Project
          </Button>
        </div>
      </div>
      <Grid fullWidth style={{ padding: "0 1.3rem" }}>
        <Column xlg={16} lg={16} md={8} sm={4}>
          <DataTable
            rows={rowData}
            headers={headers}
            selectedRows={[selectedRow.id]}
            onRowClick={(row) => handleRowClick(row)}
            radio
          >
            {({
              rows,
              headers,
              getHeaderProps,
              getRowProps,
              getSelectionProps,
              getBatchActionProps,
              onInputChange,
              selectedRows,
            }) => (
              <TableContainer>
                <TableToolbar>
                  <TableBatchActions
                    {...getBatchActionProps()}
                    shouldShowBatchActions={selectedRow.id != null}
                    totalSelected={selectedRow.id != null ? 1 : 0}
                    onCancel={() => {
                      setSelectedRow({ id: null });
                      //setIsBatchActionsOpen(false); // close batch actions
                    }}
                  >
                    <TableBatchAction
                      tabIndex={
                        getBatchActionProps().shouldShowBatchActions ? 0 : -1
                      }
                      renderIcon={TrashCan}
                      onClick={(e) => {
                        setDeleteModalState(true);
                        e.stopPropagation();
                      }}
                    >
                      Delete
                    </TableBatchAction>
                    <TableBatchAction
                      tabIndex={
                        getBatchActionProps().shouldShowBatchActions ? 0 : -1
                      }
                      renderIcon={Save}
                      onClick={(e) => {
                        setUpdateModalState(true);
                        e.stopPropagation();
                      }}
                    >
                      Rename
                    </TableBatchAction>
                  </TableBatchActions>
                  <TableToolbarContent>
                    <TableToolbarSearch
                      tabIndex={
                        getBatchActionProps().shouldShowBatchActions ? -1 : 0
                      }
                      onChange={onInputChange}
                    />
                  </TableToolbarContent>
                </TableToolbar>
                <Table>
                  <TableHead>
                    <TableRow>
                      <th scope="col" />
                      {headers.map((header, i) => (
                        <TableHeader
                          key={i}
                          {...getHeaderProps({
                            header,
                          })}
                        >
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice((page - 1) * pageSize)
                      .slice(0, pageSize)
                      .map((row) => (
                        <TableRow
                          {...getRowProps({ row })}
                          onClick={() => handleRowClick(row)}
                          isSelected={selectedRow.id === row.id}
                        >
                          <TableSelectRow
                            {...getSelectionProps({ row })}
                            checked={selectedRow.id === row.id}
                            selected={row.isSelected}
                          ></TableSelectRow>
                          {row.cells.map((cell) => {
                            if (cell.info.header === "name") {
                              return (
                                <TableCell key={cell.id}>
                                  <a
                                    style={{
                                      cursor: "pointer",
                                      color: "var(--button-primary)",
                                    }}
                                    onClick={(event) =>
                                      handleProjectLinkClick(
                                        event,
                                        row.id,
                                        cell.value
                                      )
                                    }
                                  >
                                    {cell.value}
                                  </a>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </DataTable>
          <Pagination
            onChange={changePaginationState}
            page={page}
            pageSize={pageSize}
            pageSizes={[10]}
            totalItems={rowData.length}
          />
        </Column>
      </Grid>
    </div>
  );
};

export default Dashboard;

import React, { useState } from "react";
import { api, api_external } from "../store";
import { Tile } from "@carbon/react";
import "./ClickableTile.scss";
import env from "../config";

const ClickableTile = ({
  claim,
  query,
  searchResults,
  resultsLoader,
  searchError,
  projectId,
}) => {
  const localResponse = {
    data: {
      results: [
        {
          title: "The New AI Wars Begin As Microsoft Announces ChatGPT ...",
          link: "https://www.forbes.com/sites/kenrickcai/2023/02/07/the-new-ai-wars-begin-as-microsoft-announces-chatgpt-integration-with-bing-and-edge/",
          snippet:
            "Feb 7, 2023 ... The New AI Wars Begin As Microsoft Announces ChatGPT Integration With Bing And Edge ... AI collaboration with OpenAI. Microsoft's announcement is ...",
          mime: "None",
          extracts: [
            {
              extract:
                "We’re going to move fast.”\n In a keynote presentation featuring remarks from both Nadella and OpenAI CEO Sam Altman, Microsoft said it had collaborated with OpenAI to set up Bing on a new large language model it claims is even more powerful than ChatGPT. The refreshed Bing provides a box with annotated AI answers to the right side of traditional search results. Users can also click on a new “Chat” tab which will replace the webpage with a chat interface similar to ChatGPT. The tech giant demoed example use cases, like generating customized meal plans and an itinerary of travel recommendations.",
              score: 0.8824356651814828,
            },
            {
              extract:
                "The tech giant demoed example use cases, like generating customized meal plans and an itinerary of travel recommendations. Altman said Bing’s model takes key learnings from OpenAI’s ChatGPT and GPT-3.5 to create something “faster and more capable.”\n “We’re starting with how to reshape the largest software category on planet earth: search,” Nadella said. That’s a direct challenge to Google, which currently dominates the search market. If Microsoft plays it well, the new AI integrations “could result in major share shifts away from Google” to Bing, which currently captures just 9% of the search market, Wedbush tech analysts Dan Ives and John Katsingris said in a report.\n",
              score: 0.872943521854921,
            },
            {
              extract:
                "The company said it was making the new Bing available immediately in a limited preview and plans for its full version to “expand to millions of people” in the coming weeks. “I think that this technology is going to reshape pretty much every software category,” Nadella said at the press announcement. He compared it to past pillars of technology like search, mobile and cloud, but said that even more than those paradigm shifts, AI is the closest the sector has come to a “Mosaic moment,” which introduced the web browser to the world. In a previous exclusive interview with Forbes, Altman suggested that technology such as the new Bing is mere table stakes compared to his vision for the tech.",
              score: 0.8812103887331918,
            },
          ],
          compression: [
            "In a keynote presentation featuring remarks from both Nadella and OpenAI CEO Sam Altman, Microsoft said it had collaborated with OpenAI to set up Bing on a new large language model it claims is even more powerful than ChatGPT.",
          ],
          max_score: 0.8824356651814828,
        },
        {
          title:
            "Reinventing search with a new AI-powered Microsoft Bing and Edge ...",
          link: "https://blogs.microsoft.com/blog/2023/02/07/reinventing-search-with-a-new-ai-powered-microsoft-bing-and-edge-your-copilot-for-the-web/",
          snippet:
            "Feb 7, 2023 ... ... AI, Bing, Microsoft Edge. Related Blogs. Jan 23, 2023 | Microsoft Corporate Blogs · Microsoft and OpenAI extend partnership. Jan 9, 2023 | ...",
          mime: "None",
          extracts: [],
          compression: null,
          max_score: null,
        },
        {
          title:
            "Confirmed: the new Bing runs on OpenAI's GPT-4 | Bing Search Blog",
          link: "https://blogs.bing.com/search/march_2023/Confirmed-the-new-Bing-runs-on-OpenAI%E2%80%99s-GPT-4",
          snippet:
            "Mar 14, 2023 ... Congratulations to our partners at Open AI for their release of GPT-4 today. We are happy to confirm that the new Bing is running on GPT-4, ...",
          mime: "None",
          extracts: [],
          compression: null,
          max_score: null,
        },
        {
          title:
            "Microsoft Bing will become ChatGPT's default search engine in ...",
          link: "https://www.geekwire.com/2023/microsoft-bing-will-become-chatgpts-default-search-engine-in-latest-openai-collaboration/",
          snippet:
            "May 23, 2023 ... Microsoft says Bing will become the default search engine for OpenAI's ChatGPT, giving the popular generative AI chatbot “timelier and more up- ...",
          mime: "None",
          extracts: [],
          compression: null,
          max_score: null,
        },
        {
          title:
            "Microsoft and OpenAI join forces; Bing empowers ChatGPT to take ...",
          link: "https://www.livemint.com/technology/tech-news/microsoft-and-openai-join-forces-bing-empowers-chatgpt-to-take-on-googles-dominance-what-it-means-in-the-ai-world-11684916866637.html",
          snippet:
            "May 24, 2023 ... ... AI tools. In a bid to promote collaboration and integration, Microsoft is granting external software developers and companies the ability to ...",
          mime: "None",
          extracts: [
            {
              extract:
                "\n          \nMicrosoft and OpenAI join forces; Bing empowers ChatGPT to take on Google's dominance. What it means in the AI world? | Mint\n  \n \n  \n          Explore    Sign in e-paper Subscribe Wednesday, 4 October 2023              Stocks       Mutual Funds       News                     Home Latest News Markets Premium Money Mutual Fund Industry Companies Technology Web Stories Opinion Videos      All Companies  Technology Markets Money Mutual Funds Insurance Auto  Industry  Personal Finance         Hello User  Sign in      Sign Out     My Account My Account Subscribe  My Watchlist   Newsletters   Notifications      My Reads   ",
              score: 0.8708871599765132,
            },
            {
              extract:
                "These copilots serve as helpful assistants for users across various tasks within Bing, Office, and even in security and finance software, leveraging OpenAI's advanced GPT-4 technology. With this strategic move, Microsoft aims to enhance its sales, attract a larger share of the cloud-computing market, and intensify its competition against Google in the search domain. Through its partnership with OpenAI, Microsoft has successfully positioned itself as a front runner in the dynamic landscape of emerging AI tools. In a bid to promote collaboration and integration, Microsoft is granting external software developers and companies the ability to create programs that seamlessly connect with its OpenAI-powered artificial intelligence software. As part of this initiative, Instacart Inc., Redfin Corp., Zillow Group Inc., and Kayak",
              score: 0.8754565421255753,
            },
            {
              extract:
                "As part of this initiative, Instacart Inc., Redfin Corp., Zillow Group Inc., and Kayak Software Corp. have been announced as partners who will collaborate with Bing's AI, according to Microsoft. Additionally, Adobe Inc. and Atlassian Corp. will introduce new functionalities for Microsoft's corporate AI software that can be seamlessly utilized within the Office suite. OpenAI is enabling external companies to create plugins that integrate with ChatGPT, allowing them to leverage the capabilities of these AI products. Microsoft has also adopted a similar approach, allowing companies and developers to develop plugins that can be used across ChatGPT as well as Microsoft's own consumer and enterprise AI software. You may be interested in As part of this endeavor, approximately 50 software makers,",
              score: 0.8810680714866985,
            },
          ],
          compression: null,
          max_score: 0.8810680714866985,
        },
      ],
      message: "Successfully retrieved search results!",
      usage: [
        {
          model: "text-embedding-ada-002",
          service_tag: "embedText",
          usage: {
            prompt_tokens: 3734,
            completion_tokens: 0,
            total_tokens: 3734,
          },
        },
        {
          model: "gpt-3.5-turbo",
          service_tag: "compressFilter",
          usage: {
            prompt_tokens: 1153,
            completion_tokens: 60,
            total_tokens: 1213,
          },
        },
      ],
    },
  };

  async function getFactcheckProfile() {
    try {
      const response = await api.get("/factcheck/getFactcheckProfile");
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  const handleTileClick = async () => {
    resultsLoader(true);
    try {
      // SHOULD BE KEPT IN STATE INSTEAD
      const factcheckProfile = await getFactcheckProfile();
      const requestData = {
        selected_claim: claim,
        selected_query: query,
        preference_list:
          factcheckProfile === null ? [] : factcheckProfile.allow_list,
        disallow_list:
          factcheckProfile === null ? [] : factcheckProfile.disallow_list,
      };
      const response = await api_external.post(
        env.searchAPI + "/web-extracts",
        requestData
      );
      const results = response.data.results;
      searchResults(results, claim);
      searchError("");
      api.post("/user/logAsyncServiceCall", {
        projectId: projectId,
        service_tag: "web-extracts",
        service_parent_id: null,
        service_filename: null,
        options: {
          service_progress_status: false,
          service_payload: {
            input: {
              selected_claim: claim,
              selected_query: response.data.query,
            },
            output: results,
          },
        },
        logs: response.data.usage,
      });
    } catch (err) {
      console.log(err);
      api.post("/user/logAsyncServiceCall", {
        projectId: projectId,
        service_tag: "web-extracts",
        service_parent_id: null,
        service_filename: null,
        options: {
          service_progress_status: false,
          service_error: true,
          service_error_msg: err?.message,
        },
      });
      searchError(err?.message);
    }
    resultsLoader(false);
  };

  return (
    <Tile className="clickable-tile" onClick={handleTileClick}>
      <h6 style={{ fontWeight: "450", paddingTop: "1%" }}>{claim}</h6>
    </Tile>
  );
};

export default ClickableTile;

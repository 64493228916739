import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import App from './App';
import { api, api_external } from './store';

api.defaults.headers.common['x-access-token'] = localStorage.getItem('token');
api_external.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useState } from "react";
import { api_external } from "../store";
import {
  Button,
  ProgressIndicator,
  ProgressStep,
  InlineLoading,
  InlineNotification,
} from "@carbon/react";
import CustomAccordionItem from "./CustomAccordionItem";
import "./VerticalStepper.scss";
import env from "../config";

const NextPanel = ({
  step,
  setStep,
  totalSteps,
  isLoading,
  generationFunctions,
}) => {
  const handleNext = () => {
    if (step < totalSteps) {
      const nextStep = step + 1;
      setStep(nextStep);
      if (generationFunctions && generationFunctions[nextStep]) {
        generationFunctions[nextStep]();
      }
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginTop: "20px",
      }}
    >
      {step > 1 ? (
        <Button kind="secondary" onClick={handlePrevious}>
          Previous
        </Button>
      ) : (
        <div /> // Empty div to maintain the spacing
      )}
      <Button onClick={handleNext} disabled={step === totalSteps || isLoading}>
        Next
      </Button>
    </div>
  );
};

const VerticalStepper = (props) => {
  const [rapport, setRapportContent] = useState("");
  const [rapportLoading, setRapportLoading] = useState(false);

  const [companyInfo, setCompanyInfo] = useState({});
  const [painPointsCompressed, setCompressedPP] = useState({});

  const [pr, setPR] = useState("");
  const [prSources, setPRSources] = useState([]);
  const [prLoading, setPRLoading] = useState(false);

  const [industryInsights, setIndustryInsights] = useState("");
  const [iiSources, setIISources] = useState([]);
  const [iiLoading, setIILoading] = useState(false);

  const [sr, setSR] = useState("");
  const [srSources, setSRSources] = useState([]);
  const [srLoading, setSRLoading] = useState(false);

  const [synergiesContent, setSynergiesContent] = useState("");
  const [synergiesSources, setSynergiesSources] = useState([]);
  const [synergiesLoading, setSynergiesLoading] = useState(false);

  const [webUrl, setWebURL] = useState("");
  // const [modelOpen, setModalOpen] = useState(false);
  const [generatingLink, setGeneratingLink] = useState(false);
  const [step, setStep] = useState(1);
  const [prError, setPRError] = useState("");
  const [iiError, setIIError] = useState("");
  const [srError, setSRError] = useState("");
  const [synergyError, setSynergyError] = useState("");

  useEffect(() => {
    generateInitial();
  }, []);

  const generateInitial = async () => {
    try {
      setPRLoading(true);
      console.log(props.formData);
      const name = props.formData.companyName;
      const team = props.formData.team;
      const pain_points = props.formData.pp;
      const industry = props.formData.industry.value;
      const info = await generateCompanyInfo(props.formData.companyWebsite);
      const pp = await generatePainPoints(pain_points);
      await generateProblemResearch(name, team, industry, pp, info);
      setStep(1);
    } catch (err) {
      console.log(err);
    }
  };

  const regenerateRapport = async (location) => {
    setRapportLoading(true);
    setRapportContent("");
    const requestData = {
      location: location,
      model: "gpt-3.5-turbo-0125",
    };
    const response = await api_external.post(
      env.salesPubAPI + "/rapport-builder",
      requestData
    );
    setRapportContent(response.data.response);
    setRapportLoading(false);
  };

  const generateCompanyInfo = async (urls) => {
    const requestData = {
      urls: urls,
      model: "gpt-3.5-turbo-0125",
    };
    const response = await api_external.post(
      env.salesPubAPI + "/company-info-extractor",
      requestData
    );
    const info = response.data.company_info;
    setCompanyInfo(info);
    return info;
  };

  const generatePainPoints = async (pain_points) => {
    const requestData = {
      pain_points: pain_points,
    };
    const response = await api_external.post(
      env.salesPubAPI + "/compress_pain_points",
      requestData
    );
    const compressed_pp = response.data.compressed_pain_points;
    setCompressedPP(compressed_pp);
    return compressed_pp;
  };

  const generateIndustryInsights = async (
    name,
    team,
    pain_points,
    industry,
    info
  ) => {
    try {
      setIILoading(true);
      setIndustryInsights("");
      setIIError("");
      setIISources([]);
      const requestData = {
        industry: industry,
        company_name: name,
        company_info: info,
        team_type: team,
        owner_ids: [props.ownerIds[0].id],
        pain_points: pain_points,
        model: "gpt-4-0125-preview",
      };
      const response = await api_external.post(
        env.salesPubAPI + "/industry-insights-generator",
        requestData
      );
      const insights = response.data.industry_insights;
      let text_content = "";
      insights.forEach((insight) => {
        if (insight.type === "text") {
          text_content += insight.content + "\n\n";
        }
      });

      setIndustryInsights(text_content);
      const sources = response.data.sources;
      setIISources(sources);
      setIILoading(false);
    } catch (err) {
      console.log(err);
      setIILoading(false);
      setIIError(err);
    }
  };

  function formatKey(s) {
    // Replace underscores with spaces
    let formattedString = s.replace(/_/g, " ");
    // Capitalize the first letter of each word
    formattedString = formattedString.replace(
      /(^|\s)(\S)/g,
      (match, p1, p2) => p1 + p2.toUpperCase()
    );
    return formattedString;
  }

  function formatCompanyInfo(company_info) {
    let company_info_str = "";
    let missing_info_str = "";
    for (const [key, value] of Object.entries(company_info)) {
      if (value !== null) {
        company_info_str += `${formatKey(key)}: ${value}\n`;
      } else {
        missing_info_str += `Missing information on ${formatKey(
          key
        ).toLowerCase()}.\n`;
      }
    }
    return [company_info_str, missing_info_str];
  }

  const generateProblemResearch = async (
    name,
    team,
    industry,
    pain_points,
    info
  ) => {
    try {
      setPRLoading(true);
      setPR("");
      setPRError("");
      setPRSources([]);
      const requestData = {
        company_info: info,
        company_name: name,
        team_type: team,
        industry: industry,
        owner_ids: [props.ownerIds[0].id],
        pain_points: pain_points,
        model: "gpt-4-0125-preview",
      };
      const response = await api_external.post(
        env.salesPubAPI + "/problem-research-generator",
        requestData
      );
      const problem_research = response.data.problem_research;
      const formattedCompanyInfo = formatCompanyInfo(info);
      let text_content =
        "General Company Overview\n\n" +
        formattedCompanyInfo[0] +
        "\n" +
        formattedCompanyInfo[1] +
        "\n\n";
      problem_research.forEach((pr) => {
        if (pr.type === "text") {
          text_content += pr.content + "\n\n";
        }
      });

      setPR(text_content);
      const sources = response.data.sources;
      setPRSources(sources);
      setPRLoading(false);
    } catch (err) {
      console.log(err);
      setPRLoading(false);
      setPRError(err);
    }
  };

  const generateSolutionResearch = async (name, team, industry) => {
    try {
      setSRLoading(true);
      setSR("");
      setSRError("");
      setSRSources([]);
      const requestData = {
        industry: industry,
        company_name: name,
        company_info: companyInfo,
        team_type: team,
        owner_ids: [props.ownerIds[0].id],
        pain_points: painPointsCompressed,
        model: "gpt-4-0125-preview",
      };
      const response = await api_external.post(
        env.salesPubAPI + "/solution-research-generator",
        requestData
      );
      const sr = response.data.solution_research;
      let text_content = "";
      sr.forEach((sr) => {
        if (sr.type === "text") {
          text_content += sr.content + "\n\n";
        }
      });
      setSR(text_content);
      const sources = response.data.sources;
      setSRSources(sources);
      setSRLoading(false);
    } catch (err) {
      console.log(err);
      setSRLoading(false);
      setSRError(err);
    }
  };

  const generateSynergiesProposal = async (name, team, industry) => {
    try {
      setSynergiesLoading(true);
      setSynergiesContent("");
      setSynergyError("");
      setSynergiesSources([]);
      const requestData = {
        solution_research: sr,
        company_info: companyInfo,
        company_name: name,
        team_type: team,
        industry: industry,
        industry_insights: industryInsights,
        pain_points: painPointsCompressed,
        model: "gpt-4-0125-preview",
      };
      const response = await api_external.post(
        env.salesPubAPI + "/synergy-proposal-generator",
        requestData
      );
      const synergyProposal = response.data.synergy_proposal;
      let text_content = "";
      synergyProposal.forEach((sp) => {
        if (sp.type === "text") {
          text_content += sp.content + "\n\n";
        }
      });
      setSynergiesContent(text_content);
      const sources = response.data.sources;
      setSynergiesSources(sources);
      setSynergiesLoading(false);
    } catch (err) {
      console.log(err);
      setSynergiesLoading(false);
      setSynergyError(err);
    }
  };

  function processText(text) {
    const superscriptStyle = {
      fontSize: "0.6rem",
      color: "navy",
    };
    console.log(text);
    return text.split(/(\[\d+\])/g).map((part, index) => {
      if (/\[\d+\]/.test(part)) {
        return (
          <sup key={index} style={superscriptStyle}>
            {part}
          </sup>
        );
      }
      return part;
    });
  }

  const decodeHtml = (text) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = text;
    return txt.value;
  };

  function replaceSpacesWithUnderscores(str) {
    return str.replace(/ /g, "_");
  }

  const generateDeck = async () => {
    setGeneratingLink(true);
    // const sourcePaths = useCasesSources.map((source) => source.source_path);
    console.log(props.formData.companyName);
    const requestData = {
      problem_research: pr,
      use_case_file_paths: [],
      synergy_proposal: synergiesContent,
      pptx_filename:
        replaceSpacesWithUnderscores(props.formData.companyName) + ".pptx",
    };
    const response = await api_external.post(
      env.salesPubAPI + "/sales-prep-generate-deck",
      requestData,
      { responseType: "blob" }
    );
    const fileUrl = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      })
    );
    setWebURL(fileUrl);
    setGeneratingLink(false);
    // setModalOpen(true);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(webUrl)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const generationFunctions = {
    1: () =>
      generateProblemResearch(
        props.formData.companyName,
        props.formData.team,
        painPointsCompressed,
        props.formData.industry.value,
        companyInfo
      ),
    2: () =>
      generateIndustryInsights(
        props.formData.companyName,
        props.formData.team,
        painPointsCompressed,
        props.formData.industry.value,
        companyInfo
      ),
    3: () =>
      generateSolutionResearch(
        props.formData.companyName,
        props.formData.team,
        props.formData.industry.value
      ),
    4: () =>
      generateSynergiesProposal(
        props.formData.companyName,
        props.formData.team,
        props.formData.industry.value
      ),
    5: () => generateDeck(),
  };

  return (
    <div>
      {/* <Modal
        open={modelOpen}
        size="sm"
        passiveModal={true}
        onRequestClose={() => {
          setModalOpen(false);
        }}
      >
        <div
          style={{
            position: "relative",
            marginTop: "10%",
            height: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%" }}>
            <div>
              <span className="deck-label">
                {props.formData.companyName} Deck Url
              </span>
            </div>
            <div
              style={{
                maxWidth: "92%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Link href={webUrl} target="_blank" rel="noopener noreferrer">
                {webUrl}
              </Link>
            </div>
          </div>

          <div className="archive-button" style={{ marginTop: "4%" }}>
            <Button
              onClick={() => handleCopy()}
              iconDescription="Copy"
              renderIcon={Copy}
              hasIconOnly
            />
          </div>
        </div>
      </Modal>{" "} */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        ></div>
      </div>
      <div
        style={{
          marginTop: "2%",
          alignContent: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <ProgressIndicator currentIndex={step - 1} className="sales-prep">
          <ProgressStep
            label="Problem Research"
            description="Problem research"
          />
          <ProgressStep
            label="Industry Insights"
            description="Industry insights"
          />
          <ProgressStep
            label="Relevant Case Studies"
            description="Solution research"
          />
          <ProgressStep label="Proposal" description="Synergies proposal" />
          <ProgressStep label="Generate Deck" description="Generate deck" />
          <ProgressStep
            label="Rapport Building"
            description="Call preparation"
          />
        </ProgressIndicator>
      </div>
      <div style={{ height: "60vh", marginTop: "2%" }}>
        {" "}
        {step === 1 && (
          <div>
            {prError && (
              <div style={{ marginTop: "2%" }}>
                <InlineNotification
                  lowContrast
                  kind="error"
                  subtitle={
                    "Error in problem research generation. Please retry the operation"
                  }
                  title="Error in generation"
                  hideCloseButton={true}
                />
              </div>
            )}
            <div style={{ marginTop: "2%" }}>
              <CustomAccordionItem
                key="pr"
                isLoading={prLoading}
                title={`${props.formData.companyName} problem research`}
                displayText={pr}
                sources={prSources}
                rowData={props.rowData}
                onGenerate={() => {
                  generateProblemResearch(
                    props.formData.companyName,
                    props.formData.team,
                    props.formData.industry.value,
                    painPointsCompressed,
                    companyInfo
                  );
                }}
              />
            </div>
          </div>
        )}
        {step === 2 && (
          <div>
            {iiError && (
              <div style={{ marginTop: "2%" }}>
                <InlineNotification
                  lowContrast
                  kind="error"
                  subtitle={
                    "Error in industry insights generation. Please retry the operation"
                  }
                  title="Error in generation"
                  hideCloseButton={true}
                />
              </div>
            )}
            <div style={{ marginTop: "2%" }}>
              <CustomAccordionItem
                key="ii"
                isLoading={iiLoading}
                title={`${props.formData.companyName} industry research`}
                displayText={industryInsights}
                sources={iiSources}
                rowData={props.rowData}
                onGenerate={() => {
                  generateIndustryInsights(
                    props.formData.companyName,
                    props.formData.team,
                    painPointsCompressed,
                    props.formData.industry.value,
                    companyInfo
                  );
                }}
              />
            </div>
          </div>
        )}
        {step === 3 && (
          <div>
            {srError && (
              <div style={{ marginTop: "2%" }}>
                <InlineNotification
                  lowContrast
                  kind="error"
                  subtitle={
                    "Error in solution research generation. Please retry the operation"
                  }
                  title="Error in generation"
                  hideCloseButton={true}
                />
              </div>
            )}
            <div style={{ marginTop: "2%" }}>
              <CustomAccordionItem
                key="sr"
                isLoading={srLoading}
                title="Solutions research"
                displayText={sr}
                sources={srSources}
                rowData={props.rowData}
                onGenerate={() =>
                  generateSolutionResearch(
                    props.formData.companyName,
                    props.formData.team,
                    props.formData.industry.value
                  )
                }
              />
            </div>
          </div>
        )}
        {step === 4 && (
          <div>
            {synergyError && (
              <div style={{ marginTop: "2%" }}>
                <InlineNotification
                  lowContrast
                  kind="error"
                  subtitle={
                    "Error in proposal generation. Please retry the operation"
                  }
                  title="Error in generation"
                  hideCloseButton={true}
                />
              </div>
            )}
            <div style={{ marginTop: "2%" }}>
              <CustomAccordionItem
                key="sp"
                isLoading={synergiesLoading}
                title="Proposal"
                displayText={synergiesContent}
                sources={synergiesSources}
                rowData={props.rowData}
                onGenerate={() =>
                  generateSynergiesProposal(
                    props.formData.companyName,
                    props.formData.team,
                    props.formData.industry.value
                  )
                }
              />
            </div>
          </div>
        )}
        {step === 5 && (
          <div>
            <div style={{ marginTop: "2%" }}>
              {generatingLink && (
                <InlineLoading
                  description="Hang tight! We are generating deck for you from the research"
                  style={{ marginRight: "8px" }}
                />
              )}
              {webUrl && (
                <iframe
                  src={`https://docs.google.com/gviewer?url=${encodeURIComponent(
                    webUrl
                  )}&embedded=true`}
                  style={{ width: "100%", height: "100vh", border: "none" }}
                  frameBorder="0"
                ></iframe>
              )}
            </div>
          </div>
        )}
        {step === 6 && (
          <div>
            <div style={{ marginTop: "2%" }}>
              <CustomAccordionItem
                key="rapport"
                isLoading={rapportLoading}
                title="Problem Research"
                displayText={rapport}
                sources=""
                onGenerate={() => regenerateRapport(props.formData.location)}
              />
            </div>
          </div>
        )}
      </div>
      <NextPanel
        step={step}
        setStep={setStep}
        totalSteps={5}
        isLoading={prLoading || iiLoading || srLoading || synergiesLoading}
        generationFunctions={generationFunctions}
      />
    </div>
  );
};

export default VerticalStepper;

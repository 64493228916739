import React, { useState } from "react";
import { Modal, InlineNotification } from "@carbon/react";
import { api } from "../store";
import FileUploader from "./FileUploader";
import { allowedContentTypesForTranscription } from "../util";

const FileUploaderModal = ({
  isOpen,
  onClose,
  modalHeading,
  refreshFiles,
  projectId,
  allowedFileTypes,
  sendNotification,
  allowAudio,
}) => {
  const [uploadErrorMessages, setUploadErrorMessages] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [filesAndStatus, setFilesAndStatus] = useState([]);
  const [transNotify, setTransNotify] = useState("");
  const [primaryButtonText, setPrimaryButtonText] = useState("Upload");

  function updateFileUploaderStatus(updatedFilesAndStatus) {
    setUploadErrorMessages([]);
    setFilesAndStatus(updatedFilesAndStatus);
    setUploadError(false);
  }

  async function transcribeAudioFile(fileName, audioFile, contentType) {
    try {
      await api
        .post(
          "ai/transcribe",
          {
            projectId: projectId,
            fileName,
            filePath: audioFile,
            contentType,
          },
          {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
          }
        )
        .then(() => {
          sendNotification(fileName);
          refreshFiles();
          setTimeout(() => {
            sendNotification("");
          }, 8000);
        });
    } catch (error) {
      throw error;
    }
  }

  async function deleteFile(fileId) {
    try {
      // Send delete request to the server
      const response = await api.delete("/file/deleteFile", {
        params: {
          fileId: fileId,
          ownerId: state.projectId.toString(),
        },
      });
      return response.data.message;
    } catch (err) {
      throw err;
    }
  }

  async function uploadFiles(files) {
    const formData = new FormData();

    files.forEach((file) => formData.append("files", file));
    formData.append("ownerId", projectId);
    formData.append("fileTag", "uploaded");
    try {
      const response = await api.post("file/uploadFiles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  }

  const submitUploadFiles = async () => {
    let updatedFilesAndStatus = filesAndStatus.map((fs) =>
      fs.status === "edit" ? { ...fs, status: "uploading" } : fs
    );

    try {
      setFilesAndStatus(updatedFilesAndStatus);
      setUploadErrorMessages([]);
      setPrimaryButtonText("Processing files...");

      // Only attempt upload if status is 'uploading'
      const filesToUpload = updatedFilesAndStatus
        .filter((fs) => fs.status === "uploading")
        .map((fs) => fs.file);

      const uploadResponses =
        filesToUpload.length > 0 ? (await uploadFiles(filesToUpload)).data : [];

      for (let [index, response] of uploadResponses.entries()) {
        // let index = updatedFilesAndStatus.findIndex(fs => fs.file.name === response.fileName);
        if (response.status === 200) {
          updatedFilesAndStatus[index].status = "complete";
          if (
            allowedContentTypesForTranscription.includes(
              response.fileContentType
            )
          ) {
            try {
              transcribeAudioFile(
                response.fileName,
                response.filePath,
                response.fileContentType
              );
            } catch (transcriptionError) {
              console.error(
                "Transcription failed for file",
                response.fileName,
                transcriptionError
              );
              try {
                await deleteFile(response.filePath);
              } catch (deleteError) {
                console.log(deleteError);
              }
              updatedFilesAndStatus[index].status = "edit";
              setUploadErrorMessages((prevErrorMessages) => [
                ...prevErrorMessages,
                `Could not upload file: ${response.fileName}`,
              ]);
            }
          }
        } else {
          console.log("File upload failed for ", response.fileName);
          updatedFilesAndStatus[index].status = "edit";
          setUploadErrorMessages((prevErrorMessages) => [
            ...prevErrorMessages,
            response.message,
          ]);
        }
      }
    } catch (error) {
      updatedFilesAndStatus = filesAndStatus.map((fs) => {
        fs.status = "edit";
        return fs;
      });
      console.error("Error during file upload process", error);
      setUploadError(true);
    } finally {
      setFilesAndStatus(updatedFilesAndStatus);
      const completedFilesAndStatus = updatedFilesAndStatus.filter(
        (fs) => fs.status === "complete"
      );
      if (completedFilesAndStatus.length === filesAndStatus.length) {
        refreshFiles();
        onClose();
        setPrimaryButtonText("Upload");
        setFilesAndStatus([]);
        setUploadErrorMessages([]);
      }
    }
  };

  const closeModal = () => {
    refreshFiles();
    onClose();
    setPrimaryButtonText("Upload");
    setFilesAndStatus([]);
    setUploadErrorMessages([]);
  };

  return (
    <div>
      <Modal
        open={isOpen}
        modalHeading={modalHeading}
        primaryButtonText={primaryButtonText}
        secondaryButtonText="Cancel"
        onRequestSubmit={submitUploadFiles}
        onRequestClose={closeModal}
        size="sm"
      >
        <span style={{ display: "block" }}>
          Accepted formats: {allowedFileTypes}
        </span>
        <span style={{ display: "block" }}>Maximum file size is 2GB.</span>
        <br></br>
        <div style={{ position: "relative" }}>
          <FileUploader
            uploadErrorMessages={uploadErrorMessages}
            filesAndStatus={filesAndStatus}
            updateFileUploaderStatus={updateFileUploaderStatus}
            maxFiles={100}
            uploadError={uploadError}
            allowAudioFiles={allowAudio}
            acceptTypes={allowedFileTypes}
          />
        </div>{" "}
      </Modal>
    </div>
  );
};

export default FileUploaderModal;

class Language {
    constructor(langId, label) {
        this.langId = langId;
        this.label = label;
    }
}

class Connection {
    constructor(sourceLabel, targetLabel, customModelId = null) {
        this.sourceLabel = sourceLabel;
        this.targetLabel = targetLabel;
        this.customModelId = customModelId;
    }
}

class LanguageMap {
    constructor() {
        this.languages = {}; // Store languages by label
        this.connections = []; // Store all connections
        this.intializeDefaultLanguageMap();

    }

    intializeDefaultLanguageMap() {
        const initialLanguages = [
            { langId: "ar", label: "Arabic" },
            { langId: "zh", label: "Chinese" },
            { langId: "en", label: "English" },
            { langId: "fr", label: "French" },
            { langId: "pt", label: "Portuguese" },
            { langId: "ru", label: "Russian" },
            { langId: "es", label: "Spanish" },
            { langId: "sw", label: "Swahili" },
        ];
        
        initialLanguages.forEach(lang => this.addLanguage(lang.langId, lang.label));
        
        // Establish connections excluding self-connections
        initialLanguages.forEach(source => {
            initialLanguages.forEach(target => {
                if (source.label !== target.label) {
                    this.addConnection(source.label, target.label);
                }
            });
        });
    }

    addLanguage(langId, label) {
        const newLanguage = new Language(langId, label);
        this.languages[label] = newLanguage;
    }

    addConnection(sourceLabel, targetLabel, customModelId = null) {
        if (!this.languages[sourceLabel] || !this.languages[targetLabel]) {
            console.log("One of the languages does not exist.");
            return;
        }
        const newConnection = new Connection(sourceLabel, targetLabel, customModelId);
        this.connections.push(newConnection);
    }

    getCustomModelId(sourceLanguage, targetLanguage) {
        const conn = this.connections.find(conn => conn.sourceLabel === sourceLanguage.label && conn.targetLabel === targetLanguage.label);
        return conn ? conn.customModelId : null;
    }

    getTargets(sourceLanguage) {
        return this.connections
            .filter(conn => conn.sourceLabel === sourceLanguage.label)
            .map(conn => ({ label: conn.targetLabel, langId: this.languages[conn.targetLabel].langId}));
    }

    getSources(targetLanguage) {
        return this.connections
            .filter(conn => conn.targetLabel === targetLanguage.label)
            .map(conn => ({ label: conn.sourceLabel, langId: this.languages[conn.sourceLabel].langId}));
    }

    getAllSources() {
        const unique = {};
        this.connections.forEach(conn => {
            const source = conn.sourceLabel;
            if (!unique[source]) {
                unique[source] = {label: source, langId: this.languages[source].langId};
            }
        });
        return Object.values(unique);
    }

    getAllTargets() {
        const unique = {};
        this.connections.forEach(conn => {
            const target = conn.targetLabel;
            if (!unique[target]) {
                unique[target] = {label: target, langId: this.languages[target].langId};
            }
        });
        return Object.values(unique);
    }

    connectionExists(sourceLanguage, targetLanguage) {
        if (!sourceLanguage || !targetLanguage) {
            return true;
        }
        return this.connections.some(conn => conn.sourceLabel === sourceLanguage.label && conn.targetLabel === targetLanguage.label);
    }

    getLanguageByLangId(langId) { 
        return Object.values(this.languages).find(lang => lang.langId === langId);
    }
}

const languageMap = new LanguageMap();

export default languageMap;


import React, { useState } from "react";
import { Accordion, AccordionItem, Button, Tile } from "@carbon/react";

const SearchResults = ({ data, addCitation }) => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveAccordionIndex(index);
  };

  const cite = (sourceName, sourceUrl) => {
    const source = {
      name: sourceName,
      url: sourceUrl,
    };
    addCitation(source);
  };

  const CompressionTile = ({ compression }) => (
    <Tile>
      <div style={{ marginReft: "2%" }}>
        <p
          style={{
            marginBottom: "10px",
            fontWeight: "600",
            fontSize: "var(--text-medium)",
          }}
        >
          Relevant excerpts:
        </p>
        {compression.map((item, index) => (
          <p
            key={index}
            style={{ marginBottom: "15px", fontSize: "var(--text-medium)" }}
          >
            {item}
          </p>
        ))}
      </div>
    </Tile>
  );

  return (
    <Accordion
      style={{
        height: "calc(100vh - 9rem - 3rem)",
        overflowY: "auto",
        paddingBottom: "10%",
      }}
    >
      {data.map((result, index) => (
        <AccordionItem
          key={index}
          open={index === activeAccordionIndex}
          title={result.title}
        >
          <div style={{ marginLeft: "2%", marginRight: "2%" }}>
            <p
              style={{
                fontSize: "var(--text-medium)",
                fontWeight: "400",
                marginTop: "2%",
              }}
            >
              URL:{" "}
              <a href={result.link} target="_blank" rel="noopener noreferrer">
                {result.link}
              </a>
            </p>
            <br></br>
            {result.compression !== null && (
              <div>
                <CompressionTile
                  compression={result.compression}
                  style={{ marginLeft: "2%" }}
                />
                <br></br>
              </div>
            )}
            <Button onClick={() => cite(result.title, result.link)}>
              Cite
            </Button>
            <br></br>
            <br></br>
          </div>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default SearchResults;

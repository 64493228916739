import React, { useState, useRef } from "react";
import { Close } from "@carbon/icons-react";
import {InlineLoading} from "@carbon/react"

const CustomTextArea = ({ value, currentSection, steps, summary }) => {
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const [summaryDrawer, setSummaryDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const textAreaRef = useRef(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    const selection = window.getSelection().toString();
    if (selection) {
      setContextMenu({
        visible: true,
        x: event.clientX,
        y: event.clientY,
      });
    }
  };

  // const handleMenuClick = (action) => {
  //   if (action === "summary") {
  //     setSummaryDrawer(true);
  //   }
  //   setContextMenu({ visible: false, x: 0, y: 0 });
  // };

  const closeDrawer = () => {
    setSummaryDrawer(false);
  };

  const handleMenuClick = (action) => {
    if (action === "summary") {
      setLoading(true);
      setSummaryDrawer(true);
      setTimeout(() => {
        setLoading(false);
      }, 3500);
    }
    setContextMenu({ visible: false, x: 0, y: 0 });
  };

  return (
    <>
      <textarea
        ref={textAreaRef}
        style={{
          width: "100%",
          height: "64.8vh",
          overflow: "auto",
          resize: "none",
          backgroundColor: "white",
          padding: "2rem",
          fontFamily: "Montserrat, IBM Plex Sans, sans-serif",
          fontSize: "0.875rem",
        }}
        value={steps[currentSection].content}
        onContextMenu={handleContextMenu}
      />
      {contextMenu.visible && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            top: contextMenu.y,
            left: contextMenu.x,
            zIndex: 1000,
            backgroundColor: "white",
            border: "1px solid #ccc",
            padding: "5px",
            borderRadius: "0.5rem",
            gap: "0.4rem",
          }}
        >
          <button onClick={() => handleMenuClick("summary")}>Summary</button>
          <div
            style={{
              height: "1px",
              backgroundColor: "#808080",
              margin: "0.1rem 0",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          ></div>
          <button onClick={() => handleMenuClick("comment")}>Comment</button>
        </div>
      )}
      {summaryDrawer && (
        <div
          style={{
            position: "fixed",
            top: 70,
            right: 0,
            width: "30vw",
            height: "70vh",
            backgroundColor: "#ececec",
            boxShadow: "-2px 0 5px rgba(0,0,0,0.1)",
            padding: "20px",
            zIndex: 1001,
          }}
        >
          <button onClick={closeDrawer} style={{ float: "right" }}>
            <Close />
          </button>
          <h4>Summary</h4>
          <div style={{ margin: "1rem 0", overflowY: "auto", height: "60vh" }}>
            {/* <p style={{ fontSize: "0.875rem" }}>{summary}</p> */}
            {loading ? (
              <div >
                <InlineLoading description="Summarizing selection..."/>
              </div>
            ) : (
              <p className="text-sm">{summary}</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomTextArea;


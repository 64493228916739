import React, { useState, useEffect } from "react";
import {
  TextInput,
  RadioButton,
  RadioButtonGroup,
  Button,
  InlineLoading,
  InlineNotification,
  ProgressBar,
} from "@carbon/react";
import { api, api_external } from "../../store";
import { CheckmarkFilled, CloseFilled } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import { convertArchiveTypes, supportedCMS } from "../../util";

const WordpressCMS = ({
  onCancel,
  setArchiveonfiguration,
  cmsName,
  apiPath,
}) => {
  const [selectedAuth, setSelectedAuth] = useState("");
  const [url, setUrl] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUrlVerified, setIsUrlVerified] = useState(false);
  const [urlError, setURLError] = useState("");
  const [isLoaderEnabled, setLoader] = useState(false);
  const [testConnectionSuccessMsg, displayTestConnectionMsg] = useState("");
  const [testConnectionErrorMsg, displayTestConnectionErrorMsg] = useState("");
  const [saveSuccessMsg, displaySaveMsg] = useState("");
  const [saveErrorMsg, displaySaveErrorMsg] = useState("");
  const [archiveId, setArchiveId] = useState("");
  const [posts, setPosts] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (url) {
      verifyUrl();
    }
  }, [url]);

  const verifyUrl = async () => {
    setIsLoading(true);
    try {
      const response = await api_external.get(url);
      if (response.statusText === "OK") {
        setIsUrlVerified(true);
        setURLError("");
      } else {
        setIsUrlVerified(false);
        setURLError(
          "URL not verified. Verify the URL or check if CORS is enabled"
        );
      }
    } catch (error) {
      console.log("Error: " + error);
      setURLError(
        "URL not verified. Verify the URL or check if CORS is enabled"
      );
      setIsUrlVerified(false);
    }
    setIsLoading(false);
  };

  const testConnection = async () => {
    try {
      setLoader(true);
      displayTestConnectionErrorMsg("");
      const base64Credentials = btoa(`${username}:${password}`);
      const response = await api_external.get(url + apiPath, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      setIsSaveEnabled(true);
      displayTestConnectionMsg(
        "Test Connection successful. Proceed to saving the configuration."
      );
    } catch (error) {
      console.log("Error: " + error);
      displayTestConnectionErrorMsg(
        "Test Connection failed. Incorrect username or password"
      );
    }
    setLoader(false);
  };

  const saveCredentials = async () => {
    try {
      setLoader(true);
      displaySaveErrorMsg("");
      const response = await api.post("/archives/createArchive", {
        archiveType: cmsName,
        archiveURL: url,
        archiveAuthentication: selectedAuth,
        archiveCredentials: { username, password },
      });
      setArchiveId(response.data.archiveId);
      displaySaveMsg(convertArchiveTypes(cmsName) + " is configured.");
      const base64Credentials = btoa(`${username}:${password}`);
      cmsName === "drupal"
        ? fetchAndProcessPostsBack(response.data.archiveId)
        : fetchAndProcessPosts(base64Credentials, response.data.archiveId);
    } catch (error) {
      console.log("Error: " + error);
      displaySaveErrorMsg("Saving archive failed. " + error.message);
    }
    setLoader(false);
  };

  function replaceSpacesWithUnderscores(str) {
    return str.replace(/ /g, "_");
  }

  const uploadPost = async (post, archiveid) => {
    try {
      let name = cmsName === "wordPress" ? post.slug : post.attributes.title;
      name = replaceSpacesWithUnderscores(name);
      const fileName = name + ".docx";
      const data =
        cmsName === "wordPress"
          ? post.content.rendered
          : post.attributes.body.processed;
      const response = await api.post(
        "file/uploadData",
        { data, fileName, owner: archiveid, tag: "archive" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.fileId;
    } catch (error) {
      console.log("Error uploading post: " + error);
      return null;
    }
  };

  async function embedFile(fileId, archiveid) {
    try {
      await api.post("ai/embed", {
        fileId,
        ownerId: archiveid,
      });
    } catch (err) {
      throw err;
    }
  }

  async function fetchAllWPPosts(
    base64Credentials,
    page = 1,
    perPage = 10,
    allPosts = []
  ) {
    try {
      const response = await api_external.get(url + "/wp-json/wp/v2/posts", {
        params: {
          page: page,
          per_page: perPage,
        },
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });

      // Combine the posts from this request with the ones already fetched
      allPosts = allPosts.concat(response.data);

      // Check if there's more posts to fetch based on response headers
      if (parseInt(response.headers["x-wp-totalpages"]) > page) {
        // Recursively fetch next page
        return await fetchAllWPPosts(
          base64Credentials,
          page + 1,
          perPage,
          allPosts
        );
      } else {
        return allPosts;
      }
    } catch (error) {
      console.error("Error fetching WP posts:", error);
      throw error;
    }
  }

  async function fetchAllDrupalPosts(link, base64Credentials, allPosts = []) {
    try {
      console.log(link);
      const response = await api_external.get(link, {
        params: {
          "page[limit]": 40,
        },
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });

      if (!response.status === 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const document = await response.data;

      // Combine the posts from this request with the ones already fetched
      allPosts.push(...document.data);

      // Check if there's a next page
      const hasNextPage = document.links && document.links.next;

      if (hasNextPage) {
        // Recursively fetch next page
        return await fetchAllDrupalPosts(
          document.links.next.href,
          base64Credentials,
          allPosts
        );
      } else {
        // If no more pages, return the accumulated posts
        return allPosts;
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
      throw error;
    }
  }

  const fetchAndProcessPostsBack = async (archiveId) => {
    try {
      setIsProcessing(true);
      const response = await api.post("/cms/configureDrupal", {
        archiveId,
        apiPath,
      });

      //set Archive configured
      setArchiveonfiguration(true);
      navigate("/ask-archives");
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    }
  };

  const fetchAndProcessPosts = async (base64Credentials, archiveid) => {
    setIsProcessing(true);
    try {
      let postsObj;
      if (cmsName === "wordPress") {
        postsObj = await fetchAllWPPosts(base64Credentials);
      } else if (cmsName === "drupal") {
        postsObj = await fetchAllDrupalPosts(url + apiPath, base64Credentials);
      }
      setPosts(postsObj);
      for (let post of postsObj) {
        // Upload post
        const fileId = await uploadPost(post, archiveid);
        // embed file
        try {
          await embedFile(fileId, archiveid);
        } catch (embedError) {
          console.log("Embed failed for ", fileId, embedError);
        }
        // Update progress
        const index = postsObj.indexOf(post) + 0.5;
        const progress = (index / postsObj.length) * 100;
        setProgress(parseFloat(progress.toFixed(1)));
      }
      //set Archive configured
      setArchiveonfiguration(true);
      navigate("/ask-archives");
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    }
  };

  return (
    <div
      style={{
        margin: "1.5rem 2rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          borderRadius: "var(--border-radius)",
        }}
      >
        {isProcessing ? (
          <div style={{ marginTop: "15px" }}>
            <ProgressBar
              value={progress}
              max={100}
              label={`Processing posts: ${progress}%`}
              hideLabel={false}
            />
          </div>
        ) : (
          <div>
            {saveSuccessMsg && (
              <div>
                <InlineNotification
                  lowContrast
                  kind="success"
                  subtitle={`${convertArchiveTypes(
                    cmsName
                  )} configuration saved successfully.`}
                  hideCloseButton={true}
                  timeout={5000}
                />
                <br></br>
              </div>
            )}
            <h4
              style={{ fontWeight: "500", marginTop: "0.7rem" }}
            >{`Configure ${convertArchiveTypes(cmsName)}`}</h4>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                marginTop: "2%",
              }}
            >
              <div style={{ width: "50%", padding: "1.3rem" }}>
                <h6 style={{ marginBottom: "5px" }}>URL:</h6>
                <TextInput
                  id="urlInput"
                  value={url}
                  onChange={(e) => {
                    setURLError("");
                    setUrl(e.target.value);
                  }}
                  placeholder="https://example.com"
                  helperText={`Specify the URL of the ${convertArchiveTypes(
                    cmsName
                  )} site`}
                  style={{ borderRadius: "var(--border-radius)" }}
                />
              </div>

              {isLoading && (
                <InlineLoading
                  style={{ marginLeft: "2%" }}
                  description="Verifying URL"
                />
              )}
              {urlError !== "" && (
                <span
                  style={{
                    marginLeft: "2%",
                    fontSize: "var(--text-medium)",
                    color: "red",
                  }}
                >
                  {urlError}
                </span>
              )}
              {isUrlVerified && (
                <CheckmarkFilled
                  style={{
                    width: "22px",
                    height: "22px",
                    fill: "green",
                    marginLeft: "2%",
                    marginTop: "1%",
                  }}
                />
              )}
            </div>

            <fieldset className="fieldset" style={{ margin: "1.3rem" }}>
              <h6 style={{ marginBottom: "5px" }}>Select Authentication:</h6>
              <RadioButtonGroup
                name="auth"
                valueSelected={selectedAuth}
                onChange={(value) => setSelectedAuth(value)}
                orientation="vertical"
                style={{ marginTop: "2%" }}
              >
                <RadioButton
                  id="basic"
                  value="basic"
                  labelText="Basic"
                  name="auth"
                />
                <RadioButton
                  id="apiKey"
                  value="apiKey"
                  labelText="API Key"
                  name="auth"
                />
                {/* <RadioButton id="jwt" value="jwt" labelText="JWT" name="auth" /> */}
              </RadioButtonGroup>
            </fieldset>

            {selectedAuth === "basic" && (
              <div style={{ marginBottom: "20px", width: "50%" }}>
                <TextInput
                  id="usernameInput"
                  labelText="Username:"
                  value={username}
                  onChange={(e) => {
                    displayTestConnectionMsg("");
                    setIsSaveEnabled(false);
                    setUsername(e.target.value);
                  }}
                  placeholder="Enter username..."
                  style={{ borderRadius: "var(--border-radius)" }}
                />
                <br></br>
                <TextInput.PasswordInput
                  id="passwordInput"
                  labelText="Password:"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    displayTestConnectionMsg("");
                    setIsSaveEnabled(false);
                    setPassword(e.target.value);
                  }}
                  placeholder="Enter password..."
                  style={{ borderRadius: "var(--border-radius)" }}
                />
              </div>
            )}
            {selectedAuth === "apiKey" && (
              <div style={{ marginBottom: "20px", width: "50%" }}>
                <TextInput
                  id="usernameInput"
                  labelText="Username:"
                  value={username}
                  onChange={(e) => {
                    displayTestConnectionMsg("");
                    setIsSaveEnabled(false);
                    setUsername(e.target.value);
                  }}
                  placeholder="Enter username..."
                  style={{ borderRadius: "var(--border-radius)" }}
                />
                <br></br>
                <TextInput.PasswordInput
                  id="passwordInput"
                  labelText="API key:"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    displayTestConnectionMsg("");
                    setIsSaveEnabled(false);
                    setPassword(e.target.value);
                  }}
                  placeholder="Enter API key..."
                  style={{ borderRadius: "var(--border-radius)" }}
                />
              </div>
            )}

            {!selectedAuth && (
              <Button
                kind="secondary"
                style={{ margin: "1.3rem" }}
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}

            {selectedAuth && (
              <div>
                {testConnectionSuccessMsg !== "" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "2%",
                    }}
                  >
                    <h6>{testConnectionSuccessMsg}</h6>
                    <CheckmarkFilled
                      style={{
                        width: "22px",
                        height: "22px",
                        fill: "green",
                        marginLeft: "2%",
                      }}
                    />
                  </div>
                )}
                {testConnectionErrorMsg !== "" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "2%",
                    }}
                  >
                    <h6>{testConnectionErrorMsg}</h6>
                    <CloseFilled
                      style={{
                        width: "22px",
                        height: "22px",
                        fill: "#db2121",
                        marginLeft: "2%",
                      }}
                    />
                  </div>
                )}
                {saveErrorMsg !== "" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "2%",
                    }}
                  >
                    <h6>{saveErrorMsg}</h6>
                    <CloseFilled
                      style={{
                        width: "22px",
                        height: "22px",
                        fill: "#db2121",
                        marginLeft: "2%",
                      }}
                    />
                  </div>
                )}
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isSaveEnabled ? (
                    <Button kind="primary" onClick={saveCredentials}>
                      Save
                    </Button>
                  ) : (
                    <Button
                      kind="primary"
                      onClick={testConnection}
                      disabled={!isUrlVerified || !username || !password}
                    >
                      Test Connection
                    </Button>
                  )}
                  <Button
                    kind="secondary"
                    style={{ marginLeft: "10px" }}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  {isLoaderEnabled && (
                    <InlineLoading style={{ marginLeft: "20px" }} />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WordpressCMS;

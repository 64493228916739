import React, { useState } from "react";
import { Button, Toggle, Tile } from "@carbon/react";
import "./StripePricing.scss";
import { api } from "../store";
import { CheckmarkFilled } from "@carbon/icons-react";
import { loadStripe } from "@stripe/stripe-js";

const PricingPage = ({ user }) => {
  const [isYearly, setIsYearly] = useState(false);

  const plans = [
    {
      title: "Basic",
      monthlyPrice: "$50/month",
      yearlyPrice: "$360/year",
      features: [
        "Article generation",
        "Pre-tuned research agents",
        "Pre-defined templates",
        "Text to speech",
        "Summaries",
        "Translations",
        "Transcriptions",
        "Comic strips",
      ],
      contact: false,
    },
    {
      title: "Premium",
      monthlyPrice: "$100/month per user",
      yearlyPrice: "$720/year per user",
      features: [
        "All features from Basic",
        "Custom research agents",
        "Custom templates",
        "CRM integration",
        "Ask Archives",
      ],
      contact: true,
    },
    {
      title: "Enterprise",
      monthlyPrice: "Custom pricing",
      yearlyPrice: "Custom pricing",
      features: [
        "All features from Premium",
        "RFP issuance",
        "RFP responses",
        "RFP evaluation",
        "RFI, RFQ, SOW",
        "Security questionnaires",
        "Quantized models",
        "Fine-tuned models",
        "Custom integrations",
        "API access",
        "Dedicated support",
      ],
      contact: true,
    },
  ];

  const stripePromise = loadStripe(
    "pk_live_51PGReLFkw0xrmeXJBE7cFMPF8Iks8zW2eVekElxa6SJqT1CWBLSE0Ks1KJFaye0Z8X61aWzpT49VthlPaC4DFz2000qOonlV42"
  );

  const handleContactUsClick = () => {
    window.open("https://www.xpub.ai/contact", "_blank");
  };

  const handleUpgrade = async () => {
    const data = { userId: user._id, monthlyBilling: !isYearly };
    const response = await api.post("/stripe/create-checkout-session", data);
    const sessionId = response.data.id;
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });
    if (error) {
      console.error("Error redirecting to checkout:", error);
    }
  };

  return (
    <div className="pricing-page">
      <h1>Plans & Pricing</h1>
      <div className="toggle-container">
        <Toggle
          id="pricing-toggle"
          labelA="Billed monthly"
          labelB="Billed yearly"
          toggled={isYearly}
          onToggle={() => setIsYearly(!isYearly)}
        />
      </div>
      <div className="plans-container">
        {plans.map((plan, index) => (
          <Tile className="plan-box" key={index}>
            <h2>{plan.title}</h2>
            <br />
            <p>{isYearly ? plan.yearlyPrice : plan.monthlyPrice}</p>
            {/* {isYearly && plan.title != "Enterprise" && <p>40% off</p>} */}
            <br />
            {plan.contact ? (
              <Button kind="secondary" onClick={handleContactUsClick}>
                Contact Us
              </Button>
            ) : (
              <Button kind="primary" onClick={handleUpgrade}>
                Upgrade
              </Button>
            )}
            <br />
            <br />
            <span>Includes:</span>
            <ul className="feature-list">
              {plan.features.map((feature, i) => (
                <li key={i}>
                  <CheckmarkFilled className="checkmark-icon" />
                  {feature}
                </li>
              ))}
            </ul>
          </Tile>
        ))}
      </div>
    </div>
  );
};

export default PricingPage;

import React, { useEffect, useState } from "react";
import { Modal, TextInput, Dropdown } from "@carbon/react";
import { api } from "../../store";
import { stripExtension } from "../../utils/Utils";

const SpeechModal = ({
  isOpen,
  onClose,
  // fileName,
  generateFileName,
  setTabLoading,
  tabIndex,
  setLoadingMsg,
  setShowMsg,
  onContentChange,
  projectId,
  setShowErrorMsg,
  setErrorMsg,
  listItems,
  refreshFiles,
}) => {
  // const [outputFileName, setOutputFileName] = useState(
  //   generateFileName(`${stripExtension(fileName)}_audio.mp3`)
  // );
  // const [filenameError, setFileNameError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const filesList = (listItems && listItems.length > 0 && listItems[0].items) ? listItems[0].items : [];


  // useEffect(() => {
  //   setOutputFileName(
  //     generateFileName(`${stripExtension(fileName)}_audio.mp3`)
  //   );
  // }, [fileName]);

  const textToSpeech = async function () {
    onModalClose();
    setShowErrorMsg(false);
    setTabLoading(true, tabIndex);
    setShowMsg(true);

    const fileName = selectedFile.name;
    const outputFileName = generateFileName(`${stripExtension(fileName)}_audio.mp3`);;
    
    onContentChange(outputFileName, "", tabIndex, false);
    setLoadingMsg("Hang tight! We're generating audio from the text.");
    try {
      let response = await api.post(
        "ai/createSpeech",
        {
          fileName: fileName,
          projectId: projectId,
          outputFileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status != 200) {
        throw new Error("Error  calling text to speech api");
      }

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      const fileId = response.data.fileId;
      const signedResponse = await api.get(`file/getSignedURL/${fileId}`);
      const url = signedResponse.data.url;
      // download file to local computer

      response = await fetch(url);
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = outputFileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(blobUrl);
      setShowMsg(false);
      setTabLoading(false, tabIndex);
      refreshFiles();
    } catch (error) {
      console.error("Error in speech:", error);
      setShowErrorMsg(true);
      setErrorMsg(
        error.message
          ? error.message
          : "Error in generating audio from text. Please contact support@xpub.ai."
      );
    }
  };

  const onModalClose = () => {
    // setOutputFileName("");
    onClose();
  };

  const handleFileSelectDropdownChange = ({ selectedItem }) => {
    setSelectedFile(selectedItem ? selectedItem : null);
  };

  return (
    <Modal
      open={isOpen}
      modalHeading="Generate speech from text"
      primaryButtonText={"Generate speech"}
      secondaryButtonText="Cancel"
      onRequestSubmit={textToSpeech}
      onRequestClose={onModalClose}
      size="sm"
    >
      <div style={{ marginTop: "1rem", marginBottom: "4rem" }}>
      <Dropdown
          id="file-dropdown"
          titleText="Select target file"
          label="Select file to generate speech from"
          items={filesList.filter(item => item.contentType !== ".mp3")}
          itemToString={(item) => (item ? item.name : "")}
          onChange={handleFileSelectDropdownChange}
          required
        />
      </div>
      
      {/* <div>
        <TextInput
          id="filename"
          labelText="File name"
          value={outputFileName}
          invalid={filenameError}
          className="text_input"
          onChange={(e) => {
            setFileNameError(false);
            setOutputFileName(e.target.value);
          }}
        />
      </div> */}
    </Modal>
  );
};

export default SpeechModal;

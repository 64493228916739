import React, { useState } from "react";
import { Button, TextInput } from "@carbon/react";
import "./KeywordInput.scss";
import { Add, Locked } from "@carbon/icons-react";

const PreferenceList = ({
  inputPlaceholder,
  addOnClick,
  listItems,
  listTitle,
  itemAction,
}) => {
  const [input, setInput] = useState("");

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (key === "Enter" && trimmedInput.length) {
      e.preventDefault();
      handleAddClick();
    }
  };

  const handleAddClick = () => {
    const trimmedInput = input.trim();
    if (trimmedInput) {
      addOnClick(trimmedInput);
      setInput(""); // Clear input after adding
    }
  };

  return (
    <div>
      <span
        style={{
          display: "block",
          fontSize: "var(--text-medium)",
          color: "black",
          fontWeight: "600",
          marginBottom: "1%",
        }}
      >
        {listTitle}
      </span>
      <div className="input-container">
        <TextInput
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          type="text"
          className="keyword-input"
          placeholder={inputPlaceholder}
          labelText=""
        />
        <Button
          className="keywords-buttons add-keywords-button"
          onClick={handleAddClick}
        >
          <Add />
        </Button>
      </div>
      <div
        style={{
          height: "46vh",
          overflowY: "auto",
          border: "0.1rem dashed var(--xpub-brand-color)",
          borderRadius: "var(--border-radius)",
          padding: "0.5rem 1rem",
        }}
      >
        {listItems.map((item, index) => (
          <div>
            <div
              key={index}
              className="tag-item"
              style={{
                justifyContent: "space-between",
                backgroundColor: "white",
              }}
            >
              <span className="text">{item}</span>
              <span className="close" onClick={() => itemAction(index)}>
                &times;
              </span>
            </div>
            <div
              style={{
                height: "1px",
                backgroundColor: "#cac9c9",
                marginBottom: "0.3rem",
                boxShadow: "1px 1px 1px ##cac9c9",
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreferenceList;

import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Column,
  DataTable,
  TableContainer,
  TableToolbar,
  TableBatchActions,
  TableBatchAction,
  TableSelectAll,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectRow,
  Table,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableHead,
  Pagination,
  Modal,
} from "@carbon/react";
import { TrashCan, ArrowLeft } from "@carbon/icons-react";
import FileUploader from "../Components/FileUploader";
import { api } from "../store";
import { useNavigate } from "react-router-dom";

const ArchiveFiles = (searchError) => {
  const navigate = useNavigate();
  const [rowData, setRows] = useState([]);
  const [isModalOpen, setModalState] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRow, setSelectedRow] = useState({ id: null });
  const [selectedRows, setSelectedRows] = useState([]);
  const [filesAndStatus, setFilesAndStatus] = useState([]);
  const [uploadErrorMessages, setUploadErrorMessages] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [userArchiveIDs, setUserArchiveIDs] = useState([]);
  const [isArchiveConfigured, setArchiveConfiguration] = useState(true);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [manualArchiveId, setManualArchiveId] = useState("");
  const [linkCLicked, setLinkClicked] = useState(false);

  const changePaginationState = (pageInfo) => {
    if (page !== pageInfo.page) {
      setPage(pageInfo.page);
    }
    if (pageSize !== pageInfo.pageSize) {
      setPageSize(pageInfo.pageSize);
    }
  };

  const headers = [
    { key: "name", header: "Name" },
    { key: "archiveType", header: "Type" },
  ];

  const getArchives = async (archiveIds) => {
    const idsString = archiveIds
      .map((item) => `${item.id}:${item.archiveType}`)
      .join(",");
    let response = await api.get(
      "/archives/getUserArchives?userArchivesId=" + idsString
    );
    const archives = response.data;
    setRows(archives);
  };

  const getUserArchivesId = async () => {
    const response = await api.get("/archives/getUserArchivesId");
    if (response.data.archives.length > 0) {
      setArchiveConfiguration(true);
      setUserArchiveIDs(response.data.archives);
      const manualArchive =
        response.data.archives.find(
          (archive) => archive.archiveType === "uploadFiles"
        ) || null;
      if (manualArchive !== null) {
        setShowUploadButton(true);
        setManualArchiveId(manualArchive.id);
      }
      getArchives(response.data.archives);
    } else {
      setArchiveConfiguration(false);
      setUserArchiveIDs([]);
    }
  };

  useEffect(() => {
    //get archive IDs of the user
    getUserArchivesId();
  }, []);

  async function deleteFile(fileId) {
    try {
      // Send delete request to the server
      const response = await api.delete("/file/deleteFile", {
        params: {
          fileId: fileId,
          ownerId: manualArchiveId,
        },
      });
      return response.data.message;
    } catch (err) {
      throw err;
    }
  }

  async function uploadFiles(files) {
    const formData = new FormData();

    files.forEach((file) => formData.append("files", file));
    formData.append("ownerId", manualArchiveId);
    formData.append("fileTag", "archive");
    try {
      const response = await api.post("file/uploadFiles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  }

  async function embedFile(fileId) {
    try {
      await api.post("ai/embed", {
        fileId,
        ownerId: manualArchiveId,
      });
    } catch (err) {
      throw err;
    }
  }

  const submitUploadFiles = async () => {
    let updatedFilesAndStatus = filesAndStatus.map((fs) =>
      fs.status === "edit" ? { ...fs, status: "uploading" } : fs
    );

    try {
      setFilesAndStatus(updatedFilesAndStatus);
      setUploadErrorMessages([]);

      // Only attempt upload if status is 'uploading'
      const filesToUpload = updatedFilesAndStatus
        .filter((fs) => fs.status === "uploading")
        .map((fs) => fs.file);

      const uploadResponses =
        filesToUpload.length > 0 ? (await uploadFiles(filesToUpload)).data : [];

      for (let [index, response] of uploadResponses.entries()) {
        // let index = updatedFilesAndStatus.findIndex(fs => fs.file.name === response.fileName);
        if (response.status === 200) {
          try {
            await embedFile(response.filePath);
            updatedFilesAndStatus[index].status = "complete";
          } catch (embedError) {
            console.log("Embed failed for ", response.fileName, embedError);
            try {
              await deleteFile(response.filePath);
            } catch (deleteError) {
              console.log(deleteError);
            }
            updatedFilesAndStatus[index].status = "edit";
            setUploadErrorMessages((prevErrorMessages) => [
              ...prevErrorMessages,
              `Could not upload file: ${response.fileName}`,
            ]);
          }
        } else {
          console.log("File upload failed for ", response.fileName);
          updatedFilesAndStatus[index].status = "edit";
          setUploadErrorMessages((prevErrorMessages) => [
            ...prevErrorMessages,
            response.message,
          ]);
        }
      }
    } catch (error) {
      updatedFilesAndStatus = filesAndStatus.map((fs) => {
        fs.status = "edit";
        return fs;
      });
      console.error("Error during file upload process", error);
      setUploadError(true);
    } finally {
      setFilesAndStatus(updatedFilesAndStatus);
      const completedFilesAndStatus = updatedFilesAndStatus.filter(
        (fs) => fs.status === "complete"
      );
      if (completedFilesAndStatus.length === filesAndStatus.length) {
        setModalState(false);
        setFilesAndStatus([]);
        setUploadErrorMessages([]);
      }
      getArchives(userArchiveIDs);
    }
  };

  function updateFileUploaderStatus(updatedFilesAndStatus) {
    setUploadErrorMessages([]);
    setFilesAndStatus(updatedFilesAndStatus);
    setUploadError(false);
  }

  const handleRowClick = (selRow) => {
    const updatedRows = rowData.map((row) => {
      if (row.id === selRow.id) {
        return { ...row, isSelected: !row.isSelected };
      } else {
        return row;
      }
    });
    setRows(updatedRows);

    // Update the selectedRows state based on whether the row was selected or deselected
    if (selRow.isSelected) {
      setSelectedRows(selectedRows.filter((row) => row.id !== selRow.id));
    } else {
      setSelectedRows([...selectedRows, selRow]);
    }
  };

  const handleArchiveFileDelete = async (event) => {
    event.preventDefault(); // Prevents the link from navigating to the URL
    for (let i = 0; i < selectedRows.length; i++) {
      const selRow = selectedRows[i];
      const record = rowData.find((item) => item.id === selRow.id);
      const archiveId = record.archiveId;
      // First delete the embedding from the Vector DB
      let response = await api.delete(
        "/archives/deleteFileVector?fileName=" + selRow.id
      );
      // Also delete the file from GCP
      if (response.status === 200) {
        let result = await api.delete("/file/deleteFile", {
          params: {
            fileId: selRow.id,
            ownerId: archiveId,
          },
        });
      }
    }

    //refresh the table
    setSelectedRows([]);
    getArchives(userArchiveIDs);
  };

  function getFileNameFromPath(path) {
    // Extracts the file name from the path
    if (path !== undefined) {
      const fileId = String(path).split("/").pop();
      const foundItem = rowData.find((item) => item.id === fileId);
      return foundItem ? foundItem.name : fileId;
    } else return path;
  }

  const handleArchiveFile = async (path) => {
    try {
      const fileId = String(path).split("/").pop();
      let foundItem = rowData.find((item) => item.id === fileId);
      if (foundItem === undefined)
        foundItem = rowData.find((item) => item.name === path);
      const response = await api.post("file/download", null, {
        params: {
          id: foundItem.id,
          ownerId: foundItem.archiveId,
        },
      });
      const contents = response.data.contents;
      var newTab = window.open();
      newTab.document.write(contents.replace(/\n/g, "<br>"));
      newTab.document.close();
    } catch (err) {
      console.log("Error:", err);
    }
  };

  const handleArchiveClick = async (event, file) => {
    event.stopPropagation();
    setLinkClicked(true);
    await handleArchiveFile(file);
  };

  return (
    <div style={{ margin: "1rem 2rem" }}>
      <button onClick={() => navigate("/ask-archives")}>
        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <ArrowLeft style={{ width: "0.8rem", height: "0.8rem" }} />
          <span style={{ fontSize: "var(--text-small)" }}>Back</span>
        </div>
      </button>
      <div className="page-header">
        <div className="page-title-container">
          <span className="page-title">Archive Files</span>
          {showUploadButton && (
            <Button
              onClick={(e) => {
                setModalState(true);
                e.stopPropagation();
              }}
              kind="primary"
              className="create-project-button"
            >
              Upload files
            </Button>
          )}
        </div>
      </div>
      <div style={{ margin: "0 auto auto 84%" }}>
        <Modal
          open={isModalOpen}
          modalHeading="Upload to Archives "
          primaryButtonText="Upload"
          secondaryButtonText="Cancel"
          onRequestSubmit={submitUploadFiles}
          onRequestClose={() => {
            setModalState(false);
          }}
          size="sm"
        >
          <span style={{ display: "block" }}>
            Accepted formats: .txt, .docx, .pdf
          </span>
          <span style={{ display: "block" }}>Maximum file size is 25MB.</span>
          <div style={{ position: "relative" }}>
            <FileUploader
              uploadErrorMessages={uploadErrorMessages}
              filesAndStatus={filesAndStatus}
              updateFileUploaderStatus={updateFileUploaderStatus}
              maxFiles={100}
              uploadError={uploadError}
              allowAudioFiles={false}
              acceptTypes={[".txt", ".docx", ".pdf"]}
            />
          </div>{" "}
          <br></br>
        </Modal>
      </div>
      <div style={{ padding: "0 1.3rem" }}>
        <DataTable
          rows={rowData}
          headers={headers}
          selectedRows={[selectedRow.id]}
          onRowClick={
            !linkCLicked
              ? () => handleRowClick(row)
              : () => setLinkClicked(false)
          }
        >
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getSelectionProps,
            getBatchActionProps,
            onInputChange,
            getTableProps,
          }) => (
            <TableContainer>
              <TableToolbar>
                <TableBatchActions
                  {...getBatchActionProps()}
                  shouldShowBatchActions={selectedRows.length > 0}
                  totalSelected={selectedRows.length}
                  onCancel={() => {
                    setSelectedRows([]);
                  }}
                >
                  <TableBatchAction
                    tabIndex={
                      getBatchActionProps().shouldShowBatchActions ? 0 : -1
                    }
                    renderIcon={TrashCan}
                    onClick={handleArchiveFileDelete}
                  >
                    Delete
                  </TableBatchAction>
                </TableBatchActions>
                <TableToolbarContent>
                  <TableToolbarSearch
                    tabIndex={
                      getBatchActionProps().shouldShowBatchActions ? -1 : 0
                    }
                    onChange={onInputChange}
                  />
                  {/* {showUploadButton && (
                    <Button
                      tabIndex={
                        getBatchActionProps().shouldShowBatchActions ? -1 : 0
                      }
                      onClick={(e) => {
                        setModalState(true);
                        e.stopPropagation();
                      }}
                      size="lg"
                      kind="primary"
                    >
                      Upload files
                    </Button>
                  )} */}
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <TableSelectAll />
                    {headers.map((header) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        key={header.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice((page - 1) * pageSize)
                    .slice(0, pageSize)
                    .map((row) => (
                      <TableRow
                        {...getRowProps({ row })}
                        onClick={() => handleRowClick(row)}
                        isSelected={selectedRows.some(
                          (selectedRow) => selectedRow.id === row.id
                        )}
                      >
                        <TableSelectRow
                          {...getSelectionProps({ row })}
                          checked={selectedRows.some(
                            (selectedRow) => selectedRow.id === row.id
                          )}
                          selected={row.isSelected}
                        ></TableSelectRow>
                        {row.cells.map((cell) => (
                          <TableCell key={cell.id}>
                            {cell.info.header === "name" ? (
                              <a
                                onClick={(event) =>
                                  handleArchiveClick(event, cell.value)
                                }
                              >
                                {cell.value.substring(
                                  cell.value.lastIndexOf("/") + 1
                                )}
                              </a>
                            ) : (
                              cell.value
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
        <Pagination
          onChange={changePaginationState}
          page={page}
          pageSize={pageSize}
          pageSizes={[10, 20, 50]}
          totalItems={rowData.length}
        />
      </div>
    </div>
  );
};

export default ArchiveFiles;

import React, { useEffect, useState, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import "./Editor.scss";
import QuillEditor from "../Components/QuillEditor";
import FactCheckWindow from "../Components/FactCheckWindow";
import { api } from "../store";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import {
  Grid,
  Column,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Theme,
} from "@carbon/react";
import { Add, Home, Close, Model, Time } from "@carbon/icons-react";
import { FileExtensionMimeTypes } from "../util";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import EditorMenu from "../Components/editor/EditorMenu";
import { useNavigate } from "react-router-dom";
import TourEditor from "../Components/TourEditor";

function Editor() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [listItems, setListItems] = useState([]);
  const [tabs, setTabs] = useState([
    {
      id: 1,
      name: "Untitled",
      content: "",
      isLoading: false,
      contentType: FileExtensionMimeTypes[".docx"],
      tag: [],
      originalContent: "",
    },
  ]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [style_prompt, setStylePrompt] = useState("");
  const [enableGen, setEnableGen] = useState(true);
  const [isSavingAnimation, setIsSavingAnimation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [transcriptionErrorMsg, setTranscriptionErrorMsg] = useState("");
  const [factCheckMode, setFactCheckMode] = useState(false);
  const [selectedText, setSelectedText] = useState({});
  const [doFactCheck, setFactCheck] = useState(false);
  const quillRef = useRef(null);
  const projectId = state.projectId;
  const projectName = state.projectName;
  const user = state.user;
  const [feedbackMode, setFeedbackMode] = useState(false);
  const searchPluginInstance = searchPlugin();
  const { ShowSearchPopover } = searchPluginInstance;
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const [transcriptionNotification, setTranscriptionNotification] =
    useState("");
  const [threshold, setTranscriptionThreshold] = useState(
    user ? user.transcription_threshold : 0.99
  );
  const [threshold_status, setTranscriptionThresholdStatus] = useState(
    user ? user.transcription_threshold_status : false
  );

  const [showLoadingMsg, setShowLoadingMsg] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [missedKeywords, setMissedKeywords] = useState([]);

  const generateFileNameWithPrefix = (prefix) => {
    let [base, extension] = prefix.split(".");
    extension = extension ? "." + extension : "";

    for (let counter = 1; getExistingNames().includes(prefix); counter++) {
      prefix = `${base} (${counter})${extension}`;
    }
    return prefix;
  };

  const addTab = (
    fileName,
    fileContent,
    generatePrefix = true,
    fileContentType = "text/plain",
    tag = [],
    originalContent = ""
  ) => {
    // Generate a unique tab name
    let uniqueName = generatePrefix
      ? generateFileNameWithPrefix(fileName)
      : fileName;

    if (
      tabs.length === 1 &&
      tabs[0].id === 1 &&
      tabs[0].name === "Untitled" &&
      tabs[0].content === ""
    ) {
      const newTab = {
        id: Date.now(),
        name: fileName,
        content: fileContent.replace(/\n/g, "<br>"),
        isLoading: false,
        contentType: fileContentType,
        tag: tag,
        originalContent: originalContent,
      };
      setTabs([newTab]);
      return;
    }

    const existingTab = tabs.find((tab) => tab.name === uniqueName);
    if (existingTab) {
      setActiveTabIndex(tabs.indexOf(existingTab));
      return;
    }

    const newTab = {
      id: Date.now(),
      name: uniqueName,
      content: fileContent.replace(/\n/g, "<br>"),
      isLoading: false,
      contentType: fileContentType,
      tag: tag,
      originalContent: originalContent,
    };

    setTabs([...tabs, newTab]);
    setActiveTabIndex(tabs.length);
  };

  const closeTab = (id) => {
    const closedTabIndex = tabs.findIndex((tab) => tab.id === id);
    const updatedTabs = tabs.filter((tab) => tab.id !== id);
    let newActiveTabIndex = activeTabIndex;

    if (closedTabIndex === activeTabIndex) {
      if (activeTabIndex > 0) {
        newActiveTabIndex = activeTabIndex - 1;
      } else if (updatedTabs.length > 0) {
        newActiveTabIndex = 0;
      }
    } else if (closedTabIndex < activeTabIndex) {
      newActiveTabIndex = activeTabIndex - 1;
    }
    if (updatedTabs.length === 0) {
      const newTab = {
        id: Date.now(),
        name: "Untitled",
        content: "",
        isLoading: false,
        tag: [],
        originalContent: "",
      };
      updatedTabs[0] = newTab;
      newActiveTabIndex = 0;
    }
    setTabs(updatedTabs);
    setActiveTabIndex(newActiveTabIndex);
  };

  const handleTabChange = async (
    name,
    value,
    tabIndex,
    appendVal,
    originalContent = "",
    tags = null
  ) => {
    const updatedTabs = [...tabs];
    updatedTabs[tabIndex].name = name;
    if (appendVal) updatedTabs[tabIndex].content += value;
    else updatedTabs[tabIndex].content = value;
    if (tags != null) updatedTabs[tabIndex].tag = tags;
    updatedTabs[tabIndex].originalContent = originalContent;
    setTabs(updatedTabs);
  };

  const reRenderTranscription = async (
    name,
    tabIndex,
    originalContent,
    threshold_val,
    threshold_status,
    audioHighlight = false
  ) => {
    let fileContent;
    if (audioHighlight) {
      fileContent = await processTranscriptForAudio(
        originalContent,
        threshold_val,
        threshold_status
      );
    } else
      fileContent = await processTranscript(
        originalContent,
        threshold_val,
        threshold_status
      );
    handleTabChange(name, fileContent, tabIndex, false, originalContent, null);
  };

  const handleTabClick = (index) => {
    if (!tabs.some((tab) => tab.isLoading)) {
      setActiveTabIndex(index);
    }
  };

  const handleAddTab = () => {
    if (!tabs.some((tab) => tab.isLoading)) {
      addTab("Untitled", "");
    }
  };

  const CustomTab = ({ fileName, onClose, isActive, isLoading }) => {
    const handleCloseClick = (event) => {
      // Stop event propagation to prevent calling handleTabClick
      event.stopPropagation();
      onClose();
    };
    return (
      <div
        className={`custom-tab ${isActive ? "active" : ""}`}
        title={fileName}
      >
        {/* <span className={isActive ? "active-tab-label" : "tab-label"}> */}
        <div className="tab-name">
          {" "}
          <span className="tab-label">{fileName}</span>
          {isLoading && (
            <div className="loading-indicator">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
            </div>
          )}
        </div>

        <button className="close-button" onClick={handleCloseClick}>
          <Close />
        </button>
      </div>
    );
  };

  const handleSave = async (
    content,
    fileName,
    fileType,
    tags,
    originalContent,
    displaySaveInHeader = false
  ) => {
    let file;
    try {
      if (fileType === FileExtensionMimeTypes[".docx"]) {
        try {
            const { data } = await api.post(
              "file/convertToDocx",
              { html: content, fileName },
              {
                responseType: "blob",
              }
            );
        
            file = new File([data], fileName, { type: fileType });
          } catch (error) {
            throw new Error(`An error occurred while converting the file to .docx: ${error.message}`);
        }
      } else if (fileType === FileExtensionMimeTypes[".pdf"]) {
        return; //do nothing since pdfs cannot be changed
      } else if (tags.includes("transcription")) {
        const data = originalContent;
        file = new File([data], fileName, { type: fileType });
      } else {
        if (fileName.startsWith("Untitled")) {
          fileName += ".docx";
        }
        const blob = new Blob([content], { type: fileType });
        file = new File([blob], fileName, {
          lastModified: Date.now(),
          type: fileType,
        });
      }
 
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data?.message);
      setShowErrorMsg(true)
      setErrorMsg("Failed to save document: please contact xPub using intercom on the bottom right of the screen.")
      return
    }
    
    // pass this to the API
    var formData = new FormData();
    formData.append("files", file);
    formData.append("ownerId", projectId);
    formData.append("replace", "true");
    formData.append("fileTag", tags);
    try {
      api
        .post("file/uploadFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          fetchInputOutputFiles();
          if (displaySaveInHeader) {
            setIsSaving(true);
            setTimeout(() => {
              setIsSaving(false);
            }, 1000);
          }
        });
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data?.message);
    }
  };

  //Fetch all input and output files
  async function fetchInputOutputFiles() {
    try {
      const fileList = await api.get("file/getFiles", {
        params: { projectId: projectId, isGUI: true },
        headers: { "Cache-Control": "no-cache" },
      });
      // Replace with user selection in future versions
      // const userFiles = fileList.data
      //     .filter((file) => !projectUIFileFormatsToFilter.some(format => file.name.includes(format)));
      setListItems([
        {
          title: "",
          items: fileList.data,
        },
      ]);
      return fileList.data;
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data?.message);
    }
  }

  async function getFileContent(fileName) {
    try {
      const response = await api.post("file/download", null, {
        params: {
          name: fileName,
          ownerId: projectId,
        },
      });
      return response.data.contents;
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data?.message);
    }
  }

  useEffect(() => {
    fetchFiles();
    getUserStylePrompt();

    async function getUserStylePrompt() {
      try {
        const response = await api.get("/user/style");
        setStylePrompt(response.data.stylePrompt);
      } catch (err) {
        console.log(err);
      }
    }

    async function fetchFiles() {
      const projectFiles = await fetchInputOutputFiles();

      //if we are coming from the dashboard page where the article has been generated already
      if (state.pregenerated === true) {
        try {
          //setLoading(true);
          // open files that are not media files
          const fileToOpen = projectFiles.find(
            (file) =>
              file.contentType !== ".mp3" &&
              file.contentType !== "application/pdf"
          );
          if (fileToOpen) {
            api
              .post("file/download", null, {
                params: {
                  name: fileToOpen.name,
                  ownerId: projectId,
                },
              })
              .then(({ data }) => {
                handleFileDownload(
                  fileToOpen.name,
                  data.contents,
                  data.fileType,
                  fileToOpen.tag,
                  user.transcription_threshold,
                  user.transcription_threshold_status
                );
              })
              .catch((error) => {
                console.log("Error occurred:", error);
                // Handle the error here or set an error state for displaying on the UI.
              });
          }
        } catch (err) {
          console.log(err);
          console.log(err?.response?.data?.message);
        }
        // setLoading(false);
        return;
      }
    }
  }, []);

  function processTranscriptForAudio(content, threshold, status) {
    const lines = content.split("\n");
    // Process each line (speaker).
    const processedLines = lines.map((line) => {
      // Use regex to find words and their confidence scores, including following space.
      const wordPattern =
        /(\S+)\{"metadata":\{"confidence":"([0-9.]*)","start":"([0-9.]*)","end":"([0-9.]*)"\}\}(\s?)/g;
      let processedLine = line.replace(
        wordPattern,
        (match, word, confidence, start, end, followingSpace) => {
          const startVal = parseFloat(start);
          const confidenceValue = parseFloat(confidence);
          if (startVal >= 0.0) {
            if (status && confidenceValue < threshold)
              return `<span class="audioWord" style="background-color: #aaa9a9"  data-start-value=${startVal}>${word}</span>${followingSpace}`;
            else
              return `<span class="audioWord" data-start-value=${startVal}>${word}</span>${followingSpace}`;
          } else {
            // Include the word and its following space
            return `${word}${followingSpace}`;
          }
        }
      );
      if (processedLine.trim() === "") return `<p><br></p>`;
      return `<p>${processedLine}</p>`;
    });

    // Join the processed lines.
    const processedContent = processedLines.join("");
    return processedContent;
  }

  function processTranscript(content, threshold, status) {
    // Split the content into lines (assuming each line is a speaker).
    const lines = content.split("\n");

    // Process each line (speaker).
    const processedLines = lines.map((line) => {
      // Use regex to find words and their confidence scores, including following space.
      const wordPattern =
        /(\S+)\{"metadata":\{"confidence":"([0-9.]*)","start":"([0-9.]*)","end":"([0-9.]*)"\}\}(\s?)/g;
      let processedLine = line.replace(
        wordPattern,
        (match, word, confidence, start, end, followingSpace) => {
          const metaObject = {
            metadata: { confidence: confidence, start: start, end: end },
          };
          const confidenceValue = parseFloat(confidence);

          if (status && confidenceValue < threshold) {
            // Wrap word and its following space in a span if confidence is low
            return `<span style="background-color: #d8d2e0">${word}</span>${followingSpace}`;
          } else {
            // Include the word and its following space
            return `${word}${followingSpace}`;
          }
        }
      );
      if (processedLine.trim() === "") return `<p><br></p>`;
      return `<p>${processedLine}</p>`;
    });

    // Join the processed lines.
    const processedContent = processedLines.join("");
    return processedContent;
  }

  const handleFileDownload = async (
    fileName,
    fileContent,
    fileType,
    tag = [],
    wordThreshold = threshold,
    thresholdStatus = threshold_status
  ) => {
    const isAnyTabLoading = tabs.some((tab) => tab.isLoading);
    let originalContent = "";
    if (tag.includes("transcription")) {
      originalContent = fileContent;
      fileContent = await processTranscript(
        fileContent,
        wordThreshold,
        thresholdStatus
      );
    }
    if (!isAnyTabLoading) {
      addTab(fileName, fileContent, false, fileType, tag, originalContent);
    }
  };

  const handleTabLoadingStatus = (val, tabIndex) => {
    const updatedTabs = [...tabs];
    updatedTabs[tabIndex].isLoading = val;
    setTabs(updatedTabs);
  };

  const getExistingNames = () => {
    const tabNames = tabs.map((tab) => tab.name);
    if (listItems.length <= 0) return [];
    const items = listItems[0].items;
    const listItemNames = items.reduce((names, listItem) => {
      return names.concat(listItem.name);
    }, []);
    const uniqueNames = Array.from(new Set([...tabNames, ...listItemNames]));
    return uniqueNames;
  };

  const toggleFactCheckMode = (val) => {
    setFactCheckMode(val);
    const quill = quillRef.current.getEditor();
  };

  const toggleFeedbackMode = (val) => {
    setFeedbackMode(val);
  };

  const getSelectedTextDetails = (textDetails) => {
    setSelectedText(textDetails);
  };

  const addCitation = (source) => {
    const quill = quillRef.current.getEditor();
    const selectedTxtObj = JSON.parse(selectedText);
    const endIndex = selectedTxtObj.endIndex;
    const { name, url } = source;
    const editorContent = quill.root.innerHTML;
    const citationRegex =
      /<a href="[^"]*" rel="noopener noreferrer" target="_blank"><sup>\[(\d+)\]<\/sup><\/a>/g;
    let maxNumber = 0;
    let match;

    while ((match = citationRegex.exec(editorContent)) !== null) {
      const currentNumber = parseInt(match[1], 10);
      if (currentNumber > maxNumber) {
        maxNumber = currentNumber;
      }
    }

    // Calculate the number for the new citation
    const newCitationNumber = maxNumber + 1;

    const htmlContent = `<a className='xpub_ref url_ref' href='${url}' rel='noopener noreferrer' target='_blank'><sup>[${newCitationNumber}]</sup></a>`;

    quill.clipboard.dangerouslyPasteHTML(endIndex, htmlContent);

    const content = quill.root.innerHTML;
    handleTabChange(tabs[activeTabIndex].name, content, activeTabIndex, false);

    handleSave(
      content,
      tabs[activeTabIndex].name,
      tabs[activeTabIndex].contentType,
      tabs[activeTabIndex].tag
    );
  };

  const enableFactCheck = (val) => {
    setFactCheck(val);
  };

  const displayTranscriptionNotification = (fileName) => {
    setTranscriptionNotification(fileName);
  };

  const updateTabName = (oldName, newName) => {
    // Check if the new name already exists to avoid duplicates
    if (tabs.some((tab) => tab.name === newName)) {
      console.error("A tab with the new name already exists.");
      return; // Optionally, you could handle renaming differently or notify the user
    }

    const updatedTabs = tabs.map((tab) => {
      if (tab.name === oldName) {
        // Found the tab with the old name, update its name
        return { ...tab, name: newName };
      }
      return tab;
    });

    setTabs(updatedTabs);
    // You may want to update the active tab index if the name of the active tab was changed
    const updatedActiveTab = updatedTabs.find((tab) => tab.name === newName);
    if (updatedActiveTab) {
      setActiveTabIndex(updatedTabs.indexOf(updatedActiveTab));
    }
  };

  const setOutputInEditor = (
    content,
    tag,
    filename,
    filetype,
    simulateHTML,
    tabIndex
  ) => {
    if (simulateHTML) simulateHTMLStream(content, filename, tabIndex);
  };

  const simulateHTMLStream = async function (
    text,
    fileName,
    tabIndex,
    replaceNewline = false
  ) {
    const words = text.split(" ");
    let index = 0;

    const processNextWord = () => {
      if (index < words.length) {
        let chunkSize = 10; // Number of words to process at once
        let chunk = words.slice(index, index + chunkSize).join(" ");
        let formattedChunk = replaceNewline
          ? chunk.replace(/\n/g, "<br>")
          : chunk;

        // Ensure space after each chunk, but avoid adding extra space at the end
        if (index + chunkSize < words.length) {
          formattedChunk += " ";
        }

        handleTabChange(fileName, formattedChunk, tabIndex, true);
        index += chunkSize;

        requestAnimationFrame(processNextWord);
      }
    };

    processNextWord();
  };

  const exportDocument = async () => {
    try {
      const fileName = tabs[activeTabIndex].name;
      const file = listItems[0].items.find((file) => file.name === fileName);
      const fileId = file ? file.id : null;
      if (fileId) {
        const signedResponse = await api.get(`file/getSignedURL/${fileId}`);
        const url = signedResponse.data.url;
        // download file to local computer

        let response = await fetch(url);
        if (!response.ok) throw new Error(`Error: ${response.statusText}`);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = blobUrl;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main-div">
      <div className="editor-header">
        <div className="editor-header-left">
          <Home
            className="home-button"
            onClick={() => navigate("/dashboard")}
          />
          <p className="editor-title">
            {projectName && projectName.toUpperCase()}
          </p>
        </div>
        {user.status === "active" ? (
          <div className="credits">
            <Model className="credit-icon" />
            <span className="credits-value">{user.plan}</span>
          </div>
        ) : (
          <div
            className="credits"
            onClick={() => navigate("/settings?tab=billing")}
          >
            <Time className="credit-icon" />
            <span className="credits-value">
              Trial period ends on{" "}
              {user.trial_end &&
                format(new Date(user.trial_end), "MMMM dd, yyyy")}
            </span>
          </div>
        )}
      </div>

      {factCheckMode && !feedbackMode && (
        <div>
          <Grid fullWidth noGutter className="no-padding fact-check-grid">
            <Column lg={9} md={5} sm={4} className="no-padding">
              {tabs.map(
                (tab, index) =>
                  index === activeTabIndex && (
                    <QuillEditor
                      value={tab.content}
                      onContentChange={(
                        name,
                        value,
                        index,
                        appendVal,
                        originalContent
                      ) =>
                        handleTabChange(
                          name,
                          value,
                          index,
                          appendVal,
                          originalContent
                        )
                      }
                      projectId={projectId}
                      stylePrompt={style_prompt}
                      tabName={tab.name}
                      tabIndex={index}
                      tabFileType={tab.contentType}
                      tabLoading={tab.isLoading}
                      onTabLoading={handleTabLoadingStatus}
                      enableGen={enableGen}
                      saveFile={handleSave}
                      isSaving={isSaving}
                      generateFileName={generateFileNameWithPrefix}
                      transcriptionError={transcriptionErrorMsg}
                      refreshFiles={fetchInputOutputFiles}
                      toggleFactCheckMode={toggleFactCheckMode}
                      toggleFeedbackMode={toggleFeedbackMode}
                      setSelectedText={getSelectedTextDetails}
                      quillRef={quillRef}
                      factCheckMode={factCheckMode}
                      feedbackMode={feedbackMode}
                      setFactCheck={enableFactCheck}
                      showTranscriptionMsg=""
                      user={user}
                      tabTag={tab.tag}
                      originalContent={tab.originalContent}
                      addTab={addTab}
                      thresholdStatus={threshold_status}
                      thresholdValue={threshold}
                      updateThreshold={(val) => setTranscriptionThreshold(val)}
                      updateThresholdStatus={(val) =>
                        setTranscriptionThresholdStatus(val)
                      }
                      reRenderTranscription={(
                        name,
                        tabIndex,
                        originalContent,
                        threshold_val,
                        threshold_status,
                        audioHighlight
                      ) =>
                        reRenderTranscription(
                          name,
                          tabIndex,
                          originalContent,
                          threshold_val,
                          threshold_status,
                          audioHighlight
                        )
                      }
                      showLoadingMsg={showLoadingMsg}
                      loadingMsg={loadingMsg}
                      showErrorMsg={showErrorMsg}
                      errorMsg={errorMsg}
                      onExportDocument={exportDocument}
                    />
                  )
              )}
            </Column>
            <Column
              lg={7}
              md={3}
              sm={4}
              style={{ height: "calc(100vh - 7rem)!important" }}
              className="no-padding"
            >
              <FactCheckWindow
                projectId={projectId}
                factCheck={doFactCheck}
                setFactCheck={enableFactCheck}
                userText={selectedText}
                addCitation={addCitation}
                toggleFactCheckMode={toggleFactCheckMode}
                fileName={tabs[activeTabIndex].name}
              />
            </Column>
          </Grid>
        </div>
      )}
      {!factCheckMode && !feedbackMode && (
        <Grid className="no-padding editor-background">
          <Column lg={3} md={2} sm={1} className="left-column no-padding">
            <EditorMenu
              listItems={listItems}
              projectId={projectId}
              onFileDownload={handleFileDownload}
              refreshFiles={fetchInputOutputFiles}
              sendNotification={displayTranscriptionNotification}
              updateFileName={updateTabName}
              addTab={addTab}
              quillRef={quillRef}
              saveFile={handleSave}
              tabName={tabs[activeTabIndex].name}
              tabIndex={activeTabIndex}
              generateFileName={generateFileNameWithPrefix}
              setTabLoading={handleTabLoadingStatus}
              setShowMsg={(val) => setShowLoadingMsg(val)}
              setLoadingMsg={(msg) => setLoadingMsg(msg)}
              setShowErrorMsg={(val) => setShowErrorMsg(val)}
              setErrorMsg={(msg) => setErrorMsg(msg)}
              onContentChange={(
                name,
                value,
                index,
                appendVal,
                originalContent,
                tags
              ) =>
                handleTabChange(
                  name,
                  value,
                  index,
                  appendVal,
                  originalContent,
                  tags
                )
              }
              setOutput={(
                content,
                tag,
                filename,
                filetype,
                simulateHTML,
                tabIndex
              ) =>
                setOutputInEditor(
                  content,
                  tag,
                  filename,
                  filetype,
                  simulateHTML,
                  tabIndex
                )
              }
              setMissedKeywords={(keywords) => setMissedKeywords(keywords)}
              getFileContent={getFileContent}
            />
          </Column>
          <Column
            lg={12}
            md={6}
            sm={3}
            className="right-column flex-vertical-container"
          >
            <div>
              <Theme theme="g10" className="fill-flex">
                <Tabs selectedIndex={activeTabIndex}>
                  <div className="tablist-container">
                    <div className="tabs-wrapper">
                      <div className="tab-list">
                        <TabList aria-label="Tab navigation">
                          {tabs.map((tab, index) => (
                            <Tab
                              key={tab.id}
                              className="tab"
                              onClick={() => handleTabClick(index)}
                              disabled={tab.isLoading}
                            >
                              <CustomTab
                                onClose={() => closeTab(tab.id)}
                                fileName={tab.name}
                                isLoading={tab.isLoading}
                                isActive={index === activeTabIndex}
                              >
                                {tab.name}
                              </CustomTab>
                            </Tab>
                          ))}
                        </TabList>
                      </div>

                      <div className="add-tab-container">
                        <button
                          className="add-tab-button"
                          onClick={handleAddTab}
                          disabled={tabs.some((tab) => tab.isLoading)}
                        >
                          <Add />
                        </button>
                      </div>
                    </div>
                  </div>
                  <TabPanels>
                    {tabs.map((tab, index) => (
                      <TabPanel key={tab.id} className="tab-panel">
                        {index === activeTabIndex &&
                          tab.contentType !==
                            FileExtensionMimeTypes[".pdf"] && (
                            <div style={{ backgroundColor: "white" }}>
                              <div
                              // style={{
                              //   marginLeft: "5rem",
                              //   marginRight: "5rem",
                              //   backgroundColor: "white",
                              // }}
                              >
                                <QuillEditor
                                  value={tab.content}
                                  onContentChange={(
                                    name,
                                    value,
                                    index,
                                    appendVal,
                                    originalContent,
                                    tags
                                  ) =>
                                    handleTabChange(
                                      name,
                                      value,
                                      index,
                                      appendVal,
                                      originalContent,
                                      tags
                                    )
                                  }
                                  projectId={projectId}
                                  stylePrompt={style_prompt}
                                  tabName={tab.name}
                                  tabIndex={index}
                                  tabTag={tab.tag}
                                  originalContent={tab.originalContent}
                                  tabFileType={tab.contentType}
                                  tabLoading={tab.isLoading}
                                  onTabLoading={handleTabLoadingStatus}
                                  enableGen={enableGen}
                                  saveFile={handleSave}
                                  isSaving={isSaving}
                                  generateFileName={generateFileNameWithPrefix}
                                  transcriptionError={transcriptionErrorMsg}
                                  refreshFiles={fetchInputOutputFiles}
                                  toggleFactCheckMode={toggleFactCheckMode}
                                  toggleFeedbackMode={toggleFeedbackMode}
                                  setSelectedText={getSelectedTextDetails}
                                  quillRef={quillRef}
                                  factCheckMode={factCheckMode}
                                  feedbackMode={feedbackMode}
                                  setFactCheck={enableFactCheck}
                                  addTab={addTab}
                                  showTranscriptionMsg={
                                    transcriptionNotification
                                  }
                                  user={user}
                                  thresholdStatus={threshold_status}
                                  thresholdValue={threshold}
                                  updateThreshold={(val) =>
                                    setTranscriptionThreshold(val)
                                  }
                                  updateThresholdStatus={(val) =>
                                    setTranscriptionThresholdStatus(val)
                                  }
                                  reRenderTranscription={(
                                    name,
                                    tabIndex,
                                    originalContent,
                                    threshold_val,
                                    threshold_status,
                                    audioHighlight
                                  ) =>
                                    reRenderTranscription(
                                      name,
                                      tabIndex,
                                      originalContent,
                                      threshold_val,
                                      threshold_status,
                                      audioHighlight
                                    )
                                  }
                                  showLoadingMsg={showLoadingMsg}
                                  loadingMsg={loadingMsg}
                                  showErrorMsg={showErrorMsg}
                                  errorMsg={errorMsg}
                                  onExportDocument={exportDocument}
                                  missedKeywords={missedKeywords}
                                />
                              </div>
                            </div>
                          )}
                        {index === activeTabIndex &&
                          tab.contentType ===
                            FileExtensionMimeTypes[".pdf"] && (
                            <div style={{ backgroundColor: "lightgrey" }}>
                              <div
                                style={{
                                  marginLeft: "10%",
                                  marginRight: "10%",
                                  backgroundColor: "white",
                                  height: "calc(100vh - 7rem)",
                                }}
                              >
                                <Toolbar>
                                  {(toolbarSlot) => {
                                    const {
                                      CurrentPageInput,
                                      GoToNextPage,
                                      GoToPreviousPage,
                                      NumberOfPages,
                                    } = toolbarSlot;
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <ShowSearchPopover />
                                          <GoToPreviousPage />
                                          <CurrentPageInput />
                                          <div style={{ marginTop: "4%" }}>
                                            / <NumberOfPages />
                                          </div>
                                          <GoToNextPage />
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Toolbar>
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                  <Viewer
                                    plugins={[
                                      toolbarPluginInstance,
                                      searchPluginInstance,
                                    ]}
                                    fileUrl={tab.content}
                                  />
                                </Worker>
                              </div>
                            </div>
                          )}
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              </Theme>
            </div>
          </Column>
        </Grid>
      )}
      {/* {!factCheckMode && feedbackMode && (
        <div>
          <Grid fullWidth noGutter className="no-padding fact-check-grid">
            <Column lg={9} md={5} sm={4} className="no-padding">
              {tabs.map(
                (tab, index) =>
                  index === activeTabIndex && (
                    <QuillEditor
                      value={tab.content}
                      onContentChange={(
                        name,
                        value,
                        index,
                        appendVal,
                        originalContent
                      ) =>
                        handleTabChange(
                          name,
                          value,
                          index,
                          appendVal,
                          originalContent
                        )
                      }
                      projectId={projectId}
                      stylePrompt={style_prompt}
                      tabName={tab.name}
                      tabIndex={index}
                      tabTag={tab.tag}
                      originalContent={tab.originalContent}
                      tabFileType={tab.contentType}
                      tabLoading={tab.isLoading}
                      onTabLoading={handleTabLoadingStatus}
                      enableGen={enableGen}
                      saveFile={handleSave}
                      isSaving={isSaving}
                      generateFileName={generateFileNameWithPrefix}
                      transcriptionError={transcriptionErrorMsg}
                      refreshFiles={fetchInputOutputFiles}
                      toggleFactCheckMode={toggleFactCheckMode}
                      toggleFeedbackMode={toggleFeedbackMode}
                      setSelectedText={getSelectedTextDetails}
                      quillRef={quillRef}
                      factCheckMode={factCheckMode}
                      feedbackMode={feedbackMode}
                      setFactCheck={enableFactCheck}
                      showTranscriptionMsg=""
                      user={user}
                      addTab={addTab}
                      thresholdStatus={threshold_status}
                      thresholdValue={threshold}
                      updateThreshold={(val) => setTranscriptionThreshold(val)}
                      updateThresholdStatus={(val) =>
                        setTranscriptionThresholdStatus(val)
                      }
                      reRenderTranscription={(
                        name,
                        tabIndex,
                        originalContent,
                        threshold_val,
                        threshold_status,
                        audioHighlight
                      ) =>
                        reRenderTranscription(
                          name,
                          tabIndex,
                          originalContent,
                          threshold_val,
                          threshold_status,
                          audioHighlight
                        )
                      }
                      onExportDocument={exportDocument}
                    />
                  )
              )}
            </Column>
            <Column
              lg={7}
              md={3}
              sm={4}
              style={{ height: "calc(100vh - 7rem)!important" }}
            >
              <FeedbackWindow
                toggleFeedbackWindow={toggleFeedbackMode}
                // projectId={projectId}
                // factCheck={doFactCheck}
                // setFactCheck={enableFactCheck}
                // userText={selectedText}
                // addCitation={addCitation}
                // toggleFactCheckMode={toggleFactCheckMode}
                // fileName={tabs[activeTabIndex].name}
              />
            </Column>
          </Grid>
        </div>
      )} */}
      <TourEditor onEnd={() => {}} />
    </div>
  );
}

export default Editor;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./RFPLoader.scss";
import "./Dashboard.scss";
import { Button } from "@carbon/react";
import { useNavigate } from "react-router-dom";

const RFPLoader = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [progress, setProgress] = useState({
    requirements: 0,
    criteria: 0,
    "due dates": 0,
    Collaborators: 0,
    questions: 0,
    templates: 0,
  });

  useEffect(() => {
    const timers = [
      { key: "requirements", duration: 38000 },
      { key: "criteria", duration: 24000 },
      { key: "due dates", duration: 15500 },
      { key: "Collaborators", duration: 21000 },
      { key: "questions", duration: 2000000 },
      { key: "templates", duration: 20200 },
    ];

    const easeInOutCubic = (t) =>
      t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

    const startProgressBar = (key, duration) => {
      let start = null;
      const step = (timestamp) => {
        if (!start) start = timestamp;
        const progress = Math.min((timestamp - start) / duration, 1);
        const easedProgress = easeInOutCubic(progress);

        setProgress((prev) => ({
          ...prev,
          [key]: Math.min(Math.round(easedProgress * 100), 100),
        }));

        if (progress < 1) {
          requestAnimationFrame(step);
        }
      };
      requestAnimationFrame(step);
    };

    // Start all progress bars except templates
    timers.slice(0, -1).forEach(({ key, duration }) => {
      startProgressBar(key, duration);
    });

    // Start templates progress bar after others are complete
    const otherDurations = timers.slice(0, -1).map((t) => t.duration);
    const maxDuration = Math.max(...otherDurations);
    setTimeout(() => {
      startProgressBar("templates", timers[timers.length - 1].duration);
    }, maxDuration);
  }, []);

  const allComplete = Object.values(progress).every((value) => value === 100);

  return (
    <div className="rfp-loader">
      <div className="page-header">
        <div className="page-title-container">
          <span className="page-title">Preparing data for RFX</span>
        </div>
      </div>
      <div className="rfp-loader__progress-container">
        <div className="rfp-loader__progress-bars">
          {Object.entries(progress).map(([key, value]) => (
            <div key={key} className="rfp-loader__progress-item">
              <div className="rfp-loader__progress-item-label">{key}</div>
              <div className="rfp-loader__progress-item-bar">
                <div
                  className="rfp-loader__progress-item-bar-fill"
                  style={{ width: `${value}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <Button
          onClick={(e) => {
            navigate("/rfp", {
              state: {
                fileName: state.fileName,
              },
            });
          }}
          kind="primary"
          className="create-project-button"
          // disabled={!allComplete}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default RFPLoader;

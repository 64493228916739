export const formatDate = (date) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
  };

  const formatter = new Intl.DateTimeFormat(undefined, options);
  const formattedDate = formatter.format(date);
  return formattedDate;
};

export const allowedContentTypesForTranscription = [
  "audio/mpeg",
  "audio/mp4",
  "audio/wav",
  "video/mp4",
  "video/mpeg",
  "video/webm",
];

export const FileExtensionMimeTypes = {
  ".txt": "text/plain",
  ".docx":
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".pdf": "application/pdf",
  ".json": "application/json",
  ".mp3": "audio/mpeg",
};

export const projectUIFileFormatsToFilter = [
  "_mp",
  ".json",
  ".mp3",
  ".mp4",
  ".mpeg",
  ".mgpa",
  ".m4a",
  ".wav",
  ".webm",
];

export const fileTagsForGenMainPoints = ["uploaded", "transcription"];

export const supportedCMS = [
  {
    name: "WordPress",
    archiveType: "wordPress",
    description: "Fetch from existing WordPress CMS",
    supported: true,
    logo: "WordPress.png",
    apiPath: "/wp-json/wp/v2/posts",
  },
  {
    name: "Drupal",
    archiveType: "drupal",
    description: "Fetch from existing Drupal CMS",
    supported: true,
    logo: "Drupal.png",
    apiPath: "/jsonapi/node/article",
  },
  {
    name: "Upload Files",
    archiveType: "uploadFiles",
    description: "Upload files to create archive",
    supported: true,
    logo: "UploadFiles.png",
  },
];

export const convertArchiveTypes = (archiveType) => {
  switch (archiveType) {
    case "wordPress":
      return "WordPress Archive";
    case "googleDrive":
      return "Google Drive Archive";
    case "uploadFiles":
      return "Uploaded Files Archive";
    case "drupal":
      return "Drupal Archive";
    default:
      return "Unknown Archive Type";
  }
};

import React, { useState } from "react";
import { Form, TextInput, Dropdown, Button } from "@carbon/react";
import AnnualReport from "./AnnualReport";
import QuaterlyReport from "./QuarterlyReport";

const FinForm = (props) => {
  const [files, setFiles] = useState([]);
  const [quarterFiles, setQuarterFiles] = useState([]);
  const [formData, setFormData] = useState({
    companyName: "",
    docType: "",
  });

  const [annualReports, setAnnualReports] = useState([1]);
  const [quarterReports, setQuarterlyReports] = useState([1]);
  const [showAnnualFileError, setShowAnnualFileError] = useState(false);
  const [isDropdownValid, setIsDropdownValid] = useState(true);

  const addAnnualReport = () => {
    if (annualReports.length < 2) {
      setAnnualReports((prevReports) => [
        ...prevReports,
        prevReports.length + 1,
      ]);
    }
  };

  const addQuarterlyReport = () => {
    if (quarterReports.length < 3) {
      setQuarterlyReports((prevReports) => [
        ...prevReports,
        prevReports.length + 1,
      ]);
    }
  };

  const documentOptions = [
    { label: "Cash flow statement", value: "cash_flow" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropdownChange = ({ selectedItem }) => {
    setFormData((prev) => ({
      ...prev,
      docType: selectedItem.value,
    }));
  };

  const handleAnnualFileUpload = (file) => {
    setFiles((prevFiles) => [...prevFiles, file]);
    setShowAnnualFileError(false);
  };

  const handleQuarterlyFileUpload = (file) => {
    setQuarterFiles((prevFiles) => [...prevFiles, file]);
  };

  const handleAnnualFileRemove = (fileToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileToRemove.name)
    );
  };

  const handleQuarterlyFileRemove = (fileToRemove) => {
    setQuarterFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileToRemove.name)
    );
  };

  return (
    <div>
      <h4>Fill in the details to generate financial estimates</h4>
      <br></br>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          const isAnnualFileUploaded = files.length > 0;
          const isDocumentTypeSelected = formData.docType !== "";

          // Update the UI to reflect the current validation state
          setShowAnnualFileError(!isAnnualFileUploaded);
          setIsDropdownValid(isDocumentTypeSelected);

          // Only proceed if all validations pass
          if (isAnnualFileUploaded && isDocumentTypeSelected) {
            props.onSubmit(formData, files, quarterFiles);
          }
        }}
      >
        <TextInput
          id="company-name"
          name="companyName"
          labelText="Target company name"
          value={formData.companyName}
          onChange={handleChange}
          required
        />{" "}
        <br></br>
        <Dropdown
          id="findoc"
          name="findoc"
          titleText="Financial document"
          label="Select a document type"
          items={documentOptions}
          itemToString={(item) => (item ? item.label : "")}
          onChange={(selectedItem) => {
            handleDropdownChange(selectedItem);
            setIsDropdownValid(true); // Reset validation state on change
          }}
          selectedItem={documentOptions.find(
            (option) => option.value === formData.docType
          )}
          required
        />
        {!isDropdownValid && (
          <div style={{ marginBottom: "1%", marginTop: "10px" }}>
            {" "}
            <span
              style={{
                color: "red",
                marginLeft: "10px",
                fontSize: "var(--text-medium)",
              }}
            >
              Please select a document type.
            </span>
          </div>
        )}
        <br></br>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="label-multiple-url-heading">Annual report</span>
          {showAnnualFileError && (
            <span
              style={{
                color: "red",
                marginLeft: "10px",
                fontSize: "var(--text-medium)",
              }}
            >
              Please upload at least one annual report.
            </span>
          )}
        </div>
        {annualReports.map((reportId, index) => (
          <div>
            <AnnualReport
              key={reportId}
              id={reportId}
              handleFileUpload={handleAnnualFileUpload}
              handleFileRemove={handleAnnualFileRemove}
              showAddButton={index === 0 && annualReports.length < 2}
              onAddClick={addAnnualReport}
            />
            <br></br>
          </div>
        ))}
        <br></br>
        <span className="label-multiple-url-heading">Quarterly report</span>
        {quarterReports.map((reportId, index) => (
          <div>
            <QuaterlyReport
              key={reportId}
              id={reportId}
              handleFileUpload={handleQuarterlyFileUpload}
              handleFileRemove={handleQuarterlyFileRemove}
              showAddButton={
                (quarterReports.length === 1 && index === 0) ||
                (quarterReports.length === 2 && index === 1)
              }
              onAddClick={addQuarterlyReport}
            />
            <br></br>
          </div>
        ))}
        <br></br>
        <Button type="submit">Generate</Button>
      </Form>
    </div>
  );
};

export default FinForm;

import React, { useEffect, useRef, useState } from "react";
import { TextArea } from "@carbon/react";

const AutoAdjustingTextArea = ({ displayText, onChangeHandler }) => {
  const textAreaRef = useRef(null);

  const adjustHeight = () => {
    const textArea = textAreaRef.current;
    setTimeout(() => {
      if (textArea) {
        // Adjust the height based on the content
        textArea.style.height = "auto";
        textArea.style.height = `${textArea.scrollHeight}px`;
      }
    }, 500);
  };

  useEffect(() => {
    adjustHeight();
  }, [displayText]);

  const handleInput = (event) => {
    onChangeHandler(event);
    adjustHeight();
  };

  return (
    <>
      {displayText && (
        <TextArea
          ref={textAreaRef}
          labelText=""
          value={displayText}
          onChange={handleInput}
          style={{
            width: "100%",
            minHeight: "40px",
            overflow: "hidden",
            resize: "none",
          }}
        />
      )}
    </>
  );
};

export default AutoAdjustingTextArea;

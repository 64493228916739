import React from 'react';
import { SkeletonText, SkeletonPlaceholder, AccordionSkeleton } from '@carbon/react';

const FactCheckSkeletonLoader = (props) => {
  const { childComponent } = props;
  return (
    <div>
      <div>
        <SkeletonText
          heading
          width="50%"
          style={{ marginTop: '4%', marginLeft: '20%', marginBottom: '3%' }}
        />
      </div>
      {childComponent === 'Accordion' && <AccordionSkeleton open count={4} />}
      {childComponent === 'Tile' &&
        <div>
          <SkeletonPlaceholder style={{ width: '100%' }} />
          <SkeletonPlaceholder style={{ marginTop: '5%', width: '100%' }} />
        </div>}

    </div >
  );
};

export default FactCheckSkeletonLoader;

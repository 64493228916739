import React, { useState, useEffect } from "react";
import { Modal, Dropdown } from "@carbon/react";
import "./scss/GenerationModal.scss";
import { api, api_external } from "../../store";
import { projectUIFileFormatsToFilter } from "../../util";
import env from "../../config";

const BlogGenerationModal = ({
  isOpen,
  onClose,
  refreshFiles,
  projectId,
  isDraft,
  blogGenAPICall,
  refreshComponent,
}) => {
  const [structure, setStructure] = useState("");

  const [isOtherError, setOtherIsError] = useState(false);
  const [projectFiles, setProjectFiles] = useState([]);
  const [styleProfiles, setStyleProfiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});

  useEffect(() => {
    if (isOpen) {
      getUserStyleProfiles();
      fetchInputOutputFiles();
    }

    async function getUserStyleProfiles() {
      try {
        let response = await api.get("style/getUserStyles");
        // Extract the names from the response
        let styleProfiles = response.data.map((profile) => profile.name);

        // Append the values from the Select component
        const selectOptions = [
          "Inverted pyramid",
          "Narrative",
          "Problem-Solution",
          "Compare and Contrast",
        ];
        if (styleProfiles.length > 0) {
          console.log(styleProfiles);
          setStyleProfiles(styleProfiles);
        } else {
          styleProfiles = [...styleProfiles, ...selectOptions];
          console.log(styleProfiles);
          setStyleProfiles(styleProfiles);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [isOpen, refreshComponent]);

  async function fetchInputOutputFiles() {
    try {
      const fileList = await api.get("file/getFiles", {
        params: { projectId: projectId },
        headers: { "Cache-Control": "no-cache" },
      });

      // Replace with user selection in future versions
      const userFiles = fileList.data.filter(
        (file) =>
          !projectUIFileFormatsToFilter.some((format) =>
            file.name.includes(format)
          )
      );
      const mainpointFiles = userFiles.filter((file) =>
        file.tag.includes("transcription")
      );

      console.log(mainpointFiles);

      setProjectFiles(mainpointFiles);
    } catch (err) {
      console.log(err);
    }
  }

  const handleNextStep = async (evt) => {
    evt.preventDefault();
    if (isDraft) {
      blogGenAPICall(selectedFile);
    }
    onModalClose();
    //clear the modal fields
  };

  const handleSecondaryButtonClick = () => {
    onModalClose();
  };

  const handleStructureDropdownChange = ({ selectedItem }) => {
    setOtherIsError(false);
    if (selectedItem) {
      setStructure(selectedItem.id);
    }
  };

  const handleTileClick = (id, type, tags, name) => {
    setSelectedFile({
      id: id,
      type: type,
      file_tags: tags,
      title: name,
    });
  };

  const onModalClose = () => {
    onClose();
    setSelectedFile({});
    setStructure("");
  };

  return (
    <Modal
      open={isOpen}
      onRequestClose={onModalClose}
      primaryButtonText="Proceed"
      secondaryButtonText="Cancel"
      onRequestSubmit={handleNextStep}
      onSecondarySubmit={handleSecondaryButtonClick}
      primaryButtonDisabled={!selectedFile.id || !structure}
      size="md"
    >
      <div style={{ margin: "2% 5% 0 5%" }}>
        <h3 style={{ fontSize: "var(--text-large)", fontWeight: "400" }}>
          Create blog post
        </h3>
        <div style={{}}>
          <br />
          <div>
            <p
              style={{
                marginTop: "2%",
                fontSize: "var(--text-medium)",
                fontWeight: "400",
                marginBottom: "2%",
              }}
            >
              Select a podcast file to generate draft
            </p>
            <div style={{ marginTop: "2%", marginBottom: "5%" }}>
              {projectFiles.length > 0 &&
                projectFiles.map((file, index) => (
                  <div
                    key={index}
                    className={`file-tile ${
                      selectedFile[file.id]?.selected ? "selected-tile" : ""
                    }`}
                    onClick={() =>
                      handleTileClick(file.id, "file", file.tag, file.name)
                    }
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px",
                      margin: "5px 0",
                      border:
                        selectedFile.id === file.id
                          ? "2px solid purple"
                          : "2px solid #e0e0e0",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: "10px" }}>File source</span>
                      <div
                        style={{
                          height: "20px",
                          width: "1px",
                          backgroundColor: "#e0e0e0",
                          marginRight: "10px",
                        }}
                      ></div>
                      <span>{file.name}</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <Dropdown
            id="structure-dropdown"
            invalid={isOtherError}
            titleText="Style"
            label="Select a style"
            items={styleProfiles.map((profile) => ({
              id: profile,
              label: profile,
            }))}
            itemToString={(item) => (item ? item.label : "")}
            selectedItem={
              styleProfiles
                .map((profile) => ({
                  id: profile,
                  label: profile,
                }))
                .find((profile) => profile.id === structure) || null
            }
            onChange={handleStructureDropdownChange}
          />
          <br />
        </div>
      </div>
    </Modal>
  );
};

export default BlogGenerationModal;

import React, { useState, useEffect } from "react";
import { InlineLoading, Modal, ModalBody, TextInput } from "@carbon/react";
import { api } from "../../store";
import "./scss/CollapsibleList.scss";
import {
  Document,
  DocumentPdf,
  DocumentAudio,
  OverflowMenuVertical,
} from "@carbon/icons-react";
import { allowedContentTypesForTranscription } from "../../util";

const ContextMenu = ({ x, y, onRename, onDelete }) => {
  return (
    <div className="context-menu" style={{ top: y, left: x }}>
      <span>File actions</span>
      <ul>
        <li onClick={onRename}>Rename</li>
        <li onClick={onDelete}>Delete</li>
      </ul>
    </div>
  );
};

const CollapsibleList = (props) => {
  const [menuState, setMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
    fileId: null,
  });
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState({ name: "", id: null });
  const [newName, setNewName] = useState("");
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [renameError, setRenameError] = useState("");

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuState.visible && !event.target.closest(".context-menu")) {
        handleMenuClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [menuState.visible]);

  const handleContextMenu = (event, fileId) => {
    event.preventDefault();

    setMenuState({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      fileId,
    });
  };

  const handleMenuClose = () => {
    setMenuState({ visible: false, x: 0, y: 0, fileId: null });
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    setDeleteModalOpen(false);

    try {
      await api.delete(
        `file/deleteFile/?fileId=${selectedFile.id}&ownerId=${props.projectId}`
      );
      props.refreshFiles();
    } catch (error) {
      console.error("Failed to delete file:", error);
    }
  };

  const renameFile = async () => {
    try {
      const response = await api.put("/file/renameFile", {
        fileId: selectedFile.id,
        existingName: selectedFile.name,
        newName: newName,
        ownerId: props.projectId,
      });

      if (response.status === 200) {
        setRenameModalOpen(false);
        props.refreshFiles();
        props.updateFileName(selectedFile.name, newName);
      }
    } catch (error) {
      console.error(
        "Failed to rename file:",
        error.response?.data?.message || error.message
      );
      setRenameError("File rename failed. A file with name already exists.");
    }
  };

  const handleRename = async () => {
    handleMenuClose();
    setRenameModalOpen(true);
    setNewName(selectedFile.name);
  };

  const handleFileDownload = (fileName, fileContent, fileType, tag) => {
    // Pass the downloaded file data to the parent component

    props.onFileDownload(fileName, fileContent, fileType, tag);
  };

  const handleFileClick = async (fileId, fileName, fileType, tag) => {
    // Call the API to download the file and display it
    // Implement your logic here to download and display the file
    try {
      let content;
      if (fileType === "application/pdf") {
        let response = await api.get(`file/getSignedURL/${fileId}`);
        content = response.data.url;
      } else if (fileType === ".mp3") {
        //download the file to local computer from the browser
        const response = await api.get(`file/getSignedURL/${fileId}`);
        const url = response.data.url;
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        let response = await api.post("file/download", null, {
          params: {
            id: fileId,
            ownerId: props.projectId,
          },
        });
        content = response.data.contents;
      }
      handleFileDownload(fileName, content, fileType, tag);
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data?.message);
    }
  };

  return (
    <div>
      {props.listItems[0] && props.listItems[0].items.length === 0 && (
        <span className="no-files-text">No files</span>
      )}
      {props.listItems.map((item, index) => (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              maxHeight: "40vh",
              paddingRight: "1rem",
            }}
          >
            {item.items.map((subItem, subIndex) => (
              <div key={subIndex} className="file-item-container">
                <button
                  key={subIndex}
                  className="clickable-list-item"
                  onContextMenu={(e) => {
                    setSelectedFile({ name: subItem.name, id: subItem.id });
                    handleContextMenu(e, subItem.id);
                  }}
                  // isExpressive={true}
                  onClick={() =>
                    handleFileClick(
                      subItem.id,
                      subItem.name,
                      subItem.contentType,
                      subItem.tag
                    )
                  }
                  disabled={allowedContentTypesForTranscription.includes(
                    subItem.contentType
                  )}
                >
                  {allowedContentTypesForTranscription.includes(
                    subItem.contentType
                  ) ? (
                    <div className="list-item">
                      <InlineLoading
                        className="inline-loading-file"
                        description="Transcribing..."
                      />
                      <span className="button-text">{subItem.name}</span>
                    </div>
                  ) : subItem.contentType === ".mp3" &&
                    subItem.tag.includes("podcast") ? (
                    <div className="list-item">
                      <DocumentAudio className="file-icon" />
                      <span className="button-text" title={subItem.name}>
                        {subItem.name}
                      </span>
                    </div>
                  ) : (
                    <div className="list-item">
                      {subItem.contentType === "application/pdf" ? (
                        <DocumentPdf className="file-icon" />
                      ) : (
                        <Document className="file-icon" />
                      )}
                      <span className="button-text" title={subItem.name}>
                        {subItem.name}
                      </span>
                    </div>
                  )}
                </button>
                <OverflowMenuVertical
                  className="overflow-vert"
                  onClick={(e) => {
                    setSelectedFile({ name: subItem.name, id: subItem.id });
                    handleContextMenu(e, subItem.id);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
      {menuState.visible && (
        <ContextMenu
          x={menuState.x}
          y={menuState.y}
          onRename={handleRename}
          onDelete={handleDelete}
        />
      )}
      <Modal
        open={isDeleteModalOpen}
        modalHeading="Delete file"
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        onRequestSubmit={confirmDelete}
        onRequestClose={() => {
          setDeleteModalOpen(false);
        }}
        size="sm"
      >
        <ModalBody style={{ padding: "0" }}>
          Are you sure you want to delete <strong>{selectedFile.name}</strong>?
          <div>This action cannot be undone.</div>
        </ModalBody>
      </Modal>
      <Modal
        open={isRenameModalOpen}
        modalHeading="Rename file"
        primaryButtonText="Rename"
        secondaryButtonText="Cancel"
        onRequestSubmit={renameFile}
        onRequestClose={() => {
          setRenameModalOpen(false);
        }}
        size="sm"
      >
        <ModalBody>
          <TextInput
            id="newName"
            labelText="File Name"
            value={newName}
            invalid={renameError ? true : false}
            placeholder="New file name"
            invalidText={renameError}
            onChange={(e) => {
              const lastDotIndex = selectedFile.name.lastIndexOf(".");
              const extension = selectedFile.name.substring(lastDotIndex);
              const newNameWithoutExtension = e.target.value.split(".")[0];
              setNewName(newNameWithoutExtension + extension);
              setRenameError("");
            }}
          />
          <p style={{ fontSize: "var(--text-medium)", color: "#888" }}>
            Do not include the file extension in your changes.
          </p>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CollapsibleList;

import React, { useState, useEffect } from "react";
import { Modal, ComboBox, IconButton, Loading, Dropdown, TextInput } from "@carbon/react";
import { ArrowsHorizontal } from "@carbon/icons-react";
import languageMap from "./../Languagemap";
import env from "../../config";
import { api, api_external } from "../../store";
import { stripExtension } from "../../utils/Utils";


const TranslationModal = ({
  isOpen,
  onClose,
  projectId,
  sourceText,
  modalHeaderName,
  addTab,
  tabName,
  saveFile,
  listItems,
  getFileContent,
  generateFileName
}) => {
  const [sourceLanguage, setSourceLanguage] = useState(null);
  const [targetLanguage, setTargetLanguage] = useState(null);
  const [translationError, setTranslationError] = useState(false);
  const [translationErrorMessage, setTranslationErrorMessage] = useState("");
  const [enableSwitch, setEnableSwitch] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);  
  const [targetText, setTargetText] = useState("");
  const [targetFileName, setTargetFileName] = useState("");
  // const [filenameError, setFileNameError] = useState(false);



  const filesList = (listItems && listItems.length > 0 && listItems[0].items) ? listItems[0].items : [];

  useEffect(() => {
    setEnableSwitch(
      !languageMap.connectionExists(targetLanguage, sourceLanguage)
    );
  }, [sourceLanguage, targetLanguage]);



  useEffect(() => {

    const detectLanguage = async (text) => {
      if (text.trim() === "") {
        console.log("No text provided");
        setSourceLanguage(null); // Exit if no text is provided
        return;
      }

      try {
        const response = await api.post(env.translationAPI + "/detect-lang", {
          text: text,
        });
        const detectedLanguageCode =
          response.data.detected_langs[0].language_code;
        const matchedLanguage =
          languageMap.getLanguageByLangId(detectedLanguageCode);
        if (matchedLanguage) {
          setSourceLanguage(matchedLanguage);
        }
      } catch (error) {
        console.error("Failed to detect language:", error);
        // Optionally handle the error, e.g., fallback to a default language
      }
    };

    const fetchCustomTranslationModelInfo = async () => {
      try {
        const response = await api.get(
          "/custom_model/getCustomModels?type=translation"
        );
        // Process the response data here
        if (response.data.length === 0) {
          return;
        }
        const customModelsInfos = response.data.map((custom_model) => ({
          gcpModelId: custom_model.properties.gcpModelId,
          source: custom_model.properties.source,
          target: custom_model.properties.target,
        }));

        for (const customModelInfo of customModelsInfos) {
          if (
            !languageMap.languages[customModelInfo.source.label] ||
            !languageMap.languages[customModelInfo.target.label]
          ) {
            if (!languageMap.languages[customModelInfo.source.label]) {
              languageMap.addLanguage(
                customModelInfo.source.langId,
                customModelInfo.source.label
              );
            }
            if (!languageMap.languages[customModelInfo.target.label]) {
              languageMap.addLanguage(
                customModelInfo.target.langId,
                customModelInfo.target.label
              );
            }
            languageMap.addConnection(
              customModelInfo.source.label,
              customModelInfo.target.label,
              customModelInfo.gcpModelId
            );
          }
        }
      } catch (error) {
        console.error("Failed to fetch custom models:", error);
      }
    };

    const fetchFileContent = async (fileName) => {
      const text = await getFileContent(fileName);
      return text;
    };
  
    const handleDetection = async () => {
      fetchCustomTranslationModelInfo();
      const fileName = sourceText ? tabName : selectedFile.name;
      setTargetFileName(fileName);
      const text = sourceText ? sourceText : await fetchFileContent(fileName);
      detectLanguage(text);
      setTargetText(text);
    };
    
    if (isOpen && (selectedFile || sourceText)) {
      handleDetection();
    }
  }, [isOpen, selectedFile]);

  const handleFileSelectDropdownChange = ({ selectedItem }) => {
    setSelectedFile(selectedItem ? selectedItem : null);
  };

  const translateText = async () => {

    if (targetText.trim() === "") {
      setTranslationError(true);
      setTranslationErrorMessage("Please provide text to translate.");
      return;
    }

    if (
      !sourceLanguage ||
      !targetLanguage ||
      sourceLanguage === "" ||
      targetLanguage === ""
    ) {
      setTranslationError(true);
      setTranslationErrorMessage(
        "Please select both source and target languages."
      );
      return;
    }

    const sourceLangId = sourceLanguage.langId;
    const targetLangId = targetLanguage.langId;

    const customModelId = languageMap.getCustomModelId(
      sourceLanguage,
      targetLanguage
    );

    if (sourceLangId === targetLangId) {
      setTranslationError(true);
      setTranslationErrorMessage(
        "Source and target languages cannot be the same."
      );
      return;
    }

    // Reset error state
    setTranslationError(false);
    setTranslationErrorMessage("");
    setLoading(true);

    try {
      try {

        const requestData = {
          text: targetText,
          source_lang: sourceLangId,
          target_lang: targetLangId,
          custom_model_id: customModelId,
        };
        const response = await api_external.post(
          env.translationAPI + "/translate",
          requestData
        );
        const translated_text = response.data.translated_text;
        
        
        const newFileName = generateFileName(`${stripExtension(targetFileName)} (${targetLangId}).docx`)

        // const baseTabName = targetFileName.endsWith(".docx")
        //   ? targetFileName.slice(0, -4)
        //   : targetFileName;
        // const filename = `${baseTabName}_(${targetLangId}).docx`;
        const tag = ["translation"];

        addTab(newFileName, translated_text, tag);
        saveFile(
          translated_text,
          newFileName,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          tag
        );
        onClose();
        api.post("/user/logAsyncServiceCall", {
          projectId: projectId,
          service_tag: "translation",
          service_filename: targetFileName,
          options: {
            service_progress_status: false,
            service_payload: {
              input: { source_text: targetText },
              output: { target_text: translated_text },
            },
          },
        });
      } catch (err) {
        console.log(err);
        setTranslationErrorMessage("Translation failed. Please try again.");
        api.post("/user/logAsyncServiceCall", {
          projectId: projectId,
          service_tag: "translation",
          service_filename: targetFileName,
          options: {
            service_progress_status: false,
            service_error: true,
            service_error_msg: err?.message,
          },
        });
      }
    } catch (error) {
      console.error("Translation failed", error);
      setTranslationError(true);
      setTranslationErrorMessage("Translation failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        modalHeading={modalHeaderName}
        loadingDescription={"Translating..."}
        loadingStatus={isLoading ? "active" : "inactive"}
        primaryButtonText="Translate" 
        secondaryButtonText="Cancel"
        onRequestSubmit={translateText}
        onRequestClose={() => {
          onClose();
          setTranslationError(false);
        }}
        size="sm"
      >
        {!sourceText && (
          <Dropdown
            id="file-dropdown"
            titleText="Select target file"
            label="Select file to translate"
            items={filesList.filter(item => item.contentType !== ".mp3")}
            itemToString={(item) => (item ? item.name : "")}
            onChange={handleFileSelectDropdownChange}
            required
          />
        )}


        {translationError && (
          <p style={{ color: "red" }}>{translationErrorMessage}</p>
        )}

        <style>
          {`
            .cds--list-box__menu {
              border-bottom-left-radius: var(--border-radius); 
              border-bottom-right-radius: var(--border-radius); 
            }
            [id^="downshift-"][id$="-menu"] {
              max-height: 6rem; 
              overflow-y: auto; 
            }
            .translation-combo .cds--text-input {
              border-bottom-left-radius: 0px !important; 
              border-bottom-right-radius: 0px !important;
            }
          `}
        </style>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
            marginBottom: "4rem"
          }}
        >
          <div style={{ width: "44%" }}>
            <ComboBox
              id="source-language-dropdown"
              titleText="Source Language"
              direction="bottom"
              className="translation-combo"
              items={
                targetLanguage
                  ? languageMap.getSources(targetLanguage)
                  : languageMap.getAllSources()
              }
              selectedItem={sourceLanguage}
              onChange={({ selectedItem }) => setSourceLanguage(selectedItem)}
              style={{ width: "100%" }}
            />
          </div>

          <div
            style={{
              width: "12%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <IconButton
              label="Switch languages"
              kind="tertiary"
              align="bottom"
              size="sm"
              onClick={() => {
                const temp = sourceLanguage;
                setSourceLanguage(targetLanguage);
                setTargetLanguage(temp);
              }}
              style={{ 
                position: "relative",
                
              }}
              className="switch-button"
              disabled={enableSwitch}
            >
              <ArrowsHorizontal />
            </IconButton>
          </div>

          <div style={{ width: "44%" }}>
            <ComboBox
              id="target-language-dropdown"
              titleText="Target Language"
              direction="bottom"
              className="translation-combo"
              // placeholder="Select target language"
              items={
                sourceLanguage
                  ? languageMap.getTargets(sourceLanguage)
                  : languageMap.getAllTargets()
              }
              selectedItem={targetLanguage}
              onChange={({ selectedItem }) => setTargetLanguage(selectedItem)}
              style={{ width: "100%" }}
            />
          </div>
          
        </div>
        
        {/* <div>
        <TextInput
          id="filename"
          labelText="Output file name"
          value={outputFileName}
          invalid={filenameError}
          className="text_input"
          onChange={(e) => {
            setFileNameError(false);
            setOutputFileName(e.target.value);
          }}
        />
      </div> */}
        
      </Modal>
    </div>
  );
};

export default TranslationModal;

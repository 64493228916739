import React, { useState, useEffect } from "react";
import {
  Modal,
  Select,
  SelectItem,
  TextInput,
  ProgressIndicator,
  ProgressStep,
  Tile,
  Toggle,
} from "@carbon/react";
import "./scss/GenerationModal.scss";
import { api } from "../../store";
import {
  projectUIFileFormatsToFilter,
  fileTagsForGenMainPoints,
} from "../../util";

const GenerationModal = ({
  isOpen,
  onClose,
  refreshFiles,
  projectId,
  setFileIdsForGeneration,
  outlineAPICall,
  setObjectiveForGeneration,
  isDraft,
  textGenAPICall,
  refreshComponent,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [step, setStep] = useState(1);
  const [primaryButtonText, setPrimaryButtonText] = useState("Proceed");
  const [secondaryButtonText, setSecondaryButtonText] = useState("Cancel");
  const [objective, setObjective] = useState({
    topic: "",
    category: "",
    wordLimit: "",
    tone: "",
    structure: "",
  });
  const [isTopicError, setIsTopicError] = useState(false);
  const [isCatError, setCatIsError] = useState(false);
  const [isToneError, setToneIsError] = useState(false);
  const [isOtherError, setOtherIsError] = useState(false);
  const [iswlError, setWLIsError] = useState(false);
  const [uploadErrorMessages, setUploadErrorMessages] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [filesAndStatus, setFilesAndStatus] = useState([]);
  const [projectFiles, setProjectFiles] = useState([]);
  const [styleProfiles, setStyleProfiles] = useState([]);
  const [supportingSourcesCount, setSupportingSourcesCount] = useState(0);
  const [mainSourceSelected, setMainSourceSelected] = useState(false);
  const [selectedFileRoles, setSelectedFileRoles] = useState({});

  const WORD_LIMIT = 2500;
  const selectOptions = [
    "Inverted pyramid",
    "Narrative",
    "Problem-Solution",
    "Compare and Contrast",
  ];

  useEffect(() => {
    if (isOpen) {
      getObjective();
      // getUserStyleProfiles();
      // fetchInputOutputFiles();
    }

    async function getObjective() {
      try {
        let response = await api.post("file/download", null, {
          params: {
            name: "objective.json",
            ownerId: projectId,
          },
        });
        const contents = response.data.contents;
        setObjective(JSON.parse(contents));
      } catch (err) {
        console.log(err);
      }
    }

    async function getUserStyleProfiles() {
      try {
        // let response = await api.get("style/getUserStyles");

        // // Extract the names from the response
        // let styleProfiles = response.data.map((profile) => profile.name);

        // Append the values from the Select component
        const selectOptions = [
          "Inverted pyramid",
          "Narrative",
          "Problem-Solution",
          "Compare and Contrast",
        ];

        styleProfiles = [...selectOptions];
        setStyleProfiles(styleProfiles);
      } catch (err) {
        console.log(err);
      }
    }
  }, [refreshComponent]);

  async function fetchInputOutputFiles() {
    try {
      const fileList = await api.get("file/getFiles", {
        params: { projectId: projectId },
        headers: { "Cache-Control": "no-cache" },
      });
      // Replace with user selection in future versions
      const userFiles = fileList.data.filter(
        (file) =>
          !projectUIFileFormatsToFilter.some((format) =>
            file.name.includes(format)
          )
      );
      const mainpointFiles = userFiles.filter((file) =>
        fileTagsForGenMainPoints.some((tag) => file.tag.includes(tag))
      );
      setProjectFiles(mainpointFiles);
    } catch (err) {
      console.log(err);
    }
  }

  async function uploadOutline() {
    try {
      const fileName = "objective.json";
      const data = JSON.stringify(objective);
      api.post(
        "file/uploadData",
        { data, fileName, owner: projectId, tag: "objective" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  const handleNextStep = async (evt) => {
    if (step === 1) {
      evt.preventDefault();
      const { topic, category, tone, wordLimit, structure } = objective;
      if (topic === "") setIsTopicError(true);
      if (category === "") setCatIsError(true);
      if (tone === "") setToneIsError(true);
      if (structure === "") setOtherIsError(true);
      if (wordLimit === "" || parseInt(wordLimit) > WORD_LIMIT)
        setWLIsError(true);

      if (
        topic === "" ||
        category === "" ||
        tone === "" ||
        wordLimit === "" ||
        structure === "" ||
        parseInt(wordLimit) > WORD_LIMIT
      ) {
        return;
      }
      await fetchInputOutputFiles();
      setStep(2);
      setPrimaryButtonText("Proceed");
      setSecondaryButtonText("Back");
    } else {
      // Setup files for Gen and Objective
      // Store the objective in the JSON file on bucket
      evt.preventDefault();
      let response;
      let supportingSourceList;
      let mainSourceID;
      let style_profile = objective.structure;
      supportingSourceList = Object.entries(selectedFileRoles)
        .filter(([id, role]) => role === "supportingSource")
        .map(([id, role]) => id);

      mainSourceID = Object.entries(selectedFileRoles).find(
        ([id, role]) => role === "mainSource"
      )[0];
      setSelectedFiles([...supportingSourceList, mainSourceID]);
      let style_structure = objective.structure;
      if (isDraft) {
        try {
          response = await api.get("style/getStyleParameters", {
            params: { name: style_profile },
          });
          if (response.data != "" && response.status != 204)
            style_structure = response.data.description;
        } catch (error) {
          style_structure = objective.structure;
        }
      }
      uploadOutline();
      setFileIdsForGeneration([...supportingSourceList, mainSourceID]);
      setObjectiveForGeneration(objective);
      onModalClose();
      if (isDraft) {
        // call the text gen API
        objective.structure = style_structure;
        textGenAPICall(
          objective,
          mainSourceID,
          supportingSourceList,
          style_profile
        );
      } else outlineAPICall(objective, [...supportingSourceList, mainSourceID]);
    }
  };

  const handleSecondaryButtonClick = () => {
    if (step === 1) onModalClose();
    else if (step === 2) {
      setStep(1);
      setPrimaryButtonText("Proceed");
      setSecondaryButtonText("Cancel");
    }
  };

  const handleRoleSelection = (fileId, role) => {
    setSelectedFileRoles((prevFileTypes) => {
      let newSupportingSourcesCount = supportingSourcesCount;

      if (
        role === "supportingSource" &&
        prevFileTypes[fileId] !== "supportingSource"
      ) {
        newSupportingSourcesCount++;
      } else if (
        prevFileTypes[fileId] === "supportingSource" &&
        role !== "supportingSource"
      ) {
        newSupportingSourcesCount--;
      }
      setSupportingSourcesCount(newSupportingSourcesCount);

      if (role === "mainSource") {
        setMainSourceSelected(true);
      } else if (prevFileTypes[fileId] === "mainSource") {
        setMainSourceSelected(false);
      }

      return {
        ...prevFileTypes,
        [fileId]: role,
      };
    });
  };

  const onModalClose = () => {
    onClose();
    setStep(1);
    setSelectedFileRoles({});
    setIsTopicError(false);
    setCatIsError(false);
    setToneIsError(false);
    setWLIsError(false);
    setUploadErrorMessages([]);
    setUploadError(false);
    setFilesAndStatus([]);
    setProjectFiles([]);
    setStyleProfiles([]);
    setOtherIsError(false);
    setSupportingSourcesCount(0);
    setMainSourceSelected(false);
    setObjective({
      topic: "",
      category: "",
      wordLimit: "",
      tone: "",
      structure: "",
    });
  };

  return (
    <Modal
      open={isOpen}
      onRequestClose={onModalClose}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      onRequestSubmit={handleNextStep}
      onSecondarySubmit={handleSecondaryButtonClick}
      primaryButtonDisabled={
        step === 2 ? (mainSourceSelected ? false : true) : false
      }
      size="sm"
    >
      <div style={{ margin: "0 auto", width: "16rem" }}>
        <ProgressIndicator currentIndex={step - 1}>
          {/* <ProgressStep label="Step 1" description="Upload input files" /> */}
          <ProgressStep
            label="Step 1"
            description="Set an objective for the draft"
          />
          <ProgressStep
            label="Step 2"
            description="Select files for generating draft"
          />
        </ProgressIndicator>
      </div>

      {step === 1 && (
        <div style={{ marginTop: "5%" }}>
          <h3 style={{ fontSize: "1.25rem", fontWeight: "400" }}>
            Set the objective of the article
          </h3>
          <div style={{ marginTop: "2%" }}>
            <TextInput
              id="topic"
              labelText="Subject"
              value={objective.topic}
              invalid={isTopicError}
              placeholder="scientist bring back the extinct Tamanian tiger"
              className="text_input"
              onChange={(e) => {
                setIsTopicError(false);
                setObjective({
                  ...objective,
                  topic: e.target.value,
                });
              }}
            />
            <TextInput
              id="category"
              labelText="Category"
              value={objective.category}
              invalid={isCatError}
              placeholder="science"
              className="text_input"
              onChange={(e) => {
                setCatIsError(false);
                setObjective({
                  ...objective,
                  category: e.target.value,
                });
              }}
            />
            <TextInput
              id="tone"
              labelText="Tone"
              value={objective.tone}
              invalid={isToneError}
              placeholder="engaging, funny, objective, subjective"
              className="text_input"
              onChange={(e) => {
                setToneIsError(false);
                setObjective({
                  ...objective,
                  tone: e.target.value,
                });
              }}
            />
            <TextInput
              id="wordlimit"
              labelText="Word limit (max. 2500)"
              value={objective.wordLimit}
              invalid={iswlError}
              placeholder="500"
              className="text_input"
              onChange={(e) => {
                setWLIsError(false);
                setObjective({
                  ...objective,
                  wordLimit: e.target.value,
                });
              }}
            />
            <Select
              defaultValue="Inverted pyramid"
              id="structure"
              labelText="Style"
              value={objective.structure}
              invalid={isOtherError}
              className="text_input"
              onChange={(e) => {
                setOtherIsError(false);
                setObjective({
                  ...objective,
                  structure: e.target.value,
                });
              }}
            >
              <SelectItem text="Select the style" value="" />
              {selectOptions.map((profile, index) => (
                <SelectItem key={index} text={profile} value={profile} />
              ))}
            </Select>
          </div>
        </div>
      )}

      {step === 2 && (
        <div style={{ marginTop: "5%" }}>
          <h4 style={{ fontSize: "1.25rem", fontWeight: "400" }}>
            Select sources to generate article
          </h4>
          <div>
            <p
              style={{
                marginTop: "3%",
                fontSize: "14px",
                fontSize: "0.875rem",
                fontWeight: "400",
              }}
            >
              Select a file as a main source and upto 4 files as supporting
              sources.
            </p>
            <div style={{ marginTop: "2%" }}>
              {projectFiles.length > 0 &&
                projectFiles.map((file, index) => (
                  <Tile
                    className={`file-tile ${
                      selectedFileRoles[file.id] ? "selected-file-tile" : ""
                    }`}
                  >
                    <div
                      className="file-info"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ width: "60%", fontSize: "0.875rem" }}>
                        {file.name}
                      </p>
                      <div style={{ width: "40%" }}>
                        <Select
                          id={`select-${file.id}`}
                          labelText=""
                          className="file-type-select"
                          style={{
                            backgroundColor: "white",
                            fontSize: "0.875rem",
                            borderRadius: "var(--border-radius)",
                            border: "none",
                          }}
                          onChange={(e) =>
                            handleRoleSelection(file.id, e.target.value)
                          }
                          value={selectedFileRoles[file.id] || ""}
                          size="sm"
                        >
                          <SelectItem text="Not included" value="" />
                          <SelectItem
                            text="Main Source"
                            value="mainSource"
                            disabled={mainSourceSelected ? true : false}
                          />
                          <SelectItem
                            text="Supporting Source"
                            value="supportingSource"
                            disabled={supportingSourcesCount < 4 ? false : true}
                          />
                        </Select>
                      </div>
                    </div>
                  </Tile>
                ))}
            </div>
          </div>
          {process.env.REACT_APP_ENV === "staging" && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "4% 0 2% 0",
                  padding: "1% 0",
                }}
              >
                <span style={{ fontSize: "0.875rem" }}>Access archives</span>
                <Toggle id="use-archives" hideLabel />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "1% 0",
                }}
              >
                <span style={{ fontSize: "0.875rem" }}>
                  Access trusted sources on internet
                </span>
                <Toggle id="allow-trusted-sites" hideLabel />
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default GenerationModal;

import React, { useEffect, useState } from "react";
import { api } from "../store";
import "./AudioPlayer.scss";
import ReactAudioPlayer from "react-audio-player";
import { Information } from "@carbon/icons-react";
import { Slider, Toggle, Tooltip } from "@carbon/react";

const AudioPlayer = (props) => {
  const [audioSrc, setAudioSrc] = useState("");
  const [fileName, setFileName] = useState("");
  const [isToggleOn, setIsToggleOn] = useState(true);
  const [confidenceThreshold, setConfidenceThreshold] = useState(0.7);
  const audioPlayerRef = React.useRef(null);

  const handleToggleChange = async () => {
    setIsToggleOn(!isToggleOn);
    try {
      const status = !isToggleOn;
      props.setThresholdStatus(status);
      await api.put("/user/updateThresholdStatus", {
        threshold_status: status,
      });

      props.onRerender(confidenceThreshold / 100.0, status);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  async function getAudioFile() {
    const response = await api.post("file/download", null, {
      params: {
        name: props.fileName.substring(
          0,
          props.fileName.indexOf("_transcribed.docx")
        ),
        ownerId: props.projectId,
        find: true,
      },
    });
    setAudioSrc(response.data.url);
    setFileName(response.data.fileName);
  }

  useEffect(() => {
    getAudioFile();
  }, []);

  useEffect(() => {
    if (audioPlayerRef.current && props.audioStartTime) {
      const player = audioPlayerRef.current.audioEl.current;
      player.currentTime = props.audioStartTime - 0.2;
      player.play().catch((error) => {
        console.error("Error attempting to play audio:", error);
        getAudioFile();
      });
    }
  }, [props.audioStartTime, audioSrc]);

  async function getTranscriptionThreshold() {
    try {
      const response = await api.get("/user/getUserProfile");
      const threshold = response.data.transcription_threshold;
      const adjustedThreshold = +(threshold * 100).toFixed(2);
      setConfidenceThreshold(adjustedThreshold);
      setIsToggleOn(response.data.transcription_threshold_status);
      return adjustedThreshold;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async function updateTranscriptionThreshold(valObj) {
    try {
      const threshold = valObj.value / 100.0;
      props.setThreshold(threshold);
      await api.put("/user/updateThreshold", {
        threshold: threshold,
      });
      props.onRerender(threshold, isToggleOn);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  useEffect(() => {
    getTranscriptionThreshold();
  }, []);

  return (
    <div className="audio-control">
      <div className="menubar">
        <div className="audio-file-info">
          <span className="filename">{fileName}</span>
          <br></br>
          <span className="infoText">
            Press Alt/Option and click on words to play audio from that point.
          </span>
        </div>

        <div>
          <div className="control">
            {" "}
            <span className="filename">
              Highlight words with set confidence level
            </span>
            <Tooltip
              direction="top"
              label="Use the toggle to enable/disable highlighting words. 
              Set the slider between 0 and 100 to define the confidence level for highlighting words.
              For example, if set to 88, words with less than 88% confidence are marked."
            >
              <Information />
            </Tooltip>
          </div>
          <div className="confidence-control">
            <div style={{ marginTop: "3.5%" }}>
              <Toggle
                id="confidenceToggle"
                labelText=""
                onToggle={handleToggleChange}
                toggled={isToggleOn}
              />
            </div>

            <Slider
              id="thresholdSlider"
              labelText=""
              min={0.0}
              max={100.0}
              step={0.01}
              value={confidenceThreshold}
              onChange={(value) => {
                setConfidenceThreshold(value.value);
              }}
              onRelease={(value) => {
                updateTranscriptionThreshold(value);
              }}
              disabled={!isToggleOn}
            />
          </div>
        </div>
      </div>
      <div className="audio-player">
        <ReactAudioPlayer
          src={audioSrc}
          controls
          ref={audioPlayerRef}
          className="custom-audio-player"
          title=""
        />
      </div>
    </div>
  );
};

export default AudioPlayer;

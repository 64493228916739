import React, { useState, useEffect } from "react";
import {
  Button,
  TextInput,
  Grid,
  Column,
  Form,
  FormGroup,
  InlineNotification,
} from "@carbon/react";

import { api, removeToken, removeProfile } from "../store";
import { useNavigate, useLocation } from "react-router-dom";
import "./UserProfile.scss";

const PasswordChange = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [newPassError, setNewPassError] = useState("Value cannot be empty");
  const [isNewPassError, setPassErr] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [errorMsg, setErrorMessage] = useState("");
  const [isfnameError, setIsFnameError] = useState(false);
  const [islnameError, setIslnameError] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [toolUsage, setToolUsage] = useState("");
  const [isRoleError, setIsRoleError] = useState(false);
  const [isCompanyError, setIsCompanyError] = useState(false);
  const [isUsageError, setIsUsageError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (state === null || !state.profile.force_passchange)
      navigate("/dashboard");
    else {
      setFname(state.profile.fname);
      setLname(state.profile.lname);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (
      fname == "" ||
      !/^[a-zA-Z]*$/.test(fname) ||
      lname == "" ||
      !/^[a-zA-Z]*$/.test(lname)
    ) {
      if (fname == "" || !/^[a-zA-Z]*$/.test(fname)) setIsFnameError(true);
      if (lname == "" || !/^[a-zA-Z]*$/.test(lname)) setIslnameError(true);
      return;
    }

    if (
      newPass === "" ||
      newPass.length < 6 ||
      confirmPass === "" ||
      confirmPass.length < 6 ||
      confirmPass !== newPass
    ) {
      if (newPass === "" || confirmPass === "") {
        setPassErr(true);
      }
      if (newPass.length < 6 || confirmPass.length < 6) {
        setPassErr(true);
        setNewPassError("Value cannot be less than 6 characters");
      }
      if (confirmPass !== newPass) {
        setPassErr(true);
        setNewPassError(
          "New Password and Confirm Password values do not match"
        );
      }
      return;
    }

    try {
      e.preventDefault();
      const res = await api.put("user/updateProfile", {
        fname: fname,
        lname: lname,
        role: role,
        organization: companyName,
        toolUsage: toolUsage,
        newPassword: newPass,
      });
      setShowSuccessMessage(true);
      setErrorMessage("");
      setFname("");
      setLname("");
      setRole("");
      setCompanyName("");
      setToolUsage("");
      setTimeout(() => {
        removeToken();
        removeProfile();
        navigate("/login");
      }, 1000);
    } catch (err) {
      console.log(err);
      setErrorMessage(err?.response?.data?.message);
      console.log(err?.response?.data?.message);
    }
  };

  return (
    <div>
      <Grid style={{ marginTop: "3%" }}>
        <Column
          lg={16}
          md={8}
          sm={4}
          style={{
            margin: "2%",
            padding: "1.5% 2% 1.5% 2%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: "var(--border-radius)",
          }}
        >
          <h4 style={{ fontWeight: "500", marginBottom: "1%" }}>
            Update profile
          </h4>
          <div style={{ width: "50%" }}>
            {showSuccessMessage && (
              <InlineNotification
                lowContrast
                kind="success"
                title="Password changed successfully. Login with the new password."
                onCloseButtonClick={() => setShowSuccessMessage(false)}
              />
            )}
            {errorMsg && (
              <InlineNotification
                lowContrast
                kind="danger"
                title="Error updating password. Contact support@xpub.ai for more information."
              />
            )}
            <Form onSubmit={handleSubmit}>
              <FormGroup legendText="">
                <TextInput
                  id="firstname"
                  labelText="First Name"
                  value={fname}
                  required
                  onChange={(e) => {
                    setIsFnameError(false);
                    setFname(e.target.value);
                  }}
                  invalidText="Value cannot be empty or non alphabetic"
                  invalid={isfnameError}
                />
                <br></br>
                <TextInput
                  id="lastname"
                  labelText="Last Name"
                  value={lname}
                  required
                  onChange={(e) => {
                    setIslnameError(false);
                    setLname(e.target.value);
                  }}
                  invalidText="Value cannot be empty or non alphabetic"
                  invalid={islnameError}
                />
                <br></br>
                <TextInput.PasswordInput
                  id="newPassword"
                  labelText="Enter New Password"
                  value={newPass}
                  invalid={isNewPassError}
                  invalidText={newPassError}
                  onChange={(e) => {
                    setPassErr(false);
                    setNewPass(e.target.value);
                  }}
                />{" "}
                <br></br>
                <TextInput.PasswordInput
                  id="confirmPassword"
                  labelText="Confirm New Password"
                  value={confirmPass}
                  invalid={isNewPassError}
                  invalidText={newPassError}
                  onChange={(e) => {
                    setPassErr(false);
                    setConfirmPass(e.target.value);
                  }}
                />
                <br></br>
                <TextInput
                  id="role"
                  labelText="Role"
                  value={role}
                  required
                  onChange={(e) => {
                    setIsRoleError(false);
                    setRole(e.target.value);
                  }}
                  placeholder="Editor, Journalist, Reporter, Publisher"
                  invalidText="Value cannot be empty or non alphabetic"
                  invalid={isRoleError}
                />
                <br></br>
                <TextInput
                  id="organization"
                  labelText="Organization"
                  value={companyName}
                  required
                  onChange={(e) => {
                    setIsCompanyError(false);
                    setCompanyName(e.target.value);
                  }}
                  invalidText="Value cannot be empty or non alphabetic"
                  invalid={isCompanyError}
                />
                <br></br>
                <TextInput
                  id="usage"
                  labelText="Usage of the tool"
                  value={toolUsage}
                  required
                  onChange={(e) => {
                    setIsUsageError(false);
                    setToolUsage(e.target.value);
                  }}
                  placeholder="Generating articles, Fact checking"
                  invalidText="Value cannot be empty or non alphabetic"
                  invalid={isUsageError}
                />
                <br></br>
                <br></br>
                <Button type="submit">Update profile</Button>
              </FormGroup>
            </Form>
          </div>
        </Column>
      </Grid>
    </div>
  );
};

export default PasswordChange;

import React, { useState } from "react";
import { FileUploaderButton, FileUploaderItem } from "@carbon/react";
import "./FileUploader.scss";

const FileUploader = (props) => {
  const [isFileNumberExceededStatus, setFileNumberExceededStatus] =
    useState(false);

  const handleFileUpload = (event) => {
    setFileNumberExceededStatus(false);
    const fileList = event.target.files;

    const newFiles = Array.from(fileList);
    if (props.filesAndStatus.length + newFiles.length <= props.maxFiles) {
      const newFilesWithStatus = newFiles.map((file) => {
        return {
          file,
          status: "edit",
        };
      });

      const updatedFilesAndStatus = [
        ...props.filesAndStatus,
        ...newFilesWithStatus,
      ];

      props.updateFileUploaderStatus(updatedFilesAndStatus);
    } else {
      setFileNumberExceededStatus(true);
    }
  };

  const handleFileRemove = (removedFile) => {
    if (props.filesAndStatus.length <= props.maxFiles) {
      setFileNumberExceededStatus(false);
    }

    props.updateFileUploaderStatus(
      props.filesAndStatus.filter((fs) => fs.file !== removedFile)
    );
  };

  return (
    <div className="container">
      {props.uploadError && (
        <span
          style={{
            display: "block",
            color: "#da1e28",
            fontSize: "var(--text-medium)",
          }}
        >
          Oops something went wrong during upload, please check the
          requirements. If the issue persists contact: support@xpub.ai
        </span>
      )}
      {isFileNumberExceededStatus && (
        <div>
          {" "}
          <span
            style={{
              display: "block",
              color: "#da1e28",
              fontSize: "var(--text-medium)",
            }}
          >
            Limit exceeded: upload fewer than {props.maxFiles} files at once.
          </span>
        </div>
      )}
      {props.uploadErrorMessages.map((error) => (
        <div>
          {" "}
          <span
            style={{
              display: "block",
              color: "#da1e28",
              fontSize: "var(--text-medium)",
            }}
          >
            {error}
          </span>
          <br></br>
        </div>
      ))}
      <br></br>
      <FileUploaderButton
        accept={[
          ...props.acceptTypes,
          ...(props.allowAudioFiles ? [".mp3", ".mp4", ".mpeg"] : []),
        ]}
        labelText="Add files"
        buttonKind="secondary"
        multiple
        size="lg"
        disableLabelChanges
        onChange={(evt) => handleFileUpload(evt)}
      />
      <br></br>

      {props.filesAndStatus.map((fileStatus, index) => (
        <FileUploaderItem
          key={fileStatus.file.name}
          name={fileStatus.file.name}
          status={fileStatus.status}
          onDelete={() => handleFileRemove(fileStatus.file)}
          style={{
            border: "1px solid #ccc",
            borderRadius: "var(--border-radius)",
            padding: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
          }}
        />
      ))}
    </div>
  );
};

export default FileUploader;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Translate,
  DocumentAudio,
  Blog,
  Report,
  DocumentAdd,
  Idea,
} from "@carbon/icons-react";
import CollapsibleList from "./CollapsibleList";
import FileUploaderModal from "../FileUploaderModal";
import "./scss/EditorMenu.scss";
import TranslationModal from "./TranslationModal";
import SummaryModal from "./SummaryModal";
import IdeaGenerationModal from "./IdeaGenerationModal";
import { api } from "../../store";
import SpeechModal from "./SpeechModal";
import IdeaModal from "./IdeaModal";

const EditorMenu = (props) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isTranslateModalOpen, setTranslateModalOpen] = useState(false);
  const [isSummaryModalOpen, setSummaryModalOpen] = useState(false);
  const [isIdeaModalOpen, setIdeaModalOpen] = useState(false);
  const [isSEOContentModalOpen, setSEOContentModalState] = useState(false);
  const [refreshSEOContentModal, setRefreshSEOContentModal] = useState(false);
  const [isSpeechModalOpen, setSpeechModalOpen] = useState(false);
  const [idea, setIdea] = useState("");

  const navigate = useNavigate();

  const handleUploadOpenModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleUploadCloseModal = () => {
    setIsUploadModalOpen(false);
  };

  return (
    <div className="side-nav">
      <div className="files-section">
        <div className="section-title">
          <div className="actions-section-title">Files</div>
          <button className="add-file-button" onClick={handleUploadOpenModal}>
            <DocumentAdd />
            <span className="add-file-text">Add file</span>
          </button>
        </div>

        <CollapsibleList
          listItems={props.listItems}
          projectId={props.projectId}
          onFileDownload={props.onFileDownload}
          refreshFiles={props.refreshFiles}
          updateFileName={props.updateFileName}
        />
      </div>
      <div className="actions-section">
        <div className="actions-section-title">Actions</div>
        <div
          className="action-button seo-content-button"
          onClick={() => {
            setSEOContentModalState(true);
            setRefreshSEOContentModal(true);
          }}
        >
          <Blog />
          <span>Generate article</span>
        </div>
        <div
          className="action-button idea-button"
          onClick={() => setIdeaModalOpen(true)}
        >
          <Idea />
          <span>Generate ideas</span>
        </div>
        <div className="action-button" onClick={() => setSpeechModalOpen(true)}>
          <DocumentAudio />
          <span>Text to audio</span>
        </div>
        <div
          className="action-button"
          onClick={() => setSummaryModalOpen(true)}
        >
          <Report />
          <span>Summarize</span>
        </div>
        <div
          className="action-button"
          onClick={() => setTranslateModalOpen(true)}
        >
          <Translate />
          <span>Translate</span>
        </div>
      </div>
      <FileUploaderModal
        modalHeading={"Upload files to the project"}
        isOpen={isUploadModalOpen}
        onClose={handleUploadCloseModal}
        refreshFiles={props.refreshFiles}
        projectId={props.projectId}
        allowedFileTypes={[".txt, .docx, .pdf, .mp3, .mp4, .mpeg"]}
        allowAudio={true}
        sendNotification={props.sendNotification}
      />
      <TranslationModal
        isOpen={isTranslateModalOpen}
        onClose={() => {
          setTranslateModalOpen(false);
        }}
        projectId={props.projectId}
        generateFileName={props.generateFileName}
        // sourceText={
        //   props.quillRef.current
        //     ? props.quillRef.current.getEditor().getText()
        //     : null
        // }
        sourceText={null}
        modalHeaderName="Translate document"
        addTab={props.addTab}
        tabName={props.tabName}
        saveFile={props.saveFile}
        listItems={props.listItems}
        getFileContent={props.getFileContent}
      />
      <SummaryModal
        isOpen={isSummaryModalOpen}
        onClose={() => {
          setSummaryModalOpen(false);
        }}
        projectId={props.projectId}
        generateFileName={props.generateFileName}
        // sourceText={
        //   props.quillRef.current
        //     ? props.quillRef.current.getEditor().getText()
        //     : null
        // }
        sourceText={null}
        modalHeaderName="Summarize document"
        addTab={props.addTab}
        fileName={props.tabName}
        saveFile={props.saveFile}
        setOutput={props.setOutput}
        setTabLoading={props.setTabLoading}
        tabIndex={props.tabIndex}
        setShowMsg={props.setShowMsg}
        setLoadingMsg={props.setLoadingMsg}
        onContentChange={props.onContentChange}
        setShowErrorMsg={props.setShowErrorMsg}
        setErrorMsg={props.setErrorMsg}
        listItems={props.listItems}
        getFileContent={props.getFileContent}
      />
      <SpeechModal
        isOpen={isSpeechModalOpen}
        onClose={() => {
          setSpeechModalOpen(false);
        }}
        projectId={props.projectId}
        generateFileName={props.generateFileName}
        // sourceText={
        //   props.quillRef.current
        //     ? props.quillRef.current.getEditor().getText()
        //     : null
        // }
        addTab={props.addTab}
        // fileName={props.tabName}
        saveFile={props.saveFile}
        setOutput={props.setOutput}
        setTabLoading={props.setTabLoading}
        tabIndex={props.tabIndex}
        setShowMsg={props.setShowMsg}
        setLoadingMsg={props.setLoadingMsg}
        onContentChange={props.onContentChange}
        setShowErrorMsg={props.setShowErrorMsg}
        setErrorMsg={props.setErrorMsg}
        listItems={props.listItems}
        refreshFiles={props.refreshFiles}
      />
      <IdeaGenerationModal
        isOpen={isSEOContentModalOpen}
        onClose={() => {
          setSEOContentModalState(false);
        }}
        refreshFiles={props.refreshFiles}
        projectId={props.projectId}
        refreshComponent={refreshSEOContentModal}
        generateFileName={props.generateFileName}
        saveFile={props.saveFile}
        addTab={props.addTab}
        setOutput={props.setOutput}
        setTabLoading={props.setTabLoading}
        tabIndex={props.tabIndex}
        setShowMsg={props.setShowMsg}
        setLoadingMsg={props.setLoadingMsg}
        onContentChange={props.onContentChange}
        setShowErrorMsg={props.setShowErrorMsg}
        setErrorMsg={props.setErrorMsg}
        setMissedKeywords={props.setMissedKeywords}
        idea={idea}
      />
      <IdeaModal
        isOpen={isIdeaModalOpen}
        onClose={() => {
          setIdeaModalOpen(false);
        }}
        projectId={props.projectId}
        setArticleModalOpen={(idea) => {
          setIdea(idea);
          setSEOContentModalState(true);
          setRefreshSEOContentModal(true);
        }}
      />
    </div>
  );
};

export default EditorMenu;
